// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var UnexpectedProgressionBehavior = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Course.UnexpectedProgressionBehavior");

function ended(t) {
  return t.ended;
}

function id(t) {
  return t.id;
}

function certificateSerialNumber(t) {
  return t.certificateSerialNumber;
}

function progressionBehavior(t) {
  var progressionLimit = t.progressionBehavior;
  if (typeof progressionLimit === "number") {
    if (progressionLimit !== 0) {
      return "Strict";
    } else {
      return "Unlimited";
    }
  } else {
    return {
            NAME: "Limited",
            VAL: progressionLimit._0
          };
  }
}

function decode(json) {
  var behavior = Json_decode.field("progressionBehavior", Json_decode.string, json);
  var progressionBehavior;
  switch (behavior) {
    case "Limited" :
        var progressionLimit = Json_decode.field("progressionLimit", Json_decode.$$int, json);
        progressionBehavior = /* Limited */{
          _0: progressionLimit
        };
        break;
    case "Strict" :
        progressionBehavior = /* Strict */1;
        break;
    case "Unlimited" :
        progressionBehavior = /* Unlimited */0;
        break;
    default:
      Rollbar.error("Unexpected progressionBehavior: " + behavior);
      throw {
            RE_EXN_ID: UnexpectedProgressionBehavior,
            _1: behavior,
            Error: new Error()
          };
  }
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          ended: Json_decode.field("ended", Json_decode.bool, json),
          certificateSerialNumber: Json_decode.optional((function (param) {
                  return Json_decode.field("certificateSerialNumber", Json_decode.string, param);
                }), json),
          progressionBehavior: progressionBehavior
        };
}

export {
  UnexpectedProgressionBehavior ,
  ended ,
  id ,
  certificateSerialNumber ,
  progressionBehavior ,
  decode ,
  
}
/* No side effect */
