// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

function title(t) {
  return t.title;
}

function result(t) {
  return t.result;
}

function status(t) {
  return t.status;
}

function fileName(file) {
  return file.name;
}

function fileUrl(file) {
  return file.url;
}

function make(title, result, status) {
  return {
          title: title,
          result: result,
          status: status
        };
}

function makeFile(name, url, id) {
  return {
          id: id,
          name: name,
          url: url
        };
}

function makeFiles(data) {
  return data.map(function (a) {
              return {
                      id: a.id,
                      name: a.title,
                      url: a.url
                    };
            });
}

function findAudioFile(files, id) {
  return ArrayUtils.unsafeFind((function (file) {
                return file.id === id;
              }), "Unable to find file with ID: " + id, files);
}

function findFiles(files, ids) {
  return files.filter(function (file) {
              return ids.includes(file.id);
            });
}

function makeResult(json, kind, files) {
  switch (kind) {
    case "audio" :
        return {
                TAG: /* AudioRecord */5,
                _0: findAudioFile(files, Json_decode.field("result", Json_decode.string, json))
              };
    case "files" :
        return {
                TAG: /* Files */3,
                _0: findFiles(files, Json_decode.field("result", (function (param) {
                            return Json_decode.array(Json_decode.string, param);
                          }), json))
              };
    case "link" :
        return {
                TAG: /* Link */2,
                _0: Json_decode.field("result", Json_decode.string, json)
              };
    case "longText" :
        return {
                TAG: /* LongText */1,
                _0: Json_decode.field("result", Json_decode.string, json)
              };
    case "multiChoice" :
        return {
                TAG: /* MultiChoice */4,
                _0: Json_decode.field("result", Json_decode.string, json)
              };
    case "shortText" :
        return {
                TAG: /* ShortText */0,
                _0: Json_decode.field("result", Json_decode.string, json)
              };
    default:
      Rollbar.error("Unkown kind: " + (kind + "recived in CurriculumEditor__TargetChecklistItem"));
      return {
              TAG: /* ShortText */0,
              _0: "Error"
            };
  }
}

function makeStatus(data) {
  switch (data) {
    case "failed" :
        return /* Failed */1;
    case "noAnswer" :
        return /* NoAnswer */2;
    case "passed" :
        return /* Passed */0;
    default:
      Rollbar.error("Unkown status:" + (data + "recived in CourseReview__SubmissionChecklist"));
      return /* NoAnswer */2;
  }
}

function decodeFile(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json)
        };
}

function decode(files, json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          result: makeResult(json, Json_decode.field("kind", Json_decode.string, json), files),
          status: makeStatus(Json_decode.field("status", Json_decode.string, json))
        };
}

function updateStatus(checklist, index, status) {
  return $$Array.mapi((function (i, t) {
                if (i === index) {
                  return {
                          title: t.title,
                          result: t.result,
                          status: status
                        };
                } else {
                  return t;
                }
              }), checklist);
}

function makeNoAnswer(index, checklist) {
  return updateStatus(checklist, index, /* NoAnswer */2);
}

function makeFailed(index, checklist) {
  return updateStatus(checklist, index, /* Failed */1);
}

function makePassed(index, checklist) {
  return updateStatus(checklist, index, /* Passed */0);
}

function encodeKind(t) {
  var match = t.result;
  switch (match.TAG | 0) {
    case /* ShortText */0 :
        return "shortText";
    case /* LongText */1 :
        return "longText";
    case /* Link */2 :
        return "link";
    case /* Files */3 :
        return "files";
    case /* MultiChoice */4 :
        return "multiChoice";
    case /* AudioRecord */5 :
        return "audio";
    
  }
}

function encodeResult(t) {
  var file = t.result;
  switch (file.TAG | 0) {
    case /* Files */3 :
        return file._0.map(function (file) {
                    return file.id;
                  });
    case /* AudioRecord */5 :
        return file._0.id;
    default:
      return file._0;
  }
}

function encodeStatus(t) {
  var match = t.status;
  switch (match) {
    case /* Passed */0 :
        return "passed";
    case /* Failed */1 :
        return "failed";
    case /* NoAnswer */2 :
        return "noAnswer";
    
  }
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "title",
                t.title
              ],
              tl: {
                hd: [
                  "kind",
                  encodeKind(t)
                ],
                tl: {
                  hd: [
                    "status",
                    encodeStatus(t)
                  ],
                  tl: {
                    hd: [
                      "result",
                      encodeResult(t)
                    ],
                    tl: /* [] */0
                  }
                }
              }
            });
}

function encodeArray(checklist) {
  return Json_encode.array(encode, checklist);
}

export {
  title ,
  result ,
  status ,
  fileName ,
  fileUrl ,
  make ,
  makeFile ,
  makeFiles ,
  findAudioFile ,
  findFiles ,
  makeResult ,
  makeStatus ,
  decodeFile ,
  decode ,
  updateStatus ,
  makeNoAnswer ,
  makeFailed ,
  makePassed ,
  encodeKind ,
  encodeResult ,
  encodeStatus ,
  encode ,
  encodeArray ,
  
}
/* ArrayUtils Not a pure module */
