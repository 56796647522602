// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var UnexpectedEligibilityString = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__LevelUpEligibility.UnexpectedEligibilityString");

function decode(json) {
  var stringValue = Json_decode.string(json);
  switch (stringValue) {
    case "AtMaxLevel" :
        return /* AtMaxLevel */1;
    case "CurrentLevelIncomplete" :
        return /* CurrentLevelIncomplete */3;
    case "DateLocked" :
        return /* DateLocked */6;
    case "Eligible" :
        return /* Eligible */0;
    case "NoMilestonesInLevel" :
        return /* NoMilestonesInLevel */2;
    case "PreviousLevelIncomplete" :
        return /* PreviousLevelIncomplete */4;
    case "TeamMembersPending" :
        return /* TeamMembersPending */5;
    default:
      Rollbar.error("Unexpected eligibility encountered: " + stringValue);
      throw {
            RE_EXN_ID: UnexpectedEligibilityString,
            _1: stringValue,
            Error: new Error()
          };
  }
}

function isEligible(t) {
  return t === 0;
}

function makeOptionFromJs(js) {
  return Belt_Option.map(js, (function (eligibility) {
                if (typeof eligibility !== "object") {
                  if (eligibility === "DateLocked") {
                    return /* DateLocked */6;
                  } else if (eligibility === "TeamMembersPending") {
                    return /* TeamMembersPending */5;
                  } else if (eligibility === "Eligible") {
                    return /* Eligible */0;
                  } else if (eligibility === "PreviousLevelIncomplete") {
                    return /* PreviousLevelIncomplete */4;
                  } else if (eligibility === "NoMilestonesInLevel") {
                    return /* NoMilestonesInLevel */2;
                  } else if (eligibility === "CurrentLevelIncomplete") {
                    return /* CurrentLevelIncomplete */3;
                  } else {
                    return /* AtMaxLevel */1;
                  }
                }
                var string = eligibility.VAL;
                Rollbar.error("Unexpected eligibility encountered: " + string);
                throw {
                      RE_EXN_ID: UnexpectedEligibilityString,
                      _1: string,
                      Error: new Error()
                    };
              }));
}

export {
  UnexpectedEligibilityString ,
  decode ,
  isEligible ,
  makeOptionFromJs ,
  
}
/* No side effect */
