// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as CourseInfo from "../shared/types/CourseInfo.bs.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as StudentCourse__Header from "../layouts/student_course/components/StudentCourse__Header.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("currentCourseId", Json_decode.string, json),
          Json_decode.field("courses", (function (param) {
                  return Json_decode.array(CourseInfo.decode, param);
                }), json),
          Json_decode.field("additionalLinks", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          Json_decode.field("coverImage", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        ];
}

Psj.matchPaths(undefined, [
      "courses/:id/curriculum",
      "courses/:id/report",
      "courses/:id/calendar"
    ], (function (param) {
        var match = decodeProps(DomUtils.parseJSONAttribute("course-header-root", undefined, undefined));
        var root = document.querySelector("#course-header-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(StudentCourse__Header.make, {
                    currentCourseId: match[0],
                    courses: match[1],
                    additionalLinks: match[2],
                    coverImage: match[3]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
