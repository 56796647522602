// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as AppRouter__Course from "./AppRouter__Course.bs.js";

var UnsafeFindFailed = /* @__PURE__ */Caml_exceptions.create("AppRouter__Page.UnsafeFindFailed");

function showSideNav(t) {
  switch (t.TAG | 0) {
    case /* SelectedCourse */0 :
        return true;
    case /* Student__SubmissionShow */1 :
    case /* Student__StudentsReport */2 :
        return false;
    
  }
}

function coursePath(id, page) {
  var tmp;
  switch (page) {
    case /* Curriculum */0 :
        tmp = "curriculum";
        break;
    case /* Report */1 :
        tmp = "report";
        break;
    case /* Review */2 :
        tmp = "review";
        break;
    case /* Students */3 :
        tmp = "students";
        break;
    case /* Leaderboard */4 :
        tmp = "leaderboard";
        break;
    case /* Calendar */5 :
        tmp = "calendar";
        break;
    
  }
  return "/courses/" + id + "/" + tmp;
}

function path(t) {
  switch (t.TAG | 0) {
    case /* SelectedCourse */0 :
        return coursePath(t._0, t._1);
    case /* Student__SubmissionShow */1 :
        return "/submissions/" + t._0 + "/review";
    case /* Student__StudentsReport */2 :
        return "/students/" + t._0 + "/report";
    
  }
}

function activeLinks(currentCourse) {
  var defaultLinks = [
    /* Curriculum */0,
    /* Calendar */5
  ];
  var linksForStudents = AppRouter__Course.isStudent(currentCourse) ? defaultLinks.concat([/* Report */1]) : defaultLinks;
  if (AppRouter__Course.canReview(currentCourse)) {
    return linksForStudents.concat([
                /* Review */2,
                /* Students */3
              ]);
  } else {
    return linksForStudents;
  }
}

function isSPA(t) {
  switch (t.TAG | 0) {
    case /* SelectedCourse */0 :
        return (t._1 - 2 >>> 0) <= 1;
    case /* Student__SubmissionShow */1 :
    case /* Student__StudentsReport */2 :
        return true;
    
  }
}

function useSPA(selectedPage, page) {
  if (isSPA(selectedPage)) {
    return isSPA(page);
  } else {
    return false;
  }
}

function isSelectedCoursePage(t, coursePage) {
  switch (t.TAG | 0) {
    case /* SelectedCourse */0 :
        return t._1 === coursePage;
    case /* Student__SubmissionShow */1 :
    case /* Student__StudentsReport */2 :
        return false;
    
  }
}

function canAccessPage(coursePage, course) {
  return Belt_Option.isSome(Caml_option.undefined_to_opt(activeLinks(course).find(function (l) {
                      return l === coursePage;
                    })));
}

export {
  UnsafeFindFailed ,
  showSideNav ,
  coursePath ,
  path ,
  activeLinks ,
  isSPA ,
  useSPA ,
  isSelectedCoursePage ,
  canAccessPage ,
  
}
/* No side effect */
