// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as React from "react";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as CoursesReview__SubmissionMeta from "../types/CoursesReview__SubmissionMeta.bs.js";

import "./CoursesReview__SubmissionInfoCard.css"
;

var partial_arg = "components.CoursesReview__SubmissionInfoCard";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function cardClasses(submission, selected) {
  var match = CoursesReview__SubmissionMeta.archivedAt(submission);
  var match$1 = CoursesReview__SubmissionMeta.passedAt(submission);
  var match$2 = CoursesReview__SubmissionMeta.evaluatedAt(submission);
  return "inline-block bg-white relative rounded-lg submission-info__tab shrink-0 rounded-lg transition " + (
          selected ? "border border-primary-400 " : "bg-white/50 border border-gray-300 hover:bg-white "
        ) + (
          match !== undefined ? "submission-info__tab-deleted cursor-not-allowed focus:outline-none" : (
              match$1 !== undefined ? "submission-info__tab-completed focus:ring focus:ring-focusColor-400 shadow hover:shadow-lg" : (
                  match$2 !== undefined ? "submission-info__tab-rejected focus:ring focus:ring-focusColor-400 shadow hover:shadow-lg" : "submission-info__tab-pending focus:ring focus:ring-focusColor-400 shadow hover:shadow-lg"
                )
            )
        );
}

function showSubmissionStatus(submission) {
  var match = CoursesReview__SubmissionMeta.archivedAt(submission);
  var match$1 = CoursesReview__SubmissionMeta.passedAt(submission);
  var match$2 = CoursesReview__SubmissionMeta.evaluatedAt(submission);
  var match$3 = match !== undefined ? [
      t(undefined, undefined, "deleted"),
      "bg-gray-300 text-gray-800 "
    ] : (
      match$1 !== undefined ? [
          t(undefined, undefined, "completed"),
          "bg-green-100 text-green-800"
        ] : (
          match$2 !== undefined ? [
              t(undefined, undefined, "rejected"),
              "bg-red-100 text-red-700"
            ] : [
              t(undefined, undefined, "pending_review"),
              "bg-orange-100 text-orange-800 "
            ]
        )
    );
  return React.createElement("div", {
              className: "font-semibold px-3 py-px rounded " + match$3[1]
            }, React.createElement("span", {
                  className: "hidden md:block"
                }, match$3[0]), React.createElement("span", {
                  className: "md:hidden block"
                }, React.createElement(PfIcon.make, {
                      className: "if i-check-square-alt-solid if-fw"
                    })));
}

function linkUrl(submissionId, filterString) {
  return "/submissions/" + submissionId + "/review" + (
          $$String.trim(filterString) === "" ? "" : "?" + filterString
        );
}

function submissionInfoCardContent(submission, submissionNumber) {
  return React.createElement("div", {
              className: "px-4 py-2 flex flex-row items-center justify-between min-w-min"
            }, React.createElement("div", {
                  className: "flex flex-col md:pr-6"
                }, React.createElement("h2", {
                      className: "font-semibold text-sm leading-tight"
                    }, React.createElement("p", {
                          className: "hidden md:block"
                        }, t(undefined, undefined, "submission_hash") + " #" + String(submissionNumber)), React.createElement("p", {
                          className: "md:hidden"
                        }, "#" + String(submissionNumber))), React.createElement("span", {
                      className: "text-xs text-gray-800 pt-px whitespace-nowrap"
                    }, DateFns.formatPreset(CoursesReview__SubmissionMeta.createdAt(submission), undefined, true, undefined, undefined))), React.createElement("div", {
                  className: "hidden md:flex items-center space-x-2 text-xs w-auto"
                }, ReactUtils.nullUnless(React.createElement("div", {
                          className: "flex items-center justify-center bg-primary-100 text-primary-600 border border-transparent font-semibold p-1 rounded"
                        }, React.createElement(PfIcon.make, {
                              className: "if i-comment-alt-regular if-fw"
                            })), CoursesReview__SubmissionMeta.feedbackSent(submission)), showSubmissionStatus(submission)));
}

function CoursesReview__SubmissionInfoCard(Props) {
  var submission = Props.submission;
  var submissionNumber = Props.submissionNumber;
  var selected = Props.selected;
  var filterString = Props.filterString;
  var _archivedAt = CoursesReview__SubmissionMeta.archivedAt(submission);
  if (_archivedAt !== undefined) {
    return React.createElement("div", {
                key: CoursesReview__SubmissionMeta.id(submission),
                className: cardClasses(submission, selected),
                title: t(undefined, undefined, "submission_hash") + " #" + String(submissionNumber)
              }, submissionInfoCardContent(submission, submissionNumber));
  } else {
    return React.createElement(Link.make, {
                href: linkUrl(CoursesReview__SubmissionMeta.id(submission), filterString),
                className: cardClasses(submission, selected),
                title: t(undefined, undefined, "submission_hash") + " #" + String(submissionNumber),
                children: submissionInfoCardContent(submission, submissionNumber),
                key: CoursesReview__SubmissionMeta.id(submission)
              });
  }
}

var make = CoursesReview__SubmissionInfoCard;

export {
  t ,
  str ,
  cardClasses ,
  showSubmissionStatus ,
  linkUrl ,
  submissionInfoCardContent ,
  make ,
  
}
/*  Not a pure module */
