// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as UsersDeleteAccount from "../users/UsersDeleteAccount.bs.js";

function decodeProps(json) {
  return Json_decode.field("token", Json_decode.string, json);
}

Psj.match(undefined, "users#delete_account", (function (param) {
        var json = DomUtils.parseJSONTag("user-delete-account__props", undefined);
        var token = Json_decode.field("token", Json_decode.string, json);
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(UsersDeleteAccount.make, {
                    token: token
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
