// Generated by ReScript, PLEASE EDIT WITH CARE


function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function make(id, title) {
  return {
          id: id,
          title: title
        };
}

function makeFromJs(target) {
  return {
          id: target.id,
          title: target.title
        };
}

export {
  id ,
  title ,
  make ,
  makeFromJs ,
  
}
/* No side effect */
