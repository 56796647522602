// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as React from "react";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Dropdown from "../../../shared/components/Dropdown.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as CourseInfo from "../../../shared/types/CourseInfo.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";

function str(prim) {
  return prim;
}

import "./StudentCourse__Header.css"
;

import "~/shared/styles/background_patterns.css"
;

var partial_arg = "components.StudentCourse__Header";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function courseOptions(courses) {
  return courses.filter(function (course) {
                return Belt_Option.mapWithDefault(CourseInfo.endsAt(course), true, DateFns.isFuture);
              }).map(function (course) {
              var courseId = CourseInfo.id(course);
              return React.createElement("a", {
                          key: "course-" + courseId,
                          className: "cursor-pointer block p-3 text-xs font-semibold text-white border-b border-black-50 bg-black-50  hover:bg-primary-500 whitespace-nowrap",
                          href: "/courses/" + (courseId + "/curriculum")
                        }, React.createElement("span", undefined, CourseInfo.name(course)));
            });
}

function courseDropdown(currentCourse, otherCourses) {
  var tmp;
  if (otherCourses.length !== 0) {
    var selected = React.createElement("button", {
          className: "dropdown__btn max-w-xs md:max-w-lg mx-auto text-white appearance-none flex items-center relative justify-between focus:outline-none font-semibold w-full text-lg md:text-2xl leading-tight"
        }, React.createElement("span", {
              className: "sm:truncate w-full text-left"
            }, CourseInfo.name(currentCourse)), React.createElement("div", {
              className: "student-course__dropdown-btn ml-3 hover:bg-primary-100 hover:text-primary-400 flex items-center justify-between px-3 py-2 rounded"
            }, React.createElement("i", {
                  className: "fas fa-chevron-down text-xs font-semibold"
                })));
    tmp = React.createElement(Dropdown.make, {
          selected: selected,
          contents: courseOptions(otherCourses),
          className: "student-course__dropdown relative mx-auto"
        });
  } else {
    tmp = React.createElement("div", {
          className: "flex max-w-xs md:max-w-xl mx-auto items-center relative justify-between font-semibold rounded w-full text-lg md:text-2xl leading-tight text-white"
        }, React.createElement("span", {
              className: "sm:truncate w-full text-left"
            }, CourseInfo.name(currentCourse)));
  }
  return React.createElement("div", undefined, tmp);
}

function courseNameContainerClasses(additionalLinks) {
  return "student-course__name-container w-full absolute bottom-0 " + (
          ArrayUtils.isEmpty(additionalLinks) ? "pt-2 pb-3 md:pt-4 md:pb-6" : "pt-2 pb-3 md:pt-4 md:pb-12"
        );
}

function imageWrapperClasses(coverImage) {
  return "relative " + (
          coverImage !== undefined ? "pb-1/2 md:pb-1/4 2xl:pb-1/5" : "pb-1/4 sm:1/5 md:pb-1/6 xl:pb-1/12"
        );
}

function renderCourseSelector(currentCourseId, courses, coverImage, additionalLinks) {
  var currentCourse = ArrayUtils.unsafeFind((function (c) {
          return CourseInfo.id(c) === currentCourseId;
        }), "Could not find current course with ID " + (currentCourseId + " in StudentCourse__Header"), courses);
  var otherCourses = courses.filter(function (c) {
        return CourseInfo.id(c) !== currentCourseId;
      });
  return React.createElement("div", {
              className: "relative bg-primary-900"
            }, React.createElement("div", {
                  className: imageWrapperClasses(coverImage)
                }, coverImage !== undefined ? React.createElement("img", {
                        className: "absolute h-full w-full object-cover",
                        src: coverImage
                      }) : React.createElement("div", {
                        className: "student-course__cover-default absolute h-full w-full svg-bg-pattern-1"
                      })), React.createElement("div", {
                  className: courseNameContainerClasses(additionalLinks)
                }, React.createElement("div", {
                      className: "student-course__name relative px-4 lg:px-0 flex h-full mx-auto lg:max-w-3xl"
                    }, courseDropdown(currentCourse, otherCourses))));
}

function tabClasses(url, linkTitle) {
  var defaultClasses = "student-course__nav-tab py-4 px-2 text-center flex-1 font-semibold text-sm ";
  var match = url.path;
  if (!match) {
    return defaultClasses;
  }
  if (match.hd !== "courses") {
    return defaultClasses;
  }
  var match$1 = match.tl;
  if (!match$1) {
    return defaultClasses;
  }
  var match$2 = match$1.tl;
  if (match$2 && match$2.hd === linkTitle) {
    return "student-course__nav-tab py-4 px-2 text-center flex-1 font-semibold text-sm student-course__nav-tab--active";
  } else {
    return defaultClasses;
  }
}

function StudentCourse__Header(Props) {
  var currentCourseId = Props.currentCourseId;
  var courses = Props.courses;
  var additionalLinks = Props.additionalLinks;
  var coverImage = Props.coverImage;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  return React.createElement("div", undefined, renderCourseSelector(currentCourseId, courses, coverImage, additionalLinks), additionalLinks.length !== 0 ? React.createElement("div", {
                    className: "md:px-3"
                  }, React.createElement("div", {
                        className: "bg-black-50 border-transparent flex justify-between overflow-x-auto md:overflow-hidden lg:max-w-3xl mx-auto shadow md:rounded-lg mt-0 md:-mt-7 z-10 relative"
                      }, ["curriculum"].concat(additionalLinks).map(function (l) {
                            var match;
                            switch (l) {
                              case "calendar" :
                                  match = [
                                    tr(undefined, undefined, "calendar"),
                                    "calendar"
                                  ];
                                  break;
                              case "curriculum" :
                                  match = [
                                    tr(undefined, undefined, "curriculum"),
                                    "curriculum"
                                  ];
                                  break;
                              case "leaderboard" :
                                  match = [
                                    tr(undefined, undefined, "leaderboard"),
                                    "leaderboard"
                                  ];
                                  break;
                              case "report" :
                                  match = [
                                    tr(undefined, undefined, "report"),
                                    "report"
                                  ];
                                  break;
                              case "review" :
                                  match = [
                                    tr(undefined, undefined, "review"),
                                    "review"
                                  ];
                                  break;
                              case "students" :
                                  match = [
                                    tr(undefined, undefined, "students"),
                                    "students"
                                  ];
                                  break;
                              default:
                                match = [
                                  tr(undefined, undefined, "unknown"),
                                  ""
                                ];
                            }
                            var suffix = match[1];
                            var title = match[0];
                            return React.createElement("a", {
                                        key: title,
                                        className: tabClasses(url, suffix),
                                        href: "/courses/" + (currentCourseId + ("/" + suffix))
                                      }, title);
                          }))) : null);
}

var make = StudentCourse__Header;

export {
  str ,
  tr ,
  courseOptions ,
  courseDropdown ,
  courseNameContainerClasses ,
  imageWrapperClasses ,
  renderCourseSelector ,
  tabClasses ,
  make ,
  
}
/*  Not a pure module */
