// Generated by ReScript, PLEASE EDIT WITH CARE


function id(t) {
  return t.id;
}

function level(t) {
  return t.level;
}

function taggings(t) {
  return t.taggings;
}

function droppedOutAt(t) {
  return t.droppedOutAt;
}

function personalCoaches(t) {
  return t.personalCoaches;
}

function cohort(t) {
  return t.cohort;
}

function user(t) {
  return t.user;
}

function make(id, user, taggings, level, cohort, droppedOutAt, personalCoaches) {
  return {
          id: id,
          user: user,
          taggings: taggings,
          level: level,
          cohort: cohort,
          droppedOutAt: droppedOutAt,
          personalCoaches: personalCoaches
        };
}

export {
  id ,
  level ,
  taggings ,
  droppedOutAt ,
  personalCoaches ,
  cohort ,
  user ,
  make ,
  
}
/* No side effect */
