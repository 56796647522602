// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as ReactUtils from "../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as AppRouter__Nav from "./components/AppRouter__Nav.bs.js";
import * as AppRouter__Page from "./types/AppRouter__Page.bs.js";
import * as AppRouter__User from "./types/AppRouter__User.bs.js";
import * as Caml_exceptions from "../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as CoursesReview__Root from "../../courses/review/CoursesReview__Root.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesStudents__Root from "../../courses/students/components/CoursesStudents__Root.bs.js";
import * as CoursesReview__SubmissionsRoot from "../../courses/review/components/CoursesReview__SubmissionsRoot.bs.js";
import * as CoursesStudents__StudentOverlay from "../../courses/students/components/CoursesStudents__StudentOverlay.bs.js";

var UnknownPathEncountered = /* @__PURE__ */Caml_exceptions.create("AppRouter.UnknownPathEncountered");

function str(prim) {
  return prim;
}

function classNames($$default, trueClasses, falseClasses, bool) {
  return $$default + " " + (
          bool ? trueClasses : falseClasses
        );
}

function AppRouter(Props) {
  var school = Props.school;
  var courses = Props.courses;
  var currentUser = Props.currentUser;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var match$1;
  var exit = 0;
  if (match) {
    switch (match.hd) {
      case "courses" :
          var match$2 = match.tl;
          if (match$2) {
            var match$3 = match$2.tl;
            if (match$3) {
              var courseId = match$2.hd;
              switch (match$3.hd) {
                case "review" :
                    if (match$3.tl) {
                      exit = 1;
                    } else {
                      match$1 = [
                        React.createElement(CoursesReview__Root.make, {
                              courseId: courseId,
                              currentCoachId: Belt_Option.getWithDefault(AppRouter__User.coachId(AppRouter__User.defaultUser(currentUser)), ""),
                              courses: courses
                            }),
                        {
                          TAG: /* SelectedCourse */0,
                          _0: courseId,
                          _1: /* Review */2
                        }
                      ];
                    }
                    break;
                case "students" :
                    if (match$3.tl) {
                      exit = 1;
                    } else {
                      match$1 = [
                        React.createElement(CoursesStudents__Root.make, {
                              courseId: courseId
                            }),
                        {
                          TAG: /* SelectedCourse */0,
                          _0: courseId,
                          _1: /* Students */3
                        }
                      ];
                    }
                    break;
                default:
                  exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "students" :
          var match$4 = match.tl;
          if (match$4) {
            var match$5 = match$4.tl;
            if (match$5) {
              var studentId = match$4.hd;
              if (match$5.hd === "report" && !match$5.tl) {
                match$1 = [
                  React.createElement(CoursesStudents__StudentOverlay.make, {
                        studentId: studentId,
                        userId: AppRouter__User.id(AppRouter__User.defaultUser(currentUser))
                      }),
                  {
                    TAG: /* Student__StudentsReport */2,
                    _0: studentId
                  }
                ];
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "submissions" :
          var match$6 = match.tl;
          if (match$6) {
            var match$7 = match$6.tl;
            if (match$7) {
              var submissionId = match$6.hd;
              if (match$7.hd === "review" && !match$7.tl) {
                match$1 = [
                  React.createElement(CoursesReview__SubmissionsRoot.make, {
                        submissionId: submissionId,
                        currentUser: AppRouter__User.defaultUser(currentUser)
                      }),
                  {
                    TAG: /* Student__SubmissionShow */1,
                    _0: submissionId
                  }
                ];
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    Rollbar.critical("Unknown path encountered by app router: " + $$Array.of_list(url.path).join("/"));
    throw {
          RE_EXN_ID: UnknownPathEncountered,
          _1: url.path,
          Error: new Error()
        };
  }
  var selectedPage = match$1[1];
  return React.createElement("div", {
              className: "md:h-screen md:flex bg-gray-50 overflow-hidden"
            }, ReactUtils.nullUnless(React.createElement(AppRouter__Nav.make, {
                      school: school,
                      courses: courses,
                      selectedPage: selectedPage,
                      currentUser: currentUser
                    }), AppRouter__Page.showSideNav(selectedPage)), match$1[0]);
}

var make = AppRouter;

export {
  UnknownPathEncountered ,
  str ,
  classNames ,
  make ,
  
}
/* react Not a pure module */
