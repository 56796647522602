// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as StudentTopNav__NavLink from "../types/StudentTopNav__Types/StudentTopNav__NavLink.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.StudentTopNav__DropDown";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function handleToggle(setLinksVisible, param) {
  return Curry._1(setLinksVisible, (function (linksVisible) {
                return !linksVisible;
              }));
}

function additionalLinks(linksVisible, links) {
  if (linksVisible) {
    return React.createElement("div", {
                className: "dropdown__list dropdown__list-right bg-black-50 shadow-lg rounded mt-3 absolute w-40 z-50"
              }, links.map(function (link, index) {
                    return React.createElement("div", {
                                key: String(index),
                                className: ""
                              }, React.createElement("a", {
                                    className: "cursor-pointer block p-3 text-xs text-left font-medium text-white border-b border-black-50 bg-black-50 hover:bg-primary-500 focus:outline-none focus:text-primary-400 focus:bg-gray-50",
                                    href: StudentTopNav__NavLink.url(link),
                                    rel: "noopener",
                                    target: "_blank"
                                  }, StudentTopNav__NavLink.title(link)));
                  }));
  } else {
    return null;
  }
}

function StudentTopNav__DropDown(Props) {
  var links = Props.links;
  var match = React.useState(function () {
        return false;
      });
  var setLinksVisible = match[1];
  if (links.length !== 0) {
    return React.createElement("button", {
                key: "more-links",
                className: "whitespace-nowrap mx-2 font-medium text-sm p-4 md:px-3 md:py-2 cursor-pointer relative rounded-lg text-white bg-transparent hover:bg-primary-500 hover:text-white border border-primary-500 hover:text-primary-400 focus:outline-none focus:bg-primary-500 focus:text-white",
                title: t(undefined, undefined, "show_links"),
                onClick: (function (param) {
                    return Curry._1(setLinksVisible, (function (linksVisible) {
                                  return !linksVisible;
                                }));
                  })
              }, React.createElement("span", undefined, t(undefined, undefined, "more")), React.createElement("i", {
                    className: "fas fa-caret-down ml-2"
                  }), additionalLinks(match[0], links));
  } else {
    return null;
  }
}

var make = StudentTopNav__DropDown;

export {
  str ,
  t ,
  handleToggle ,
  additionalLinks ,
  make ,
  
}
/* I18n Not a pure module */
