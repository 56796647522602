// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as $$Array from "../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as PfIcon from "../packages/pf-icon/src/PfIcon.bs.js";
import * as Sorter from "../shared/components/Sorter.bs.js";
import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as DateFns from "../shared/utils/DateFns.bs.js";
import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Dropdown from "../shared/components/Dropdown.bs.js";
import * as ArrayUtils from "../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../shared/utils/OptionUtils.bs.js";
import * as StringUtils from "../shared/utils/StringUtils.bs.js";
import * as GraphqlQuery from "../shared/utils/GraphqlQuery.bs.js";
import * as TopicCategory from "../shared/types/TopicCategory.bs.js";
import * as LoadingSpinner from "../shared/components/LoadingSpinner.bs.js";
import * as SkeletonLoading from "../shared/components/SkeletonLoading.bs.js";
import * as MultiselectDropdown from "../packages/multiselect-dropdown/src/components/MultiselectDropdown.bs.js";
import * as RescriptReactRouter from "../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CommunitiesShow__Topic from "./types/CommunitiesShow__Topic.bs.js";
import * as CommunitiesShow__Target from "./types/CommunitiesShow__Target.bs.js";
import * as CommunitiesShow__Topics from "./types/CommunitiesShow__Topics.bs.js";
import * as CommunitiesShow__Participants from "./components/CommunitiesShow__Participants.bs.js";

import "./CommunitiesShow__Root.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CommunitiesShow__Root";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function getSearch(search) {
  return search._0;
}

function searchBy(search) {
  if (search.TAG === /* SearchContent */0) {
    return "content";
  } else {
    return "title";
  }
}

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* BeginLoadingMore */0) {
      return {
              loading: /* LoadingMore */2,
              topics: state.topics,
              filterString: state.filterString,
              totalTopicsCount: state.totalTopicsCount
            };
    } else {
      return {
              loading: /* Reloading */1,
              topics: state.topics,
              filterString: state.filterString,
              totalTopicsCount: state.totalTopicsCount
            };
    }
  }
  if (action.TAG === /* UpdateFilterString */0) {
    return {
            loading: state.loading,
            topics: state.topics,
            filterString: action._0,
            totalTopicsCount: state.totalTopicsCount
          };
  }
  var newTopics = action._2;
  var endCursor = action._0;
  var match = state.loading;
  var updatedTopics = match >= 2 ? $$Array.append(CommunitiesShow__Topics.toArray(state.topics), newTopics) : newTopics;
  return {
          loading: /* NotLoading */0,
          topics: action._1 && endCursor !== undefined ? ({
                TAG: /* PartiallyLoaded */0,
                _0: updatedTopics,
                _1: endCursor
              }) : ({
                TAG: /* FullyLoaded */1,
                _0: updatedTopics
              }),
          filterString: state.filterString,
          totalTopicsCount: action._3
        };
}

var Raw = {};

var query = "query TopicsFromCommunitiesShowRootQuery($communityId: ID!, $topicCategoryId: ID, $targetId: ID, $resolution: TopicResolutionFilter!, $searchFilter: CommunitySearchFilter, $after: String, $sortCriterion: TopicSortCriterion!, $sortDirection: SortDirection!)  {\ntopics(communityId: $communityId, topicCategoryId: $topicCategoryId, targetId: $targetId, search: $searchFilter, resolution: $resolution, sortDirection: $sortDirection, sortCriterion: $sortCriterion, first: 10, after: $after)  {\nnodes  {\nid  \nlastActivityAt  \ncreatedAt  \ncreator  {\nname  \n}\n\nlikesCount  \nliveRepliesCount  \nviews  \ntitle  \nsolved  \ntopicCategoryId  \nparticipantsCount  \nparticipants  {\nid  \nname  \navatarUrl  \n}\n\n}\n\npageInfo  {\nendCursor  \nhasNextPage  \n}\n\ntotalCount  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.topics;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  return {
          topics: {
            nodes: value$2.map(function (value) {
                  var value$1 = value.lastActivityAt;
                  var value$2 = value.creator;
                  var value$3 = value.topicCategoryId;
                  var value$4 = value.participants;
                  return {
                          id: value.id,
                          lastActivityAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                          createdAt: value.createdAt,
                          creator: !(value$2 == null) ? ({
                                name: value$2.name
                              }) : undefined,
                          likesCount: value.likesCount,
                          liveRepliesCount: value.liveRepliesCount,
                          views: value.views,
                          title: value.title,
                          solved: value.solved,
                          topicCategoryId: !(value$3 == null) ? value$3 : undefined,
                          participantsCount: value.participantsCount,
                          participants: value$4.map(function (value) {
                                var value$1 = value.avatarUrl;
                                return {
                                        id: value.id,
                                        name: value.name,
                                        avatarUrl: !(value$1 == null) ? value$1 : undefined
                                      };
                              })
                        };
                }),
            pageInfo: {
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: value$3.hasNextPage
            },
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.topics;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var pageInfo = {
    endCursor: endCursor,
    hasNextPage: value$4
  };
  var value$6 = value$1.nodes;
  var nodes = value$6.map(function (value) {
        var value$1 = value.participants;
        var participants = value$1.map(function (value) {
              var value$1 = value.avatarUrl;
              var avatarUrl = value$1 !== undefined ? value$1 : null;
              var value$2 = value.name;
              var value$3 = value.id;
              return {
                      id: value$3,
                      name: value$2,
                      avatarUrl: avatarUrl
                    };
            });
        var value$2 = value.participantsCount;
        var value$3 = value.topicCategoryId;
        var topicCategoryId = value$3 !== undefined ? value$3 : null;
        var value$4 = value.solved;
        var value$5 = value.title;
        var value$6 = value.views;
        var value$7 = value.liveRepliesCount;
        var value$8 = value.likesCount;
        var value$9 = value.creator;
        var creator;
        if (value$9 !== undefined) {
          var value$10 = value$9.name;
          creator = {
            name: value$10
          };
        } else {
          creator = null;
        }
        var value$11 = value.createdAt;
        var value$12 = value.lastActivityAt;
        var lastActivityAt = value$12 !== undefined ? Caml_option.valFromOption(value$12) : null;
        var value$13 = value.id;
        return {
                id: value$13,
                lastActivityAt: lastActivityAt,
                createdAt: value$11,
                creator: creator,
                likesCount: value$8,
                liveRepliesCount: value$7,
                views: value$6,
                title: value$5,
                solved: value$4,
                topicCategoryId: topicCategoryId,
                participantsCount: value$2,
                participants: participants
              };
      });
  var topics = {
    nodes: nodes,
    pageInfo: pageInfo,
    totalCount: value$2
  };
  return {
          topics: topics
        };
}

function serializeInputObjectCommunitySearchFilter(inp) {
  var a = inp.searchBy;
  return {
          search: inp.search,
          searchBy: a === "content" ? "content" : "title"
        };
}

function serializeVariables(inp) {
  var a = inp.topicCategoryId;
  var a$1 = inp.targetId;
  var a$2 = inp.resolution;
  var a$3 = inp.searchFilter;
  var a$4 = inp.after;
  var a$5 = inp.sortCriterion;
  var a$6 = inp.sortDirection;
  return {
          communityId: inp.communityId,
          topicCategoryId: a !== undefined ? a : undefined,
          targetId: a$1 !== undefined ? a$1 : undefined,
          resolution: a$2 === "Unsolved" ? "Unsolved" : (
              a$2 === "Unselected" ? "Unselected" : "Solved"
            ),
          searchFilter: a$3 !== undefined ? serializeInputObjectCommunitySearchFilter(a$3) : undefined,
          after: a$4 !== undefined ? a$4 : undefined,
          sortCriterion: a$5 === "CreatedAt" ? "CreatedAt" : (
              a$5 === "LastActivityAt" ? "LastActivityAt" : "Views"
            ),
          sortDirection: a$6 === "Descending" ? "Descending" : "Ascending"
        };
}

function makeVariables(communityId, topicCategoryId, targetId, resolution, searchFilter, after, sortCriterion, sortDirection, param) {
  return {
          communityId: communityId,
          topicCategoryId: topicCategoryId,
          targetId: targetId,
          resolution: resolution,
          searchFilter: searchFilter,
          after: after,
          sortCriterion: sortCriterion,
          sortDirection: sortDirection
        };
}

function makeInputObjectCommunitySearchFilter(search, searchBy, param) {
  return {
          search: search,
          searchBy: searchBy
        };
}

var TopicsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectCommunitySearchFilter: serializeInputObjectCommunitySearchFilter,
  makeVariables: makeVariables,
  makeInputObjectCommunitySearchFilter: makeInputObjectCommunitySearchFilter
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var TopicsQuery_Graphql_error = include.Graphql_error;

var TopicsQuery_decodeNotification = include.decodeNotification;

var TopicsQuery_decodeObject = include.decodeObject;

var TopicsQuery_decodeNotifications = include.decodeNotifications;

var TopicsQuery_decodeErrors = include.decodeErrors;

var TopicsQuery_flashNotifications = include.flashNotifications;

var TopicsQuery_sendQuery = include.sendQuery;

var TopicsQuery_query = include.query;

var TopicsQuery_fetch = include.$$fetch;

var TopicsQuery = {
  TopicsQuery_inner: TopicsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectCommunitySearchFilter: serializeInputObjectCommunitySearchFilter,
  makeVariables: makeVariables,
  makeInputObjectCommunitySearchFilter: makeInputObjectCommunitySearchFilter,
  Graphql_error: TopicsQuery_Graphql_error,
  decodeNotification: TopicsQuery_decodeNotification,
  decodeObject: TopicsQuery_decodeObject,
  decodeNotifications: TopicsQuery_decodeNotifications,
  decodeErrors: TopicsQuery_decodeErrors,
  flashNotifications: TopicsQuery_flashNotifications,
  sendQuery: TopicsQuery_sendQuery,
  query: TopicsQuery_query,
  $$fetch: TopicsQuery_fetch,
  make: make
};

function getTopics(send, communityId, cursor, filter) {
  var topicCategoryId = OptionUtils.map(TopicCategory.id, filter.topicCategory);
  var targetId = OptionUtils.map(CommunitiesShow__Target.id, filter.target);
  var searchFilter = Belt_Option.map(filter.search, (function (search) {
          if (search.TAG === /* SearchContent */0) {
            return {
                    search: search._0,
                    searchBy: "content"
                  };
          } else {
            return {
                    search: search._0,
                    searchBy: "title"
                  };
          }
        }));
  var variables = makeVariables(communityId, topicCategoryId, targetId, filter.solution, searchFilter, cursor, filter.sortCriterion, filter.sortDirection, undefined);
  Curry._3(make, undefined, undefined, variables).then(function (response) {
        var newTopics = response.topics.nodes.map(CommunitiesShow__Topic.makeFromJS);
        Curry._1(send, {
              TAG: /* LoadTopics */1,
              _0: response.topics.pageInfo.endCursor,
              _1: response.topics.pageInfo.hasNextPage,
              _2: newTopics,
              _3: response.topics.totalCount
            });
        return Promise.resolve(undefined);
      });
  
}

function reloadTopics(communityId, filter, send) {
  Curry._1(send, /* BeginReloading */1);
  return getTopics(send, communityId, undefined, filter);
}

function computeInitialState(param) {
  return {
          loading: /* NotLoading */0,
          topics: /* Unloaded */0,
          filterString: "",
          totalTopicsCount: 0
        };
}

function filterToQueryString(filter) {
  var match = filter.sortCriterion;
  var sortCriterion = match === "CreatedAt" ? "CreatedAt" : (
      match === "LastActivityAt" ? "LastActivityAt" : "Views"
    );
  var match$1 = filter.sortDirection;
  var sortDirection = match$1 === "Descending" ? "Descending" : "Ascending";
  var filterDict = Js_dict.fromArray([
        [
          "sortCriterion",
          sortCriterion
        ],
        [
          "sortDirection",
          sortDirection
        ]
      ]);
  Belt_Option.forEach(filter.search, (function (search) {
          filterDict["search"] = search._0;
          filterDict["searchBy"] = searchBy(search);
          
        }));
  Belt_Option.forEach(filter.topicCategory, (function (tc) {
          filterDict["topicCategory"] = TopicCategory.id(tc);
          
        }));
  var match$2 = filter.solution;
  if (match$2 === "Unsolved") {
    filterDict["solution"] = "Unsolved";
  } else if (match$2 === "Unselected") {
    
  } else {
    filterDict["solution"] = "Solved";
  }
  return new URLSearchParams(filterDict).toString();
}

function updateParams(filter) {
  return RescriptReactRouter.push("?" + filterToQueryString(filter));
}

function topicsList(topicCategories, topics, userEmail, userAccessToken) {
  if (ArrayUtils.isEmpty(topics)) {
    return React.createElement("div", {
                className: "flex flex-col mx-auto rounded-md border p-6 justify-center items-center"
              }, React.createElement(FaIcon.make, {
                    classes: "fas fa-comments text-5xl text-gray-400"
                  }), React.createElement("h4", {
                    className: "mt-3 text-base md:text-lg text-center font-semibold"
                  }, t(undefined, undefined, "empty_topics")));
  } else {
    return topics.map(function (topic) {
                var name = CommunitiesShow__Topic.creatorName(topic);
                var date = CommunitiesShow__Topic.lastActivityAt(topic);
                var id = CommunitiesShow__Topic.topicCategoryId(topic);
                var tmp;
                if (id !== undefined) {
                  var topicCategory = ArrayUtils.unsafeFind((function (c) {
                          return TopicCategory.id(c) === id;
                        }), t(undefined, undefined, "unable_find_id") + id, topicCategories);
                  var match = StringUtils.toColor(TopicCategory.name(topicCategory));
                  var style = {
                    backgroundColor: match[0]
                  };
                  tmp = React.createElement("span", {
                        className: "flex items-center text-xs font-semibold py-1 mr-2"
                      }, React.createElement("div", {
                            className: "w-3 h-3 rounded",
                            style: style
                          }), React.createElement("span", {
                            className: "ml-1"
                          }, TopicCategory.name(topicCategory)));
                } else {
                  tmp = null;
                }
                return React.createElement("a", {
                            key: CommunitiesShow__Topic.id(topic),
                            "aria-label": "Topic " + CommunitiesShow__Topic.id(topic),
                            className: "block",
                            href: "/topics/" + CommunitiesShow__Topic.id(topic) + "?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken)
                          }, React.createElement("div", {
                                className: "flex items-center border border-transparent bg-black-75 text-primary-400  hover:border-primary-400"
                              }, React.createElement("div", {
                                    className: "flex-1"
                                  }, React.createElement("div", {
                                        className: "cursor-pointer no-underline flex flex-col p-4 md:px-6 md:py-5"
                                      }, React.createElement("span", {
                                            className: "block"
                                          }, React.createElement("h4", {
                                                className: "community-topic__title text-base font-semibold inline-block break-words leading-snug"
                                              }, CommunitiesShow__Topic.title(topic)), React.createElement("span", {
                                                className: "block text-xs text-white pt-1"
                                              }, React.createElement("span", undefined, t(undefined, undefined, "topic_posted_by_text")), React.createElement("span", {
                                                    className: "font-semibold mr-2"
                                                  }, name !== undefined ? " " + (name + " ") : "Unknown "), React.createElement("span", {
                                                    className: "hidden md:inline-block md:mr-2"
                                                  }, "on " + DateFns.formatPreset(CommunitiesShow__Topic.createdAt(topic), undefined, true, undefined, undefined)), React.createElement("span", {
                                                    className: "inline-block md:mt-0 md:px-2 md:border-l border-gray-300"
                                                  }, date !== undefined ? React.createElement("span", undefined, React.createElement("span", {
                                                              className: "hidden md:inline-block"
                                                            }, t(undefined, undefined, "topic_last_updated_text")), " " + DateFns.formatDistanceToNowStrict(Caml_option.valFromOption(date), true, undefined, undefined, undefined)) : null))), React.createElement("span", {
                                            className: "flex flex-row flex-wrap mt-2 items-center"
                                          }, React.createElement("span", {
                                                "aria-label": "Likes",
                                                className: "flex text-center items-center mr-2 py-1 px-2 rounded bg-gray-50"
                                              }, React.createElement("i", {
                                                    className: "far fa-thumbs-up text-sm text-gray-600 mr-1"
                                                  }), React.createElement("p", {
                                                    className: "text-xs font-semibold"
                                                  }, React.createElement("span", {
                                                        className: "ml-1 hidden md:inline"
                                                      }, t(undefined, CommunitiesShow__Topic.likesCount(topic), "topic_stats_likes")))), React.createElement("span", {
                                                "aria-label": "Replies",
                                                className: "flex justify-between text-center items-center mr-2 py-1 px-2 rounded bg-gray-50"
                                              }, React.createElement("i", {
                                                    className: "far fa-comment-dots text-sm text-gray-600 mr-1"
                                                  }), React.createElement("p", {
                                                    className: "text-xs font-semibold"
                                                  }, React.createElement("span", {
                                                        className: "ml-1 hidden md:inline"
                                                      }, t(undefined, CommunitiesShow__Topic.liveRepliesCount(topic), "topic_stats_replies")))), React.createElement("span", {
                                                "aria-label": "Views",
                                                className: "flex justify-between text-center items-center mr-2 py-1 px-2 rounded bg-gray-50"
                                              }, React.createElement("i", {
                                                    className: "far fa-eye text-sm text-gray-600 mr-1"
                                                  }), React.createElement("p", {
                                                    className: "text-xs font-semibold"
                                                  }, React.createElement("span", {
                                                        className: "ml-1 hidden md:inline"
                                                      }, t(undefined, CommunitiesShow__Topic.views(topic), "topic_stats_views")))), tmp, ReactUtils.nullUnless(React.createElement("span", {
                                                    "aria-label": "Solved status icon",
                                                    className: "flex items-center justify-center w-5 h-5 bg-green-200 text-green-800 rounded-full"
                                                  }, React.createElement(PfIcon.make, {
                                                        className: "if i-check-solid text-xs"
                                                      })), CommunitiesShow__Topic.solved(topic))))), React.createElement("div", {
                                    className: "md:w-1/5"
                                  }, React.createElement(CommunitiesShow__Participants.make, {
                                        title: null,
                                        className: "flex shrink-0 items-center justify-end pr-4 md:pr-6",
                                        participants: CommunitiesShow__Topic.participants(topic),
                                        participantsCount: CommunitiesShow__Topic.participantsCount(topic)
                                      }))));
              });
  }
}

function topicsLoadedData(totalTopicsCount, loadedTopicsCount) {
  return React.createElement("div", {
              className: "inline-block mt-2 mx-auto bg-gray-50 text-gray-800 text-xs p-2 text-center rounded font-semibold"
            }, totalTopicsCount === loadedTopicsCount ? t([[
                      "total_topics",
                      String(totalTopicsCount)
                    ]], undefined, "topics_fully_loaded_text") : t([
                    [
                      "total_topics",
                      String(totalTopicsCount)
                    ],
                    [
                      "loaded_topics_count",
                      String(loadedTopicsCount)
                    ]
                  ], undefined, "topics_partially_loaded_text"));
}

function label(t) {
  switch (t.TAG | 0) {
    case /* TopicCategory */0 :
        return "Category";
    case /* Solution */1 :
        return "Solution";
    case /* Search */2 :
        if (t._0.TAG === /* SearchContent */0) {
          return "Search by content";
        } else {
          return "Search by title";
        }
    
  }
}

function value(t) {
  switch (t.TAG | 0) {
    case /* TopicCategory */0 :
        return TopicCategory.name(t._0);
    case /* Solution */1 :
        if (t._0) {
          return "Solved";
        } else {
          return "Unsolved";
        }
    case /* Search */2 :
        return t._0._0;
    
  }
}

function searchString(t) {
  switch (t.TAG | 0) {
    case /* TopicCategory */0 :
        return "category " + TopicCategory.name(t._0);
    case /* Solution */1 :
        return "solution solved unsolved";
    case /* Search */2 :
        return t._0._0;
    
  }
}

function color(t) {
  switch (t.TAG | 0) {
    case /* TopicCategory */0 :
        return "orange";
    case /* Solution */1 :
        return "green";
    case /* Search */2 :
        return "gray";
    
  }
}

function topicCategory(topicCategory$1) {
  return {
          TAG: /* TopicCategory */0,
          _0: topicCategory$1
        };
}

function search(search$1) {
  return {
          TAG: /* Search */2,
          _0: search$1
        };
}

function solution(on) {
  return {
          TAG: /* Solution */1,
          _0: on
        };
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  topicCategory: topicCategory,
  search: search,
  solution: solution
};

var Multiselect = MultiselectDropdown.Make(Selectable);

function unselected(topicCategories, filter, state) {
  var unselectedCategories = $$Array.map(topicCategory, topicCategories.filter(function (category) {
            return OptionUtils.mapWithDefault((function (selectedCategory) {
                          return TopicCategory.id(category) !== TopicCategory.id(selectedCategory);
                        }), true, filter.topicCategory);
          }));
  var trimmedFilterString = $$String.trim(state.filterString);
  var search = trimmedFilterString === "" ? [] : [
      {
        TAG: /* Search */2,
        _0: {
          TAG: /* SearchContent */0,
          _0: trimmedFilterString
        }
      },
      {
        TAG: /* Search */2,
        _0: {
          TAG: /* SearchTitle */1,
          _0: trimmedFilterString
        }
      }
    ];
  var match = filter.solution;
  var hasSolution = match === "Unsolved" ? [{
        TAG: /* Solution */1,
        _0: true
      }] : (
      match === "Unselected" ? [
          {
            TAG: /* Solution */1,
            _0: true
          },
          {
            TAG: /* Solution */1,
            _0: false
          }
        ] : [{
            TAG: /* Solution */1,
            _0: false
          }]
    );
  return unselectedCategories.concat(search).concat(hasSolution);
}

function selected(filter) {
  var selectedCategory = OptionUtils.mapWithDefault((function (selectedCategory) {
          return [{
                    TAG: /* TopicCategory */0,
                    _0: selectedCategory
                  }];
        }), [], filter.topicCategory);
  var selectedSearchString = OptionUtils.mapWithDefault((function (search) {
          return [{
                    TAG: /* Search */2,
                    _0: search
                  }];
        }), [], filter.search);
  var match = filter.solution;
  var selectedSolutionFilter = match === "Unsolved" ? [{
        TAG: /* Solution */1,
        _0: false
      }] : (
      match === "Unselected" ? [] : [{
            TAG: /* Solution */1,
            _0: true
          }]
    );
  return selectedCategory.concat(selectedSearchString).concat(selectedSolutionFilter);
}

function onSelectFilter(filter, send, selectable) {
  switch (selectable.TAG | 0) {
    case /* TopicCategory */0 :
        updateParams({
              topicCategory: selectable._0,
              solution: filter.solution,
              search: filter.search,
              target: filter.target,
              sortCriterion: filter.sortCriterion,
              sortDirection: filter.sortDirection
            });
        break;
    case /* Solution */1 :
        var solution = selectable._0 ? "Solved" : "Unsolved";
        updateParams({
              topicCategory: filter.topicCategory,
              solution: solution,
              search: filter.search,
              target: filter.target,
              sortCriterion: filter.sortCriterion,
              sortDirection: filter.sortDirection
            });
        break;
    case /* Search */2 :
        updateParams({
              topicCategory: filter.topicCategory,
              solution: filter.solution,
              search: selectable._0,
              target: filter.target,
              sortCriterion: filter.sortCriterion,
              sortDirection: filter.sortDirection
            });
        break;
    
  }
  return Curry._1(send, {
              TAG: /* UpdateFilterString */0,
              _0: ""
            });
}

function onDeselectFilter(filter, selectable) {
  switch (selectable.TAG | 0) {
    case /* TopicCategory */0 :
        return updateParams({
                    topicCategory: undefined,
                    solution: filter.solution,
                    search: filter.search,
                    target: filter.target,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection
                  });
    case /* Solution */1 :
        return updateParams({
                    topicCategory: filter.topicCategory,
                    solution: "Unselected",
                    search: filter.search,
                    target: filter.target,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection
                  });
    case /* Search */2 :
        return updateParams({
                    topicCategory: filter.topicCategory,
                    solution: filter.solution,
                    search: undefined,
                    target: filter.target,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection
                  });
    
  }
}

function filterPlaceholder(filter, topicCategories) {
  var match = filter.topicCategory;
  var match$1 = filter.search;
  if (match !== undefined || match$1 !== undefined) {
    return "";
  } else if (ArrayUtils.isEmpty(topicCategories)) {
    return t(undefined, undefined, "filter_input_placeholder_default");
  } else {
    return t(undefined, undefined, "filter_input_placeholder_categories");
  }
}

function categoryDropdownSelected(topicCategory) {
  var tmp;
  if (topicCategory !== undefined) {
    var match = TopicCategory.color(topicCategory);
    var style = {
      backgroundColor: match[0]
    };
    tmp = React.createElement("div", {
          className: "inline-flex items-center"
        }, React.createElement("div", {
              className: "h-3 w-3 border",
              style: style
            }), React.createElement("span", {
              className: "ml-2"
            }, TopicCategory.name(topicCategory)));
  } else {
    tmp = t(undefined, undefined, "all_categories_button");
  }
  return React.createElement("div", {
              "aria-label": "Selected category filter",
              className: "text-sm text-primary-500 bg-transparent border border-gray-300 rounded py-1 md:py-2 px-3 focus:outline-none focus:bg-white focus:border-primary-300 cursor-pointer"
            }, tmp, React.createElement(FaIcon.make, {
                  classes: "ml-4 fas fa-caret-down"
                }));
}

function categoryDropdownContents(availableTopicCategories, filter) {
  var selectableTopicCategories = Belt_Option.mapWithDefault(filter.topicCategory, availableTopicCategories, (function (topicCategory) {
          return availableTopicCategories.filter(function (availableTopicCategory) {
                      return TopicCategory.id(availableTopicCategory) !== TopicCategory.id(topicCategory);
                    });
        }));
  return selectableTopicCategories.map(function (topicCategory) {
              var match = TopicCategory.color(topicCategory);
              var style = {
                backgroundColor: match[0]
              };
              var categoryName = TopicCategory.name(topicCategory);
              return React.createElement("div", {
                          "aria-label": "Select category " + categoryName,
                          className: "pl-3 pr-4 py-2 font-normal flex items-center",
                          onClick: (function (param) {
                              return updateParams({
                                          topicCategory: topicCategory,
                                          solution: filter.solution,
                                          search: filter.search,
                                          target: filter.target,
                                          sortCriterion: filter.sortCriterion,
                                          sortDirection: filter.sortDirection
                                        });
                            })
                        }, React.createElement("div", {
                              className: "w-3 h-3 rounded",
                              style: style
                            }), React.createElement("span", {
                              className: "ml-1"
                            }, categoryName));
            });
}

function criterion(c) {
  if (c === "CreatedAt") {
    return t(undefined, undefined, "sort_criterion_posted_at");
  } else if (c === "LastActivityAt") {
    return t(undefined, undefined, "sort_criterion_last_activity");
  } else {
    return t(undefined, undefined, "sort_criterion_views");
  }
}

function criterionType(_t) {
  return "Number";
}

var Sortable = {
  criterion: criterion,
  criterionType: criterionType
};

var TopicsSorter = Sorter.Make(Sortable);

function topicsSorter(filter) {
  return React.createElement("div", {
              "aria-label": "Change topics sorting",
              className: "shrink-0"
            }, React.createElement("label", {
                  className: "block text-tiny font-semibold uppercase"
                }, t(undefined, undefined, "sort_criterion_input_label")), React.createElement(TopicsSorter.make, {
                  criteria: [
                    "CreatedAt",
                    "LastActivityAt",
                    "Views"
                  ],
                  selectedCriterion: filter.sortCriterion,
                  direction: filter.sortDirection,
                  onDirectionChange: (function (sortDirection) {
                      return updateParams({
                                  topicCategory: filter.topicCategory,
                                  solution: filter.solution,
                                  search: filter.search,
                                  target: filter.target,
                                  sortCriterion: filter.sortCriterion,
                                  sortDirection: sortDirection
                                });
                    }),
                  onCriterionChange: (function (sortCriterion) {
                      return updateParams({
                                  topicCategory: filter.topicCategory,
                                  solution: filter.solution,
                                  search: filter.search,
                                  target: filter.target,
                                  sortCriterion: sortCriterion,
                                  sortDirection: filter.sortDirection
                                });
                    })
                }));
}

function filterFromQueryParams(search, target, topicCategories) {
  var params = new URLSearchParams(search);
  var criterion = params.get("solution");
  var criterion$1 = params.get("sortCriterion");
  var direction = params.get("sortDirection");
  return {
          topicCategory: Belt_Option.flatMap(Caml_option.nullable_to_opt(params.get("topicCategory")), (function (cat) {
                  return Caml_option.undefined_to_opt(topicCategories.find(function (c) {
                                  return TopicCategory.id(c) === cat;
                                }));
                })),
          solution: (criterion == null) ? "Unselected" : (
              criterion === "Solved" ? "Solved" : (
                  criterion === "Unsolved" ? "Unsolved" : "Unselected"
                )
            ),
          search: Belt_Option.map(Caml_option.nullable_to_opt(params.get("search")), (function (searchString) {
                  var match = params.get("searchBy");
                  if (match == null) {
                    return {
                            TAG: /* SearchTitle */1,
                            _0: searchString
                          };
                  }
                  switch (match) {
                    case "content" :
                        return {
                                TAG: /* SearchContent */0,
                                _0: searchString
                              };
                    case "title" :
                        return {
                                TAG: /* SearchTitle */1,
                                _0: searchString
                              };
                    default:
                      return {
                              TAG: /* SearchTitle */1,
                              _0: searchString
                            };
                  }
                })),
          target: target,
          sortCriterion: (criterion$1 == null) ? "CreatedAt" : (
              criterion$1 === "LastActivityAt" ? "LastActivityAt" : (
                  criterion$1 === "Views" ? "Views" : "CreatedAt"
                )
            ),
          sortDirection: !(direction == null) && !(direction === "Descending" || direction !== "Ascending") ? "Ascending" : "Descending"
        };
}

function CommunitiesShow__Root(Props) {
  var communityId = Props.communityId;
  var target = Props.target;
  var topicCategories = Props.topicCategories;
  var userEmail = Props.userEmail;
  var userAccessToken = Props.userAccessToken;
  var match = React.useReducer(reducer, {
        loading: /* NotLoading */0,
        topics: /* Unloaded */0,
        filterString: "",
        totalTopicsCount: 0
      });
  var send = match[1];
  var state = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var filter = filterFromQueryParams(url.search, target, topicCategories);
  React.useEffect((function () {
          reloadTopics(communityId, filter, send);
          
        }), [url]);
  var topics = state.topics;
  var tmp;
  if (typeof topics === "number") {
    tmp = SkeletonLoading.multiple(10, SkeletonLoading.card(undefined, undefined));
  } else if (topics.TAG === /* PartiallyLoaded */0) {
    var cursor = topics._1;
    var topics$1 = topics._0;
    var match$1 = state.loading;
    var tmp$1;
    switch (match$1) {
      case /* NotLoading */0 :
          tmp$1 = React.createElement("button", {
                className: "btn btn-primary-ghost cursor-pointer w-full mt-4",
                onClick: (function (param) {
                    Curry._1(send, /* BeginLoadingMore */0);
                    return getTopics(send, communityId, cursor, filter);
                  })
              }, t(undefined, undefined, "button_load_more"));
          break;
      case /* Reloading */1 :
          tmp$1 = null;
          break;
      case /* LoadingMore */2 :
          tmp$1 = SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined));
          break;
      
    }
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "shadow bg-white rounded-lg divide-y"
            }, topicsList(topicCategories, topics$1, userEmail, userAccessToken)), React.createElement("div", {
              className: "text-center"
            }, topicsLoadedData(state.totalTopicsCount, topics$1.length)), tmp$1);
  } else {
    var topics$2 = topics._0;
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "shadow bg-white rounded-lg divide-y"
            }, topicsList(topicCategories, topics$2, userEmail, userAccessToken)), React.createElement("div", {
              className: "text-center"
            }, topicsLoadedData(state.totalTopicsCount, topics$2.length)));
  }
  var match$2 = state.topics;
  var tmp$2;
  if (typeof match$2 === "number") {
    tmp$2 = null;
  } else {
    var match$3 = state.loading;
    var loading = match$3 === 1;
    tmp$2 = React.createElement(LoadingSpinner.make, {
          loading: loading
        });
  }
  return React.createElement("div", {
              className: "flex-1 flex flex-col"
            }, target !== undefined ? React.createElement("div", {
                    className: "max-w-3xl w-full mt-5 mx-auto px-3 md:px-0"
                  }, React.createElement("div", {
                        className: "flex py-4 px-4 md:px-6 w-full bg-yellow-100 border border-dashed border-yellow-400 rounded justify-between items-center"
                      }, React.createElement("p", {
                            className: "w-3/5 md:w-4/5 font-semibold text-sm"
                          }, "Target: " + CommunitiesShow__Target.title(target)), React.createElement("a", {
                            className: "no-underline bg-yellow-100 border border-yellow-400 px-3 py-2 hover:bg-yellow-200 rounded-lg cursor-pointer text-xs font-semibold",
                            href: "/communities/" + communityId + "?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken)
                          }, "Clear Filter"))) : null, React.createElement("div", {
                  className: "mt-5 flex flex-col flex-1 "
                }, React.createElement("div", {
                      className: "w-full sticky top-0 z-30 bg-black-100 text-white py-2"
                    }, React.createElement("div", {
                          className: "max-w-3xl w-full mx-auto relative px-3 md:px-6"
                        }, React.createElement("div", {
                              className: "pb-3 flex justify-between"
                            }, ReactUtils.nullIf(React.createElement(Dropdown.make, {
                                      selected: categoryDropdownSelected(filter.topicCategory),
                                      contents: categoryDropdownContents(topicCategories, filter),
                                      className: ""
                                    }), ArrayUtils.isEmpty(topicCategories)), React.createElement("div", {
                                  className: ""
                                })), React.createElement("div", {
                              className: "flex w-full items-start flex-wrap"
                            }, React.createElement("div", {
                                  className: "flex-1 pr-2"
                                }, React.createElement("label", {
                                      className: "block text-tiny font-semibold uppercase pl-px"
                                    }, t(undefined, undefined, "filter_input_label")), React.createElement(Multiselect.make, {
                                      id: "filter",
                                      placeholder: filterPlaceholder(filter, topicCategories),
                                      onChange: (function (filterString) {
                                          return Curry._1(send, {
                                                      TAG: /* UpdateFilterString */0,
                                                      _0: filterString
                                                    });
                                        }),
                                      value: state.filterString,
                                      unselected: unselected(topicCategories, filter, state),
                                      selected: selected(filter),
                                      onSelect: (function (param) {
                                          return onSelectFilter(filter, send, param);
                                        }),
                                      onDeselect: (function (param) {
                                          return onDeselectFilter(filter, param);
                                        })
                                    })), topicsSorter(filter)))), React.createElement("div", {
                      className: "max-w-3xl w-full mx-auto relative px-3 md:px-6 pb-4"
                    }, React.createElement("div", {
                          className: "community-topic__list-container my-4",
                          id: "topics"
                        }, tmp)), tmp$2));
}

var make$1 = CommunitiesShow__Root;

export {
  str ,
  t ,
  getSearch ,
  searchBy ,
  reducer ,
  TopicsQuery ,
  getTopics ,
  reloadTopics ,
  computeInitialState ,
  filterToQueryString ,
  updateParams ,
  topicsList ,
  topicsLoadedData ,
  Selectable ,
  Multiselect ,
  unselected ,
  selected ,
  onSelectFilter ,
  onDeselectFilter ,
  filterPlaceholder ,
  categoryDropdownSelected ,
  categoryDropdownContents ,
  Sortable ,
  TopicsSorter ,
  topicsSorter ,
  filterFromQueryParams ,
  make$1 as make,
  
}
/*  Not a pure module */
