// Generated by ReScript, PLEASE EDIT WITH CARE


function toLanguageName(inputLanguage, outputLanguage) {
  var intlDisplayNames = new Intl.DisplayNames([outputLanguage], {
        type: "language"
      });
  return intlDisplayNames.of(inputLanguage);
}

function humanize(languageCode) {
  if (languageCode.startsWith("en")) {
    return toLanguageName(languageCode, languageCode);
  } else {
    return toLanguageName(languageCode, "en") + " - " + toLanguageName(languageCode, languageCode);
  }
}

export {
  toLanguageName ,
  humanize ,
  
}
/* No side effect */
