// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../shared/Api.bs.js";
import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../shared/Avatar.bs.js";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as Locale from "../shared/utils/Locale.bs.js";
import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as Caml_array from "../../../node_modules/rescript/lib/es6/caml_array.js";
import * as EmailUtils from "../shared/utils/EmailUtils.bs.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as OptionUtils from "../shared/utils/OptionUtils.bs.js";
import * as GraphqlQuery from "../shared/utils/GraphqlQuery.bs.js";
import * as $$Notification from "../shared/Notification.bs.js";
import * as ConfirmWindow from "../shared/components/ConfirmWindow.bs.js";
import * as AuthenticityToken from "../shared/utils/AuthenticityToken.bs.js";
import * as School__InputGroupError from "../schools/shared/components/School__InputGroupError.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.UserEdit";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* StartSaving */0 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: true,
                  dirty: state.dirty
                };
      case /* ResetSaving */1 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: false,
                  dirty: state.dirty
                };
      case /* StartDeletingAccount */2 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: true,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: state.dirty
                };
      case /* FinishAccountDeletion */3 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: "",
                  showDeleteAccountForm: false,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: false,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: state.dirty
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* UpdateName */0 :
          return {
                  name: action._0,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdatePreferredName */1 :
          return {
                  name: state.name,
                  preferredName: action._0,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateAbout */2 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: action._0,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateEmail */3 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: action._0,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* SetDisableUpdateEmail */4 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: action._0,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: state.dirty
                };
      case /* UpdateLocale */5 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: action._0,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateCurrentPassword */6 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: action._0,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateNewPassword */7 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: action._0,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateNewPassWordConfirm */8 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: action._0,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateEmailForDeletion */9 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: action._0,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: state.dirty
                };
      case /* UpdateDailyDigest */10 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: action._0,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      case /* UpdateAvatarUrl */11 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: action._0,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: undefined,
                  saving: state.saving,
                  dirty: state.dirty
                };
      case /* ChangeDeleteAccountFormVisibility */12 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: "",
                  showDeleteAccountForm: action._0,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: state.dirty
                };
      case /* SetAvatarUploadError */13 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: action._0,
                  saving: state.saving,
                  dirty: state.dirty
                };
      case /* FinishSaving */14 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: state.email,
                  disableEmailInput: state.disableEmailInput,
                  avatarUrl: state.avatarUrl,
                  currentPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: action._0,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: false,
                  dirty: false
                };
      case /* UpdateEmailAndDisableInput */15 :
          return {
                  name: state.name,
                  preferredName: state.preferredName,
                  about: state.about,
                  locale: state.locale,
                  email: action._0,
                  disableEmailInput: true,
                  avatarUrl: state.avatarUrl,
                  currentPassword: state.currentPassword,
                  newPassword: state.newPassword,
                  confirmPassword: state.confirmPassword,
                  dailyDigest: state.dailyDigest,
                  emailForAccountDeletion: state.emailForAccountDeletion,
                  showDeleteAccountForm: state.showDeleteAccountForm,
                  hasCurrentPassword: state.hasCurrentPassword,
                  deletingAccount: state.deletingAccount,
                  avatarUploadError: state.avatarUploadError,
                  saving: state.saving,
                  dirty: true
                };
      
    }
  }
}

var Raw = {};

var query = "mutation UpdateUserMutation($name: String!, $preferredName: String, $about: String, $locale: String!, $currentPassword: String, $newPassword: String, $confirmPassword: String, $dailyDigest: Boolean!)  {\nupdateUser(name: $name, preferredName: $preferredName, about: $about, locale: $locale, currentPassword: $currentPassword, newPassword: $newPassword, confirmNewPassword: $confirmPassword, dailyDigest: $dailyDigest)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.updateUser;
  return {
          updateUser: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.updateUser;
  var value$2 = value$1.success;
  var updateUser = {
    success: value$2
  };
  return {
          updateUser: updateUser
        };
}

function serializeVariables(inp) {
  var a = inp.preferredName;
  var a$1 = inp.about;
  var a$2 = inp.currentPassword;
  var a$3 = inp.newPassword;
  var a$4 = inp.confirmPassword;
  return {
          name: inp.name,
          preferredName: a !== undefined ? a : undefined,
          about: a$1 !== undefined ? a$1 : undefined,
          locale: inp.locale,
          currentPassword: a$2 !== undefined ? a$2 : undefined,
          newPassword: a$3 !== undefined ? a$3 : undefined,
          confirmPassword: a$4 !== undefined ? a$4 : undefined,
          dailyDigest: inp.dailyDigest
        };
}

function makeVariables(name, preferredName, about, locale, currentPassword, newPassword, confirmPassword, dailyDigest, param) {
  return {
          name: name,
          preferredName: preferredName,
          about: about,
          locale: locale,
          currentPassword: currentPassword,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          dailyDigest: dailyDigest
        };
}

var UpdateUserQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var UpdateUserQuery_Graphql_error = include.Graphql_error;

var UpdateUserQuery_decodeNotification = include.decodeNotification;

var UpdateUserQuery_decodeObject = include.decodeObject;

var UpdateUserQuery_decodeNotifications = include.decodeNotifications;

var UpdateUserQuery_decodeErrors = include.decodeErrors;

var UpdateUserQuery_flashNotifications = include.flashNotifications;

var UpdateUserQuery_sendQuery = include.sendQuery;

var UpdateUserQuery_query = include.query;

var UpdateUserQuery_make = include.make;

var UpdateUserQuery = {
  UpdateUserQuery_inner: UpdateUserQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: UpdateUserQuery_Graphql_error,
  decodeNotification: UpdateUserQuery_decodeNotification,
  decodeObject: UpdateUserQuery_decodeObject,
  decodeNotifications: UpdateUserQuery_decodeNotifications,
  decodeErrors: UpdateUserQuery_decodeErrors,
  flashNotifications: UpdateUserQuery_flashNotifications,
  sendQuery: UpdateUserQuery_sendQuery,
  query: UpdateUserQuery_query,
  $$fetch: $$fetch,
  make: UpdateUserQuery_make
};

var Raw$1 = {};

var query$1 = "mutation InitiateAccountDeletionMutation($email: String!)  {\ninitiateAccountDeletion(email: $email)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.initiateAccountDeletion;
  return {
          initiateAccountDeletion: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.initiateAccountDeletion;
  var value$2 = value$1.success;
  var initiateAccountDeletion = {
    success: value$2
  };
  return {
          initiateAccountDeletion: initiateAccountDeletion
        };
}

function serializeVariables$1(inp) {
  return {
          email: inp.email
        };
}

function makeVariables$1(email, param) {
  return {
          email: email
        };
}

var InitiateAccountDeletionQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var InitiateAccountDeletionQuery_Graphql_error = include$1.Graphql_error;

var InitiateAccountDeletionQuery_decodeNotification = include$1.decodeNotification;

var InitiateAccountDeletionQuery_decodeObject = include$1.decodeObject;

var InitiateAccountDeletionQuery_decodeNotifications = include$1.decodeNotifications;

var InitiateAccountDeletionQuery_decodeErrors = include$1.decodeErrors;

var InitiateAccountDeletionQuery_flashNotifications = include$1.flashNotifications;

var InitiateAccountDeletionQuery_sendQuery = include$1.sendQuery;

var InitiateAccountDeletionQuery_query = include$1.query;

var InitiateAccountDeletionQuery_make = include$1.make;

var InitiateAccountDeletionQuery = {
  InitiateAccountDeletionQuery_inner: InitiateAccountDeletionQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: InitiateAccountDeletionQuery_Graphql_error,
  decodeNotification: InitiateAccountDeletionQuery_decodeNotification,
  decodeObject: InitiateAccountDeletionQuery_decodeObject,
  decodeNotifications: InitiateAccountDeletionQuery_decodeNotifications,
  decodeErrors: InitiateAccountDeletionQuery_decodeErrors,
  flashNotifications: InitiateAccountDeletionQuery_flashNotifications,
  sendQuery: InitiateAccountDeletionQuery_sendQuery,
  query: InitiateAccountDeletionQuery_query,
  $$fetch: $$fetch$1,
  make: InitiateAccountDeletionQuery_make
};

var Raw$2 = {};

var query$2 = "mutation SendUpdateEmailToken($newEmail: String!)  {\nsendUpdateEmailToken(newEmail: $newEmail)  {\nsuccess  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = value.sendUpdateEmailToken;
  return {
          sendUpdateEmailToken: {
            success: value$1.success
          }
        };
}

function serialize$2(value) {
  var value$1 = value.sendUpdateEmailToken;
  var value$2 = value$1.success;
  var sendUpdateEmailToken = {
    success: value$2
  };
  return {
          sendUpdateEmailToken: sendUpdateEmailToken
        };
}

function serializeVariables$2(inp) {
  return {
          newEmail: inp.newEmail
        };
}

function makeVariables$2(newEmail, param) {
  return {
          newEmail: newEmail
        };
}

var SendEmailUpdateTokenQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = GraphqlQuery.Extender({
      Raw: Raw$2,
      query: query$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$2 = include$2.$$fetch;

var SendEmailUpdateTokenQuery_Graphql_error = include$2.Graphql_error;

var SendEmailUpdateTokenQuery_decodeNotification = include$2.decodeNotification;

var SendEmailUpdateTokenQuery_decodeObject = include$2.decodeObject;

var SendEmailUpdateTokenQuery_decodeNotifications = include$2.decodeNotifications;

var SendEmailUpdateTokenQuery_decodeErrors = include$2.decodeErrors;

var SendEmailUpdateTokenQuery_flashNotifications = include$2.flashNotifications;

var SendEmailUpdateTokenQuery_sendQuery = include$2.sendQuery;

var SendEmailUpdateTokenQuery_query = include$2.query;

var SendEmailUpdateTokenQuery_make = include$2.make;

var SendEmailUpdateTokenQuery = {
  SendEmailUpdateTokenQuery_inner: SendEmailUpdateTokenQuery_inner,
  Raw: Raw$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  Graphql_error: SendEmailUpdateTokenQuery_Graphql_error,
  decodeNotification: SendEmailUpdateTokenQuery_decodeNotification,
  decodeObject: SendEmailUpdateTokenQuery_decodeObject,
  decodeNotifications: SendEmailUpdateTokenQuery_decodeNotifications,
  decodeErrors: SendEmailUpdateTokenQuery_decodeErrors,
  flashNotifications: SendEmailUpdateTokenQuery_flashNotifications,
  sendQuery: SendEmailUpdateTokenQuery_sendQuery,
  query: SendEmailUpdateTokenQuery_query,
  $$fetch: $$fetch$2,
  make: SendEmailUpdateTokenQuery_make
};

function uploadAvatar(send, formData) {
  return Api.sendFormData("/user/upload_avatar", formData, (function (json) {
                $$Notification.success(I18n.ts(undefined, undefined, "notifications.done_exclamation"), t(undefined, undefined, "avatar_uploaded_notification"));
                var avatarUrl = Json_decode.field("avatarUrl", Json_decode.string, json);
                return Curry._1(send, {
                            TAG: /* UpdateAvatarUrl */11,
                            _0: avatarUrl
                          });
              }), (function (param) {
                return Curry._1(send, {
                            TAG: /* SetAvatarUploadError */13,
                            _0: t(undefined, undefined, "upload_failed")
                          });
              }));
}

function updateEmail(send, email, newEmail) {
  Curry._1(send, {
        TAG: /* SetDisableUpdateEmail */4,
        _0: false
      });
  Curry._3($$fetch$2, undefined, undefined, {
            newEmail: newEmail
          }).then(function (param) {
          Curry._1(send, {
                TAG: /* SetDisableUpdateEmail */4,
                _0: true
              });
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, {
              TAG: /* UpdateEmailAndDisableInput */15,
              _0: email
            });
        return Promise.resolve(undefined);
      });
  
}

function submitAvatarForm(send, formId) {
  var element = document.querySelector("#" + formId);
  if (element == null) {
    Rollbar.error("Could not find form to upload file for content block: " + formId);
    return ;
  } else {
    return uploadAvatar(send, new FormData(element));
  }
}

function handleAvatarInputChange(send, formId, $$event) {
  $$event.preventDefault();
  var files = $$event.target.files;
  if (files.length === 0) {
    return ;
  }
  var file = Caml_array.get(files, 0);
  var isInvalidImageFile = true;
  if (file.size <= window.pupilfirst.maxUploadFileSize) {
    var match = file.type;
    var tmp;
    switch (match) {
      case "image/gif" :
      case "image/jpeg" :
      case "image/png" :
          tmp = false;
          break;
      default:
        tmp = true;
    }
    isInvalidImageFile = tmp;
  }
  var error = isInvalidImageFile ? t(undefined, undefined, "select_image_limit") : undefined;
  if (error !== undefined) {
    return Curry._1(send, {
                TAG: /* SetAvatarUploadError */13,
                _0: error
              });
  } else {
    return submitAvatarForm(send, formId);
  }
}

function updateUser(state, send, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* StartSaving */0);
  var variables = makeVariables(state.name, state.preferredName, state.about, state.locale, state.currentPassword, state.newPassword, state.confirmPassword, state.dailyDigest, undefined);
  Curry._3($$fetch, undefined, undefined, variables).then(function (result) {
          if (result.updateUser.success) {
            var hasCurrentPassword = state.newPassword.length !== 0;
            Curry._1(send, {
                  TAG: /* FinishSaving */14,
                  _0: hasCurrentPassword
                });
          } else {
            Curry._1(send, {
                  TAG: /* FinishSaving */14,
                  _0: state.hasCurrentPassword
                });
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* ResetSaving */1);
        return Promise.resolve(undefined);
      });
  
}

function initiateAccountDeletion(state, send) {
  Curry._1(send, /* StartDeletingAccount */2);
  Curry._3($$fetch$1, undefined, undefined, {
            email: state.emailForAccountDeletion
          }).then(function (result) {
          Curry._1(send, /* FinishAccountDeletion */3);
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(send, /* FinishAccountDeletion */3);
        return Promise.resolve(undefined);
      });
  
}

function hasInvalidPassword(state) {
  return !(state.newPassword === "" && state.confirmPassword === "" || state.newPassword === state.confirmPassword && state.newPassword.length >= 8);
}

function saveDisabled(state) {
  if (hasInvalidPassword(state) || $$String.trim(state.name).length === 0) {
    return true;
  } else {
    return !state.dirty;
  }
}

function confirmDeletionWindow(state, send) {
  if (!state.showDeleteAccountForm) {
    return null;
  }
  var body = React.createElement("div", {
        "aria-label": t(undefined, undefined, "confirm_dialog_aria")
      }, React.createElement("p", {
            className: "text-sm text-center sm:text-left text-gray-600"
          }, t(undefined, undefined, "account_delete_q")), React.createElement("div", {
            className: "mt-3"
          }, React.createElement("label", {
                className: "block text-sm font-semibold",
                htmlFor: "email"
              }, t(undefined, undefined, "confirm_email")), React.createElement("input", {
                className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                id: "email",
                autoComplete: "off",
                placeholder: t(undefined, undefined, "email_placeholder"),
                type: "email",
                value: state.emailForAccountDeletion,
                onChange: (function ($$event) {
                    return Curry._1(send, {
                                TAG: /* UpdateEmailForDeletion */9,
                                _0: $$event.target.value
                              });
                  })
              })));
  return React.createElement(ConfirmWindow.make, {
              title: t(undefined, undefined, "delete_account"),
              body: body,
              confirmButtonText: t(undefined, undefined, "initiate_deletion"),
              cancelButtonText: t(undefined, undefined, "cancel"),
              onConfirm: (function (param) {
                  return initiateAccountDeletion(state, send);
                }),
              onCancel: (function (param) {
                  return Curry._1(send, {
                              TAG: /* ChangeDeleteAccountFormVisibility */12,
                              _0: false
                            });
                }),
              disableConfirm: state.deletingAccount,
              alertType: "Critical"
            });
}

function UserEdit(Props) {
  var name = Props.name;
  var preferredName = Props.preferredName;
  var hasCurrentPassword = Props.hasCurrentPassword;
  var about = Props.about;
  var locale = Props.locale;
  var availableLocales = Props.availableLocales;
  var avatarUrl = Props.avatarUrl;
  var dailyDigest = Props.dailyDigest;
  var isSchoolAdmin = Props.isSchoolAdmin;
  var hasValidDeleteAccountToken = Props.hasValidDeleteAccountToken;
  var email = Props.email;
  var initialState_dailyDigest = OptionUtils.mapWithDefault((function (d) {
          return d;
        }), false, dailyDigest);
  var initialState = {
    name: name,
    preferredName: preferredName,
    about: about,
    locale: locale,
    email: email,
    disableEmailInput: true,
    avatarUrl: avatarUrl,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    dailyDigest: initialState_dailyDigest,
    emailForAccountDeletion: "",
    showDeleteAccountForm: false,
    hasCurrentPassword: hasCurrentPassword,
    deletingAccount: false,
    avatarUploadError: undefined,
    saving: false,
    dirty: false
  };
  var match = React.useReducer(reducer, initialState);
  var send = match[1];
  var state = match[0];
  var url = state.avatarUrl;
  var error = state.avatarUploadError;
  return React.createElement("div", {
              className: "container mx-auto px-3 py-8 max-w-5xl"
            }, confirmDeletionWindow(state, send), React.createElement("div", {
                  className: "bg-white shadow sm:rounded-lg"
                }, React.createElement("div", {
                      className: "px-4 py-5 sm:p-6"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pr-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "edit_profile")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "displayed_publicly"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("div", {
                                  className: ""
                                }, React.createElement("div", {
                                      className: ""
                                    }, React.createElement("label", {
                                          className: "block text-sm font-semibold",
                                          htmlFor: "user_name"
                                        }, I18n.ts(undefined, undefined, "name")))), React.createElement("input", {
                                  className: "appearance-none mb-2 block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                  id: "user_name",
                                  autoFocus: true,
                                  name: "name",
                                  placeholder: t(undefined, undefined, "name_placeholder"),
                                  value: state.name,
                                  onChange: (function ($$event) {
                                      return Curry._1(send, {
                                                  TAG: /* UpdateName */0,
                                                  _0: $$event.target.value
                                                });
                                    })
                                }), React.createElement(School__InputGroupError.make, {
                                  message: t(undefined, undefined, "name_error"),
                                  active: $$String.trim(state.name).length < 2
                                }), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "user_preferred_name"
                                    }, I18n.ts(undefined, undefined, "preferred_name")), React.createElement("input", {
                                      className: "appearance-none mb-2 block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "user_preferred_name",
                                      name: "preferred_name",
                                      placeholder: t(undefined, undefined, "preferred_name_placeholder"),
                                      value: state.preferredName,
                                      onChange: (function ($$event) {
                                          return Curry._1(send, {
                                                      TAG: /* UpdatePreferredName */1,
                                                      _0: $$event.target.value
                                                    });
                                        })
                                    })), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "about"
                                    }, t(undefined, undefined, "about")), React.createElement("div", undefined, React.createElement("textarea", {
                                          className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                          id: "about",
                                          placeholder: t(undefined, undefined, "about_placeholder"),
                                          rows: 3,
                                          value: state.about,
                                          onChange: (function ($$event) {
                                              return Curry._1(send, {
                                                          TAG: /* UpdateAbout */2,
                                                          _0: $$event.target.value
                                                        });
                                            })
                                        }))), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("form", {
                                      id: "user-avatar-uploader"
                                    }, React.createElement("input", {
                                          name: "authenticity_token",
                                          type: "hidden",
                                          value: AuthenticityToken.fromHead(undefined)
                                        }), React.createElement("label", {
                                          className: "block text-sm font-semibold"
                                        }, t(undefined, undefined, "photo")), React.createElement("div", {
                                          className: "mt-2 flex items-center"
                                        }, React.createElement("span", {
                                              className: "inline-block h-14 w-14 rounded-full overflow-hidden bg-gray-50 border-2 boder-gray-400"
                                            }, url !== undefined ? React.createElement("img", {
                                                    src: url
                                                  }) : React.createElement(Avatar.make, {
                                                    name: name
                                                  })), React.createElement("span", {
                                              className: "ml-5 inline-flex"
                                            }, React.createElement("input", {
                                                  "aria-label": "User Avatar",
                                                  className: "form-input__file-sr-only",
                                                  id: "user-edit__avatar-input",
                                                  multiple: false,
                                                  name: "user[avatar]",
                                                  required: false,
                                                  type: "file",
                                                  onChange: (function (param) {
                                                      return handleAvatarInputChange(send, "user-avatar-uploader", param);
                                                    })
                                                }), React.createElement("label", {
                                                  "aria-hidden": true,
                                                  className: "form-input__file-label shadow-sm py-2 px-3 border border-gray-300 rounded-md text-sm font-semibold hover:text-gray-800 active:bg-gray-50 active:text-gray-800",
                                                  htmlFor: "user-edit__avatar-input"
                                                }, t(undefined, undefined, "change_photo"))), error !== undefined ? React.createElement(School__InputGroupError.make, {
                                                message: error,
                                                active: true
                                              }) : null))), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "user_email",
                                      name: "user_email"
                                    }, t(undefined, undefined, "email_label")), React.createElement("div", {
                                      className: "mt-2 flex items-stretch gap-2"
                                    }, React.createElement("input", {
                                          "aria-label": "User Email",
                                          className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                          id: "user-update__email-input",
                                          disabled: state.disableEmailInput,
                                          name: "user_email",
                                          required: true,
                                          type: "email",
                                          value: state.email,
                                          onChange: (function ($$event) {
                                              return Curry._1(send, {
                                                          TAG: /* UpdateEmail */3,
                                                          _0: $$event.target.value
                                                        });
                                            })
                                        }), state.disableEmailInput ? React.createElement("button", {
                                            className: "btn btn-primary",
                                            onClick: (function (evt) {
                                                return Curry._1(send, {
                                                            TAG: /* SetDisableUpdateEmail */4,
                                                            _0: false
                                                          });
                                              })
                                          }, I18n.ts(undefined, undefined, "edit")) : React.createElement("div", {
                                            className: "flex gap-2"
                                          }, React.createElement("button", {
                                                className: "btn btn-subtle",
                                                onClick: (function (param) {
                                                    return Curry._1(send, {
                                                                TAG: /* UpdateEmailAndDisableInput */15,
                                                                _0: email
                                                              });
                                                  })
                                              }, I18n.ts(undefined, undefined, "cancel")), React.createElement("button", {
                                                className: "btn btn-primary",
                                                disabled: EmailUtils.isInvalid(false, state.email) || state.email === email,
                                                onClick: (function (param) {
                                                    return updateEmail(send, email, state.email);
                                                  })
                                              }, I18n.ts(undefined, undefined, "update"))))))), React.createElement("div", {
                          className: "flex flex-col md:flex-row mt-10 md:mt-12"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pr-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "security")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "update_credentials"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("p", {
                                  className: "font-semibold"
                                }, state.hasCurrentPassword ? t(undefined, undefined, "change_password") : t(undefined, undefined, "set_password")), state.hasCurrentPassword ? React.createElement("div", {
                                    className: "mt-6"
                                  }, React.createElement("label", {
                                        className: "block text-sm font-semibold",
                                        htmlFor: "current_password"
                                      }, t(undefined, undefined, "current_password")), React.createElement("input", {
                                        className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                        id: "current_password",
                                        autoComplete: "off",
                                        placeholder: t(undefined, undefined, "password_placeholder"),
                                        type: "password",
                                        value: state.currentPassword,
                                        onChange: (function ($$event) {
                                            return Curry._1(send, {
                                                        TAG: /* UpdateCurrentPassword */6,
                                                        _0: $$event.target.value
                                                      });
                                          })
                                      })) : null, React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      htmlFor: "new_password"
                                    }, t(undefined, undefined, "new_password")), React.createElement("input", {
                                      className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "new_password",
                                      autoComplete: "off",
                                      placeholder: t(undefined, undefined, "new_password_placeholder"),
                                      type: "password",
                                      value: state.newPassword,
                                      onChange: (function ($$event) {
                                          return Curry._1(send, {
                                                      TAG: /* UpdateNewPassword */7,
                                                      _0: $$event.target.value
                                                    });
                                        })
                                    })), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("label", {
                                      className: "block text-sm font-semibold",
                                      autoComplete: "off",
                                      htmlFor: "confirm_password"
                                    }, t(undefined, undefined, "confirm_password")), React.createElement("input", {
                                      className: "appearance-none block text-sm w-full shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "confirm_password",
                                      autoComplete: "off",
                                      placeholder: t(undefined, undefined, "confirm_password_placeholder"),
                                      type: "password",
                                      value: state.confirmPassword,
                                      onChange: (function ($$event) {
                                          return Curry._1(send, {
                                                      TAG: /* UpdateNewPassWordConfirm */8,
                                                      _0: $$event.target.value
                                                    });
                                        })
                                    }), React.createElement(School__InputGroupError.make, {
                                      message: t(undefined, undefined, "confirm_password_error"),
                                      active: hasInvalidPassword(state)
                                    })))), React.createElement("div", {
                          className: "flex flex-col md:flex-row mt-10 md:mt-12"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pr-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "localization")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "update_locale"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("label", {
                                  className: "font-semibold",
                                  htmlFor: "language"
                                }, t(undefined, undefined, "language")), React.createElement("p", {
                                  className: "text-sm text-gray-600"
                                }, t(undefined, undefined, "select_language")), React.createElement("div", {
                                  className: "mt-6"
                                }, React.createElement("select", {
                                      className: "select appearance-none block text-sm w-full bg-white shadow-sm border border-gray-300 rounded px-4 py-2 my-2 leading-relaxed focus:outline-none focus:border-transparent focus:ring-2 focus:ring-focusColor-500",
                                      id: "language",
                                      value: state.locale,
                                      onChange: (function ($$event) {
                                          return Curry._1(send, {
                                                      TAG: /* UpdateLocale */5,
                                                      _0: $$event.target.value
                                                    });
                                        })
                                    }, availableLocales.map(function (availableLocale) {
                                          return React.createElement("option", {
                                                      key: availableLocale,
                                                      "aria-selected": state.locale === availableLocale,
                                                      value: availableLocale
                                                    }, Locale.humanize(availableLocale));
                                        })))))), React.createElement("div", {
                      className: "bg-gray-50 px-4 py-5 sm:p-6 flex rounded-b-lg justify-end"
                    }, React.createElement("button", {
                          className: "btn btn-primary",
                          disabled: saveDisabled(state),
                          onClick: (function (param) {
                              return updateUser(state, send, param);
                            })
                        }, t(undefined, undefined, "save_changes")))), React.createElement("div", {
                  className: "bg-white shadow sm:rounded-lg mt-10"
                }, React.createElement("div", {
                      className: "px-4 py-5 sm:p-6"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row"
                        }, React.createElement("div", {
                              className: "w-full md:w-1/3 pr-4"
                            }, React.createElement("h3", {
                                  className: "text-lg font-semibold"
                                }, t(undefined, undefined, "account")), React.createElement("p", {
                                  className: "mt-1 text-sm text-gray-600"
                                }, t(undefined, undefined, "manage_account"))), React.createElement("div", {
                              className: "mt-5 md:mt-0 w-full md:w-2/3"
                            }, React.createElement("p", {
                                  className: "font-semibold text-red-700"
                                }, t(undefined, undefined, "delete_account")), React.createElement("p", {
                                  className: "text-sm text-gray-600 mt-1"
                                }, t(undefined, undefined, "deleting_account_warning") + "  "), React.createElement("div", {
                                  className: "mt-4"
                                }, isSchoolAdmin || hasValidDeleteAccountToken ? React.createElement("div", {
                                        className: "bg-orange-100 border-l-4 border-orange-400 p-4"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, React.createElement(FaIcon.make, {
                                                classes: "fas fa-exclamation-triangle text-orange-400"
                                              }), React.createElement("div", {
                                                className: "ml-3"
                                              }, React.createElement("p", {
                                                    className: "text-sm text-orange-900"
                                                  }, isSchoolAdmin ? t(undefined, undefined, "you_admin_warning") : t(undefined, undefined, "already_iniated_deletion_warning"))))) : React.createElement("button", {
                                        className: "py-2 px-3 border border-red-500 text-red-600 rounded text-xs font-semibold hover:bg-red-600 hover:text-white focus:outline-none active:bg-red-700 active:text-white",
                                        onClick: (function (param) {
                                            return Curry._1(send, {
                                                        TAG: /* ChangeDeleteAccountFormVisibility */12,
                                                        _0: true
                                                      });
                                          })
                                      }, t(undefined, undefined, "delete_your_account"))))))));
}

var ts = I18n.ts;

var make = UserEdit;

export {
  str ,
  t ,
  ts ,
  reducer ,
  UpdateUserQuery ,
  InitiateAccountDeletionQuery ,
  SendEmailUpdateTokenQuery ,
  uploadAvatar ,
  updateEmail ,
  submitAvatarForm ,
  handleAvatarInputChange ,
  updateUser ,
  initiateAccountDeletion ,
  hasInvalidPassword ,
  saveDisabled ,
  confirmDeletionWindow ,
  make ,
  
}
/* include Not a pure module */
