// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as CoursesReview__Editor from "./CoursesReview__Editor.bs.js";
import * as CoursesReview__SubmissionMeta from "../types/CoursesReview__SubmissionMeta.bs.js";
import * as CoursesReview__SubmissionDetails from "../types/CoursesReview__SubmissionDetails.bs.js";

function str(prim) {
  return prim;
}

var Raw = {};

var query = "query SubmissionDetailsQuery($submissionId: ID!)  {\nsubmissionDetails(submissionId: $submissionId)  {\ntargetId  \ntargetTitle  \nlevelNumber  \nlevelId  \ninactiveStudents  \ncreatedAt  \nsubmissionReportPollTime  \ninactiveSubmissionReviewAllowedDays  \nstudents  {\nid  \nname  \n}\n\nsubmissionReport  {\nid  \ntestReport  \nstatus  \nstartedAt  \ncompletedAt  \nconclusion  \nqueuedAt  \n}\n\nevaluationCriteria  {\nid  \nname  \nmaxGrade  \npassGrade  \ngradeLabels  {\ngrade  \nlabel  \n}\n\n}\n\nreviewChecklist  {\ntitle  \nresult  {\ntitle  \nfeedback  \n}\n\n}\n\ntargetEvaluationCriteriaIds  \nsubmission  {\nid  \nevaluatorName  \npassedAt  \ncreatedAt  \nevaluatedAt  \narchivedAt  \nfiles  {\nurl  \ntitle  \nid  \n}\n\ngrades  {\nevaluationCriterionId  \ngrade  \n}\n\nfeedback  {\nid  \ncoachName  \ncoachAvatarUrl  \ncoachTitle  \ncreatedAt  \nvalue  \n}\n\nchecklist  \n}\n\nallSubmissions  {\nid  \npassedAt  \ncreatedAt  \nevaluatedAt  \nfeedbackSent  \narchivedAt  \n}\n\ncoaches  {\n...UserProxyFragment   \n}\n\nteamName  \ncourseId  \npreview  \nreviewerDetails  {\nassignedAt  \nuser  {\n...UserProxyFragment   \n}\n\n}\n\n}\n\n}\n" + UserProxy.Fragment.query;

function parse(value) {
  var value$1 = value.submissionDetails;
  var value$2 = value$1.students;
  var value$3 = value$1.submissionReport;
  var tmp;
  if (value$3 == null) {
    tmp = undefined;
  } else {
    var value$4 = value$3.testReport;
    var value$5 = value$3.status;
    var tmp$1;
    switch (value$5) {
      case "completed" :
          tmp$1 = "completed";
          break;
      case "in_progress" :
          tmp$1 = "in_progress";
          break;
      case "queued" :
          tmp$1 = "queued";
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$5
        };
    }
    var value$6 = value$3.startedAt;
    var value$7 = value$3.completedAt;
    var value$8 = value$3.conclusion;
    var tmp$2;
    if (value$8 == null) {
      tmp$2 = undefined;
    } else {
      var tmp$3;
      switch (value$8) {
        case "error" :
            tmp$3 = "error";
            break;
        case "failure" :
            tmp$3 = "failure";
            break;
        case "success" :
            tmp$3 = "success";
            break;
        default:
          tmp$3 = {
            NAME: "FutureAddedValue",
            VAL: value$8
          };
      }
      tmp$2 = tmp$3;
    }
    tmp = {
      id: value$3.id,
      testReport: !(value$4 == null) ? value$4 : undefined,
      status: tmp$1,
      startedAt: !(value$6 == null) ? Caml_option.some(value$6) : undefined,
      completedAt: !(value$7 == null) ? Caml_option.some(value$7) : undefined,
      conclusion: tmp$2,
      queuedAt: value$3.queuedAt
    };
  }
  var value$9 = value$1.evaluationCriteria;
  var value$10 = value$1.reviewChecklist;
  var value$11 = value$1.targetEvaluationCriteriaIds;
  var value$12 = value$1.submission;
  var value$13 = value$12.evaluatorName;
  var value$14 = value$12.passedAt;
  var value$15 = value$12.evaluatedAt;
  var value$16 = value$12.archivedAt;
  var value$17 = value$12.files;
  var value$18 = value$12.grades;
  var value$19 = value$12.feedback;
  var value$20 = value$1.allSubmissions;
  var value$21 = value$1.coaches;
  var value$22 = value$1.teamName;
  var value$23 = value$1.reviewerDetails;
  var tmp$4;
  if (value$23 == null) {
    tmp$4 = undefined;
  } else {
    var value$24 = value$23.user;
    tmp$4 = {
      assignedAt: value$23.assignedAt,
      user: UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value$24)
    };
  }
  return {
          submissionDetails: {
            targetId: value$1.targetId,
            targetTitle: value$1.targetTitle,
            levelNumber: value$1.levelNumber,
            levelId: value$1.levelId,
            inactiveStudents: value$1.inactiveStudents,
            createdAt: value$1.createdAt,
            submissionReportPollTime: value$1.submissionReportPollTime,
            inactiveSubmissionReviewAllowedDays: value$1.inactiveSubmissionReviewAllowedDays,
            students: value$2.map(function (value) {
                  return {
                          id: value.id,
                          name: value.name
                        };
                }),
            submissionReport: tmp,
            evaluationCriteria: value$9.map(function (value) {
                  var value$1 = value.gradeLabels;
                  return {
                          id: value.id,
                          name: value.name,
                          maxGrade: value.maxGrade,
                          passGrade: value.passGrade,
                          gradeLabels: value$1.map(function (value) {
                                return {
                                        grade: value.grade,
                                        label: value.label
                                      };
                              })
                        };
                }),
            reviewChecklist: value$10.map(function (value) {
                  var value$1 = value.result;
                  return {
                          title: value.title,
                          result: value$1.map(function (value) {
                                var value$1 = value.feedback;
                                return {
                                        title: value.title,
                                        feedback: !(value$1 == null) ? value$1 : undefined
                                      };
                              })
                        };
                }),
            targetEvaluationCriteriaIds: value$11.map(function (value) {
                  return value;
                }),
            submission: {
              id: value$12.id,
              evaluatorName: !(value$13 == null) ? value$13 : undefined,
              passedAt: !(value$14 == null) ? Caml_option.some(value$14) : undefined,
              createdAt: value$12.createdAt,
              evaluatedAt: !(value$15 == null) ? Caml_option.some(value$15) : undefined,
              archivedAt: !(value$16 == null) ? Caml_option.some(value$16) : undefined,
              files: value$17.map(function (value) {
                    return {
                            url: value.url,
                            title: value.title,
                            id: value.id
                          };
                  }),
              grades: value$18.map(function (value) {
                    return {
                            evaluationCriterionId: value.evaluationCriterionId,
                            grade: value.grade
                          };
                  }),
              feedback: value$19.map(function (value) {
                    var value$1 = value.coachName;
                    var value$2 = value.coachAvatarUrl;
                    return {
                            id: value.id,
                            coachName: !(value$1 == null) ? value$1 : undefined,
                            coachAvatarUrl: !(value$2 == null) ? value$2 : undefined,
                            coachTitle: value.coachTitle,
                            createdAt: value.createdAt,
                            value: value.value
                          };
                  }),
              checklist: value$12.checklist
            },
            allSubmissions: value$20.map(function (value) {
                  var value$1 = value.passedAt;
                  var value$2 = value.evaluatedAt;
                  var value$3 = value.archivedAt;
                  return {
                          id: value.id,
                          passedAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                          createdAt: value.createdAt,
                          evaluatedAt: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                          feedbackSent: value.feedbackSent,
                          archivedAt: !(value$3 == null) ? Caml_option.some(value$3) : undefined
                        };
                }),
            coaches: value$21.map(function (value) {
                  return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
                }),
            teamName: !(value$22 == null) ? value$22 : undefined,
            courseId: value$1.courseId,
            preview: value$1.preview,
            reviewerDetails: tmp$4
          }
        };
}

function serialize(value) {
  var value$1 = value.submissionDetails;
  var value$2 = value$1.reviewerDetails;
  var reviewerDetails;
  if (value$2 !== undefined) {
    var value$3 = value$2.user;
    var user = UserProxy.Fragment.serialize(value$3);
    var value$4 = value$2.assignedAt;
    reviewerDetails = {
      assignedAt: value$4,
      user: user
    };
  } else {
    reviewerDetails = null;
  }
  var value$5 = value$1.preview;
  var value$6 = value$1.courseId;
  var value$7 = value$1.teamName;
  var teamName = value$7 !== undefined ? value$7 : null;
  var value$8 = value$1.coaches;
  var coaches = value$8.map(function (value) {
        return UserProxy.Fragment.serialize(value);
      });
  var value$9 = value$1.allSubmissions;
  var allSubmissions = value$9.map(function (value) {
        var value$1 = value.archivedAt;
        var archivedAt = value$1 !== undefined ? Caml_option.valFromOption(value$1) : null;
        var value$2 = value.feedbackSent;
        var value$3 = value.evaluatedAt;
        var evaluatedAt = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
        var value$4 = value.createdAt;
        var value$5 = value.passedAt;
        var passedAt = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
        var value$6 = value.id;
        return {
                id: value$6,
                passedAt: passedAt,
                createdAt: value$4,
                evaluatedAt: evaluatedAt,
                feedbackSent: value$2,
                archivedAt: archivedAt
              };
      });
  var value$10 = value$1.submission;
  var value$11 = value$10.checklist;
  var value$12 = value$10.feedback;
  var feedback = value$12.map(function (value) {
        var value$1 = value.value;
        var value$2 = value.createdAt;
        var value$3 = value.coachTitle;
        var value$4 = value.coachAvatarUrl;
        var coachAvatarUrl = value$4 !== undefined ? value$4 : null;
        var value$5 = value.coachName;
        var coachName = value$5 !== undefined ? value$5 : null;
        var value$6 = value.id;
        return {
                id: value$6,
                coachName: coachName,
                coachAvatarUrl: coachAvatarUrl,
                coachTitle: value$3,
                createdAt: value$2,
                value: value$1
              };
      });
  var value$13 = value$10.grades;
  var grades = value$13.map(function (value) {
        var value$1 = value.grade;
        var value$2 = value.evaluationCriterionId;
        return {
                evaluationCriterionId: value$2,
                grade: value$1
              };
      });
  var value$14 = value$10.files;
  var files = value$14.map(function (value) {
        var value$1 = value.id;
        var value$2 = value.title;
        var value$3 = value.url;
        return {
                url: value$3,
                title: value$2,
                id: value$1
              };
      });
  var value$15 = value$10.archivedAt;
  var archivedAt = value$15 !== undefined ? Caml_option.valFromOption(value$15) : null;
  var value$16 = value$10.evaluatedAt;
  var evaluatedAt = value$16 !== undefined ? Caml_option.valFromOption(value$16) : null;
  var value$17 = value$10.createdAt;
  var value$18 = value$10.passedAt;
  var passedAt = value$18 !== undefined ? Caml_option.valFromOption(value$18) : null;
  var value$19 = value$10.evaluatorName;
  var evaluatorName = value$19 !== undefined ? value$19 : null;
  var value$20 = value$10.id;
  var submission = {
    id: value$20,
    evaluatorName: evaluatorName,
    passedAt: passedAt,
    createdAt: value$17,
    evaluatedAt: evaluatedAt,
    archivedAt: archivedAt,
    files: files,
    grades: grades,
    feedback: feedback,
    checklist: value$11
  };
  var value$21 = value$1.targetEvaluationCriteriaIds;
  var targetEvaluationCriteriaIds = value$21.map(function (value) {
        return value;
      });
  var value$22 = value$1.reviewChecklist;
  var reviewChecklist = value$22.map(function (value) {
        var value$1 = value.result;
        var result = value$1.map(function (value) {
              var value$1 = value.feedback;
              var feedback = value$1 !== undefined ? value$1 : null;
              var value$2 = value.title;
              return {
                      title: value$2,
                      feedback: feedback
                    };
            });
        var value$2 = value.title;
        return {
                title: value$2,
                result: result
              };
      });
  var value$23 = value$1.evaluationCriteria;
  var evaluationCriteria = value$23.map(function (value) {
        var value$1 = value.gradeLabels;
        var gradeLabels = value$1.map(function (value) {
              var value$1 = value.label;
              var value$2 = value.grade;
              return {
                      grade: value$2,
                      label: value$1
                    };
            });
        var value$2 = value.passGrade;
        var value$3 = value.maxGrade;
        var value$4 = value.name;
        var value$5 = value.id;
        return {
                id: value$5,
                name: value$4,
                maxGrade: value$3,
                passGrade: value$2,
                gradeLabels: gradeLabels
              };
      });
  var value$24 = value$1.submissionReport;
  var submissionReport;
  if (value$24 !== undefined) {
    var value$25 = value$24.queuedAt;
    var value$26 = value$24.conclusion;
    var conclusion = value$26 !== undefined ? (
        typeof value$26 === "object" ? value$26.VAL : (
            value$26 === "failure" ? "failure" : (
                value$26 === "success" ? "success" : "error"
              )
          )
      ) : null;
    var value$27 = value$24.completedAt;
    var completedAt = value$27 !== undefined ? Caml_option.valFromOption(value$27) : null;
    var value$28 = value$24.startedAt;
    var startedAt = value$28 !== undefined ? Caml_option.valFromOption(value$28) : null;
    var value$29 = value$24.status;
    var status = typeof value$29 === "object" ? value$29.VAL : (
        value$29 === "completed" ? "completed" : (
            value$29 === "queued" ? "queued" : "in_progress"
          )
      );
    var value$30 = value$24.testReport;
    var testReport = value$30 !== undefined ? value$30 : null;
    var value$31 = value$24.id;
    submissionReport = {
      id: value$31,
      testReport: testReport,
      status: status,
      startedAt: startedAt,
      completedAt: completedAt,
      conclusion: conclusion,
      queuedAt: value$25
    };
  } else {
    submissionReport = null;
  }
  var value$32 = value$1.students;
  var students = value$32.map(function (value) {
        var value$1 = value.name;
        var value$2 = value.id;
        return {
                id: value$2,
                name: value$1
              };
      });
  var value$33 = value$1.inactiveSubmissionReviewAllowedDays;
  var value$34 = value$1.submissionReportPollTime;
  var value$35 = value$1.createdAt;
  var value$36 = value$1.inactiveStudents;
  var value$37 = value$1.levelId;
  var value$38 = value$1.levelNumber;
  var value$39 = value$1.targetTitle;
  var value$40 = value$1.targetId;
  var submissionDetails = {
    targetId: value$40,
    targetTitle: value$39,
    levelNumber: value$38,
    levelId: value$37,
    inactiveStudents: value$36,
    createdAt: value$35,
    submissionReportPollTime: value$34,
    inactiveSubmissionReviewAllowedDays: value$33,
    students: students,
    submissionReport: submissionReport,
    evaluationCriteria: evaluationCriteria,
    reviewChecklist: reviewChecklist,
    targetEvaluationCriteriaIds: targetEvaluationCriteriaIds,
    submission: submission,
    allSubmissions: allSubmissions,
    coaches: coaches,
    teamName: teamName,
    courseId: value$6,
    preview: value$5,
    reviewerDetails: reviewerDetails
  };
  return {
          submissionDetails: submissionDetails
        };
}

function serializeVariables(inp) {
  return {
          submissionId: inp.submissionId
        };
}

function makeVariables(submissionId, param) {
  return {
          submissionId: submissionId
        };
}

var SubmissionDetailsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var SubmissionDetailsQuery_Graphql_error = include.Graphql_error;

var SubmissionDetailsQuery_decodeNotification = include.decodeNotification;

var SubmissionDetailsQuery_decodeObject = include.decodeObject;

var SubmissionDetailsQuery_decodeNotifications = include.decodeNotifications;

var SubmissionDetailsQuery_decodeErrors = include.decodeErrors;

var SubmissionDetailsQuery_flashNotifications = include.flashNotifications;

var SubmissionDetailsQuery_sendQuery = include.sendQuery;

var SubmissionDetailsQuery_query = include.query;

var SubmissionDetailsQuery_fetch = include.$$fetch;

var SubmissionDetailsQuery = {
  SubmissionDetailsQuery_inner: SubmissionDetailsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: SubmissionDetailsQuery_Graphql_error,
  decodeNotification: SubmissionDetailsQuery_decodeNotification,
  decodeObject: SubmissionDetailsQuery_decodeObject,
  decodeNotifications: SubmissionDetailsQuery_decodeNotifications,
  decodeErrors: SubmissionDetailsQuery_decodeErrors,
  flashNotifications: SubmissionDetailsQuery_flashNotifications,
  sendQuery: SubmissionDetailsQuery_sendQuery,
  query: SubmissionDetailsQuery_query,
  $$fetch: SubmissionDetailsQuery_fetch,
  make: make
};

function getSubmissionDetails(submissionId, setState, param) {
  Curry._1(setState, (function (param) {
          return /* Loading */0;
        }));
  Curry._3(make, undefined, undefined, {
          submissionId: submissionId
        }).then(function (response) {
        Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.decodeJs(response.submissionDetails)
                      };
              }));
        return Promise.resolve(undefined);
      });
  
}

function updateSubmission(setState, submissionDetails, overlaySubmission) {
  var newSubmissionDetails = CoursesReview__SubmissionDetails.updateOverlaySubmission(overlaySubmission, submissionDetails);
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: newSubmissionDetails
                      };
              }));
}

function currentSubmissionIndex(submissionId, allSubmissions) {
  return allSubmissions.length - allSubmissions.findIndex(function (s) {
              return CoursesReview__SubmissionMeta.id(s) === submissionId;
            }) | 0;
}

function updateReviewChecklist(submissionDetails, setState, reviewChecklist) {
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.updateReviewChecklist(reviewChecklist, submissionDetails)
                      };
              }));
}

function updateReviewer(submissionDetails, setState, reviewer) {
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.updateReviewer(reviewer, submissionDetails)
                      };
              }));
}

function updateSubmissionReport(submissionDetails, setState, submissionReport) {
  return Curry._1(setState, (function (param) {
                return /* Loaded */{
                        _0: CoursesReview__SubmissionDetails.updateSubmissionReport(submissionReport, submissionDetails)
                      };
              }));
}

function CoursesReview__SubmissionsRoot(Props) {
  var submissionId = Props.submissionId;
  var currentUser = Props.currentUser;
  var match = React.useState(function () {
        return /* Loading */0;
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          return getSubmissionDetails(submissionId, setState, undefined);
        }), [submissionId]);
  var tmp;
  if (state) {
    var submissionDetails = state._0;
    tmp = React.createElement(CoursesReview__Editor.make, {
          overlaySubmission: CoursesReview__SubmissionDetails.submission(submissionDetails),
          teamSubmission: CoursesReview__SubmissionDetails.students(submissionDetails).length > 1,
          evaluationCriteria: CoursesReview__SubmissionDetails.evaluationCriteria(submissionDetails),
          reviewChecklist: CoursesReview__SubmissionDetails.reviewChecklist(submissionDetails),
          updateSubmissionCB: (function (param) {
              return updateSubmission(setState, submissionDetails, param);
            }),
          updateReviewChecklistCB: (function (param) {
              return updateReviewChecklist(submissionDetails, setState, param);
            }),
          targetId: CoursesReview__SubmissionDetails.targetId(submissionDetails),
          targetEvaluationCriteriaIds: CoursesReview__SubmissionDetails.targetEvaluationCriteriaIds(submissionDetails),
          currentUser: currentUser,
          number: currentSubmissionIndex(submissionId, CoursesReview__SubmissionDetails.allSubmissions(submissionDetails)),
          submissionDetails: submissionDetails,
          submissionId: submissionId,
          updateReviewerCB: (function (param) {
              return updateReviewer(submissionDetails, setState, param);
            }),
          submissionReport: CoursesReview__SubmissionDetails.submissionReport(submissionDetails),
          updateSubmissionReportCB: (function (param) {
              return updateSubmissionReport(submissionDetails, setState, param);
            }),
          submissionReportPollTime: CoursesReview__SubmissionDetails.submissionReportPollTime(submissionDetails)
        });
  } else {
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "bg-gray-50 md:px-4"
            }, React.createElement("div", {
                  className: "mx-auto"
                }, SkeletonLoading.card(undefined, undefined))), React.createElement("div", {
              className: "grid md:grid-cols-2 mt-10 border-t h-full"
            }, React.createElement("div", {
                  className: "md:px-4 bg-white"
                }, SkeletonLoading.heading(undefined), SkeletonLoading.multiple(3, SkeletonLoading.paragraph(undefined))), React.createElement("div", {
                  className: "md:px-4 bg-gray-50 border-l"
                }, SkeletonLoading.userDetails(undefined), SkeletonLoading.paragraph(undefined), SkeletonLoading.userDetails(undefined), SkeletonLoading.paragraph(undefined))));
  }
  return React.createElement("div", {
              className: "flex-1 md:flex md:flex-col md:overflow-hidden"
            }, tmp);
}

var UserProxyFragment;

var make$1 = CoursesReview__SubmissionsRoot;

export {
  str ,
  UserProxyFragment ,
  SubmissionDetailsQuery ,
  getSubmissionDetails ,
  updateSubmission ,
  currentSubmissionIndex ,
  updateReviewChecklist ,
  updateReviewer ,
  updateSubmissionReport ,
  make$1 as make,
  
}
/* include Not a pure module */
