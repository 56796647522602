// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as User from "../../shared/types/User.bs.js";
import * as React from "react";
import * as Avatar from "../../shared/Avatar.bs.js";
import * as Tooltip from "../../shared/components/Tooltip.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MarkdownBlock from "../../shared/components/MarkdownBlock.bs.js";
import * as TopicsShow__Post from "../types/TopicsShow__Post.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.TopicsShow__PostReply";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

import "./TopicsShow__PostReply.css"
;

var avatarClasses = "w-6 h-6 md:w-8 md:h-8 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover";

function avatar(user) {
  var avatarUrl = Belt_Option.flatMap(user, User.avatarUrl);
  var name = Belt_Option.mapWithDefault(user, "?", User.name);
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses,
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses
              });
  }
}

function navigateToPost(postId) {
  var elementId = "post-show-" + postId;
  var element = document.getElementById(elementId);
  if (element == null) {
    Rollbar.error("Could not find the post to scroll to.");
  } else {
    element.scrollIntoView();
    element.className = "topics-show__highlighted-item";
  }
  
}

function TopicsShow__PostReply(Props) {
  var post = Props.post;
  var users = Props.users;
  var user = TopicsShow__Post.user(users, post);
  var tip = React.createElement("div", {
        className: "text-left"
      }, t(undefined, undefined, "jump_reply"));
  return React.createElement("div", {
              className: "topics-post-reply-show__replies flex flex-col border bg-gray-50 rounded-lg mb-2 p-2 md:p-4"
            }, React.createElement("div", {
                  className: "flex justify-between"
                }, React.createElement("div", {
                      className: "flex items-center"
                    }, avatar(user), React.createElement("span", {
                          className: "text-xs font-semibold ml-2"
                        }, Belt_Option.mapWithDefault(user, "Unknown", User.name))), React.createElement(Tooltip.make, {
                      tip: tip,
                      position: "Left",
                      children: React.createElement("div", {
                            "aria-label": t(undefined, undefined, "navigate_post") + " " + TopicsShow__Post.id(post),
                            className: "shrink-0 flex items-center justify-center w-7 h-7 rounded leading-tight border bg-gray-50 text-gray-600 cursor-pointer hover:bg-gray-300",
                            onClick: (function (param) {
                                return navigateToPost(TopicsShow__Post.id(post));
                              })
                          }, React.createElement("i", {
                                className: "fas fa-angle-double-down"
                              }))
                    })), React.createElement("div", {
                  className: "text-sm ml-9"
                }, React.createElement(MarkdownBlock.make, {
                      markdown: TopicsShow__Post.body(post),
                      className: "leading-normal text-sm ",
                      profile: /* Permissive */0
                    })));
}

var make = TopicsShow__PostReply;

export {
  str ,
  t ,
  avatarClasses ,
  avatar ,
  navigateToPost ,
  make ,
  
}
/*  Not a pure module */
