// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";

function make(id, title, createdAt, levelId, status, targetId, targetRole) {
  return {
          id: id,
          title: title,
          createdAt: createdAt,
          status: status,
          levelId: levelId,
          targetId: targetId,
          targetRole: targetRole
        };
}

function id(t) {
  return t.id;
}

function levelId(t) {
  return t.levelId;
}

function title(t) {
  return t.title;
}

function status(t) {
  return t.status;
}

function createdAt(t) {
  return t.createdAt;
}

function targetId(t) {
  return t.targetId;
}

function targetRole(t) {
  return t.targetRole;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function makeFromJs(submissions) {
  return submissions.map(function (submission) {
              var createdAt = DateFns.decodeISO(submission.createdAt);
              var _passedAt = submission.passedAt;
              var status;
              if (_passedAt !== undefined) {
                status = "Completed";
              } else {
                var _time = submission.evaluatedAt;
                status = _time !== undefined ? "Rejected" : "PendingReview";
              }
              var targetRole = submission.teamTarget ? /* Team */({
                    _0: submission.studentIds
                  }) : /* Student */0;
              return make(submission.id, submission.title, createdAt, submission.levelId, status, submission.targetId, targetRole);
            });
}

export {
  make ,
  id ,
  levelId ,
  title ,
  status ,
  createdAt ,
  targetId ,
  targetRole ,
  createdAtPretty ,
  makeFromJs ,
  
}
/* DateFns Not a pure module */
