// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as Tooltip from "../../../shared/components/Tooltip.bs.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";

function str(prim) {
  return prim;
}

function avatarClasses(size) {
  var mdSize = size[1];
  var defaultSize = size[0];
  return "w-" + (defaultSize + (" h-" + (defaultSize + (" md:w-" + (mdSize + (" md:h-" + (mdSize + " text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover")))))));
}

function avatar(sizeOpt, avatarUrl, name) {
  var size = sizeOpt !== undefined ? sizeOpt : [
      "8",
      "10"
    ];
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses(size),
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses(size)
              });
  }
}

function CoursesStudents__PersonalCoaches(Props) {
  var tooltipPositionOpt = Props.tooltipPosition;
  var defaultAvatarSizeOpt = Props.defaultAvatarSize;
  var mdAvatarSizeOpt = Props.mdAvatarSize;
  var title = Props.title;
  var className = Props.className;
  var coaches = Props.coaches;
  var tooltipPosition = tooltipPositionOpt !== undefined ? tooltipPositionOpt : "Top";
  var defaultAvatarSize = defaultAvatarSizeOpt !== undefined ? defaultAvatarSizeOpt : "6";
  var mdAvatarSize = mdAvatarSizeOpt !== undefined ? mdAvatarSizeOpt : "8";
  if (!ArrayUtils.isNotEmpty(coaches)) {
    return React.createElement("div", undefined);
  }
  var listedCoaches = coaches.length <= 4 ? coaches : coaches.slice(0, 3);
  var otherCoaches;
  if (coaches.length > 4) {
    var names = coaches.slice(3).map(function (coach) {
          return React.createElement("div", {
                      key: UserProxy.userId(coach)
                    }, UserProxy.name(coach));
        });
    var count = coaches.length - 3 | 0;
    otherCoaches = [
      names,
      count
    ];
  } else {
    otherCoaches = undefined;
  }
  return React.createElement("div", {
              className: className
            }, React.createElement("div", {
                  className: "text-xs"
                }, title), React.createElement("div", {
                  className: "inline-flex"
                }, $$Array.map((function (coach) {
                        return React.createElement(Tooltip.make, {
                                    tip: UserProxy.name(coach),
                                    className: "-mr-1",
                                    position: tooltipPosition,
                                    children: avatar([
                                          defaultAvatarSize,
                                          mdAvatarSize
                                        ], UserProxy.avatarUrl(coach), UserProxy.name(coach)),
                                    key: UserProxy.userId(coach)
                                  });
                      }), listedCoaches), OptionUtils.mapWithDefault((function (param) {
                        return React.createElement(Tooltip.make, {
                                    tip: param[0],
                                    className: "-mr-1",
                                    children: React.createElement(Avatar.make, {
                                          colors: [
                                            "#EEE",
                                            "#000"
                                          ],
                                          name: "+ " + String(param[1]),
                                          className: avatarClasses([
                                                defaultAvatarSize,
                                                mdAvatarSize
                                              ])
                                        })
                                  });
                      }), null, otherCoaches)));
}

var make = CoursesStudents__PersonalCoaches;

export {
  str ,
  avatarClasses ,
  avatar ,
  make ,
  
}
/* react Not a pure module */
