// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Avatar from "../../shared/Avatar.bs.js";
import * as Tooltip from "../../shared/components/Tooltip.bs.js";
import * as CommunitiesShow__TopicParticipant from "../types/CommunitiesShow__TopicParticipant.bs.js";

function str(prim) {
  return prim;
}

function avatarClasses(size) {
  var mdSize = size[1];
  var defaultSize = size[0];
  return "w-" + (defaultSize + (" h-" + (defaultSize + (" md:w-" + (mdSize + (" md:h-" + (mdSize + " text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover")))))));
}

function avatar(sizeOpt, avatarUrl, name) {
  var size = sizeOpt !== undefined ? sizeOpt : [
      "8",
      "10"
    ];
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses(size),
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses(size)
              });
  }
}

function CommunitiesShow__Participants(Props) {
  var tooltipPositionOpt = Props.tooltipPosition;
  var defaultAvatarSizeOpt = Props.defaultAvatarSize;
  var mdAvatarSizeOpt = Props.mdAvatarSize;
  var title = Props.title;
  var className = Props.className;
  var participants = Props.participants;
  var participantsCount = Props.participantsCount;
  var tooltipPosition = tooltipPositionOpt !== undefined ? tooltipPositionOpt : "Top";
  var defaultAvatarSize = defaultAvatarSizeOpt !== undefined ? defaultAvatarSizeOpt : "6";
  var mdAvatarSize = mdAvatarSizeOpt !== undefined ? mdAvatarSizeOpt : "8";
  var otherParticipantsCount = participantsCount - participants.length | 0;
  return React.createElement("div", {
              className: className
            }, React.createElement("div", {
                  className: "text-xs"
                }, title), React.createElement("div", {
                  className: "inline-flex"
                }, $$Array.map((function (participant) {
                        return React.createElement(Tooltip.make, {
                                    tip: CommunitiesShow__TopicParticipant.name(participant),
                                    className: "-mr-1",
                                    position: tooltipPosition,
                                    children: avatar([
                                          defaultAvatarSize,
                                          mdAvatarSize
                                        ], CommunitiesShow__TopicParticipant.avatarUrl(participant), CommunitiesShow__TopicParticipant.name(participant)),
                                    key: CommunitiesShow__TopicParticipant.id(participant)
                                  });
                      }), participants), otherParticipantsCount > 0 ? React.createElement(Avatar.make, {
                        colors: [
                          "#EEE",
                          "#000"
                        ],
                        name: "+ " + String(otherParticipantsCount),
                        className: avatarClasses([
                              defaultAvatarSize,
                              mdAvatarSize
                            ])
                      }) : null));
}

var make = CommunitiesShow__Participants;

export {
  str ,
  avatarClasses ,
  avatar ,
  make ,
  
}
/* react Not a pure module */
