// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as GradeLabel from "../../../shared/types/GradeLabel.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";
import * as CoursesReview__Student from "./CoursesReview__Student.bs.js";
import * as CoursesReview__Reviewer from "./CoursesReview__Reviewer.bs.js";
import * as CoursesReview__SubmissionMeta from "./CoursesReview__SubmissionMeta.bs.js";
import * as CoursesReview__SubmissionReport from "./CoursesReview__SubmissionReport.bs.js";
import * as CoursesReview__OverlaySubmission from "./CoursesReview__OverlaySubmission.bs.js";
import * as CoursesReview__ReviewChecklistItem from "./CoursesReview__ReviewChecklistItem.bs.js";

function submission(t) {
  return t.submission;
}

function allSubmissions(t) {
  return t.allSubmissions;
}

function targetId(t) {
  return t.targetId;
}

function targetTitle(t) {
  return t.targetTitle;
}

function levelNumber(t) {
  return t.levelNumber;
}

function students(t) {
  return t.students;
}

function evaluationCriteria(t) {
  return t.evaluationCriteria;
}

function reviewChecklist(t) {
  return t.reviewChecklist;
}

function targetEvaluationCriteriaIds(t) {
  return t.targetEvaluationCriteriaIds;
}

function inactiveStudents(t) {
  return t.inactiveStudents;
}

function coaches(t) {
  return t.coaches;
}

function teamName(t) {
  return t.teamName;
}

function courseId(t) {
  return t.courseId;
}

function createdAt(t) {
  return t.createdAt;
}

function preview(t) {
  return t.preview;
}

function reviewer(t) {
  return t.reviewer;
}

function submissionReport(t) {
  return t.submissionReport;
}

function submissionReportPollTime(t) {
  return t.submissionReportPollTime;
}

function inactiveSubmissionReviewAllowedDays(t) {
  return t.inactiveSubmissionReviewAllowedDays;
}

function make(submission, allSubmissions, targetId, targetTitle, students, levelNumber, evaluationCriteria, levelId, reviewChecklist, targetEvaluationCriteriaIds, inactiveStudents, submissionReport, coaches, teamName, courseId, createdAt, preview, reviewer, submissionReportPollTime, inactiveSubmissionReviewAllowedDays) {
  return {
          submission: submission,
          createdAt: createdAt,
          allSubmissions: allSubmissions,
          targetId: targetId,
          targetTitle: targetTitle,
          students: students,
          levelNumber: levelNumber,
          levelId: levelId,
          submissionReport: submissionReport,
          evaluationCriteria: evaluationCriteria,
          reviewChecklist: reviewChecklist,
          targetEvaluationCriteriaIds: targetEvaluationCriteriaIds,
          inactiveStudents: inactiveStudents,
          coaches: coaches,
          teamName: teamName,
          courseId: courseId,
          preview: preview,
          reviewer: reviewer,
          submissionReportPollTime: submissionReportPollTime,
          inactiveSubmissionReviewAllowedDays: inactiveSubmissionReviewAllowedDays
        };
}

function decodeJs(details) {
  return make(CoursesReview__OverlaySubmission.makeFromJs(details.submission), ArrayUtils.copyAndSort((function (s1, s2) {
                    return DateFns.differenceInSeconds(CoursesReview__SubmissionMeta.createdAt(s2), CoursesReview__SubmissionMeta.createdAt(s1));
                  }), CoursesReview__SubmissionMeta.makeFromJs(details.allSubmissions)), details.targetId, details.targetTitle, details.students.map(CoursesReview__Student.makeFromJs), details.levelNumber, details.evaluationCriteria.map(function (ec) {
                  return EvaluationCriterion.make(ec.id, ec.name, ec.maxGrade, ec.passGrade, ec.gradeLabels.map(GradeLabel.makeFromJs));
                }), details.levelId, CoursesReview__ReviewChecklistItem.makeFromJs(details.reviewChecklist), details.targetEvaluationCriteriaIds, details.inactiveStudents, Belt_Option.map(details.submissionReport, CoursesReview__SubmissionReport.makeFromJS), details.coaches.map(UserProxy.makeFromJs), details.teamName, details.courseId, DateFns.decodeISO(details.createdAt), details.preview, Belt_Option.map(details.reviewerDetails, CoursesReview__Reviewer.makeFromJs), details.submissionReportPollTime, details.inactiveSubmissionReviewAllowedDays);
}

function updateMetaSubmission(submission) {
  return CoursesReview__SubmissionMeta.make(CoursesReview__OverlaySubmission.id(submission), CoursesReview__OverlaySubmission.createdAt(submission), CoursesReview__OverlaySubmission.passedAt(submission), CoursesReview__OverlaySubmission.evaluatedAt(submission), ArrayUtils.isNotEmpty(CoursesReview__OverlaySubmission.feedback(submission)), CoursesReview__OverlaySubmission.archivedAt(submission));
}

function updateOverlaySubmission(submission, t) {
  var newrecord = Caml_obj.caml_obj_dup(t);
  newrecord.allSubmissions = t.allSubmissions.map(function (s) {
        if (CoursesReview__SubmissionMeta.id(s) === CoursesReview__OverlaySubmission.id(submission)) {
          return updateMetaSubmission(submission);
        } else {
          return s;
        }
      });
  newrecord.submission = submission;
  return newrecord;
}

function updateReviewChecklist(reviewChecklist, t) {
  var newrecord = Caml_obj.caml_obj_dup(t);
  newrecord.reviewChecklist = reviewChecklist;
  return newrecord;
}

function updateReviewer(user, t) {
  var newrecord = Caml_obj.caml_obj_dup(t);
  newrecord.reviewer = Belt_Option.map(user, CoursesReview__Reviewer.setReviewer);
  return newrecord;
}

function updateSubmissionReport(report, t) {
  var newrecord = Caml_obj.caml_obj_dup(t);
  newrecord.submissionReport = report;
  return newrecord;
}

var OverlaySubmission;

var IndexSubmission;

var Student;

var ReviewChecklistItem;

var SubmissionMeta;

var Coach;

var Reviewer;

var SubmissionReport;

export {
  OverlaySubmission ,
  IndexSubmission ,
  Student ,
  ReviewChecklistItem ,
  SubmissionMeta ,
  Coach ,
  Reviewer ,
  SubmissionReport ,
  submission ,
  allSubmissions ,
  targetId ,
  targetTitle ,
  levelNumber ,
  students ,
  evaluationCriteria ,
  reviewChecklist ,
  targetEvaluationCriteriaIds ,
  inactiveStudents ,
  coaches ,
  teamName ,
  courseId ,
  createdAt ,
  preview ,
  reviewer ,
  submissionReport ,
  submissionReportPollTime ,
  inactiveSubmissionReviewAllowedDays ,
  make ,
  decodeJs ,
  updateMetaSubmission ,
  updateOverlaySubmission ,
  updateReviewChecklist ,
  updateReviewer ,
  updateSubmissionReport ,
  
}
/* DateFns Not a pure module */
