// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ArrayUtils from "../utils/ArrayUtils.bs.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function avatarUrl(t) {
  return t.avatarUrl;
}

function fullTitle(t) {
  return t.fullTitle;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          avatarUrl: Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          fullTitle: Json_decode.field("fullTitle", Json_decode.string, json)
        };
}

function findById(id, proxies) {
  return ArrayUtils.unsafeFind((function (proxy) {
                return proxy.id === id;
              }), "Unable to find a User with ID " + id, proxies);
}

function make(id, name, avatarUrl, fullTitle) {
  return {
          id: id,
          name: name,
          avatarUrl: avatarUrl,
          fullTitle: fullTitle
        };
}

function makeFromJs(jsObject) {
  return make(jsObject.id, jsObject.name, jsObject.avatarUrl, jsObject.fullTitle);
}

var Raw = {};

function parse(value) {
  var value$1 = value.avatarUrl;
  return {
          id: value.id,
          name: value.name,
          fullTitle: value.fullTitle,
          avatarUrl: !(value$1 == null) ? value$1 : undefined
        };
}

function serialize(value) {
  var value$1 = value.avatarUrl;
  var avatarUrl = value$1 !== undefined ? value$1 : null;
  var value$2 = value.fullTitle;
  var value$3 = value.name;
  var value$4 = value.id;
  return {
          id: value$4,
          name: value$3,
          fullTitle: value$2,
          avatarUrl: avatarUrl
        };
}

function verifyArgsAndParse(_UserFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Fragment = {
  Raw: Raw,
  query: "fragment UserFragment on User   {\nid  \nname  \nfullTitle  \navatarUrl  \n}\n",
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

function makeFromFragment(user) {
  return make(user.id, user.name, user.avatarUrl, user.fullTitle);
}

export {
  id ,
  name ,
  avatarUrl ,
  fullTitle ,
  decode ,
  findById ,
  make ,
  makeFromJs ,
  Fragment ,
  makeFromFragment ,
  
}
/* ArrayUtils Not a pure module */
