// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as TopicCategory from "../shared/types/TopicCategory.bs.js";
import * as CommunitiesShow__Root from "../communities/CommunitiesShow__Root.bs.js";
import * as CommunitiesShow__Target from "../communities/types/CommunitiesShow__Target.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("communityId", Json_decode.string, json),
          Json_decode.optional((function (param) {
                  return Json_decode.field("target", CommunitiesShow__Target.decode, param);
                }), json),
          Json_decode.field("topicCategories", (function (param) {
                  return Json_decode.array(TopicCategory.decode, param);
                }), json),
          Json_decode.field("userEmail", Json_decode.string, json),
          Json_decode.field("userAccessToken", Json_decode.string, json)
        ];
}

Psj.match(undefined, "communities#show", (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag(undefined, undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(CommunitiesShow__Root.make, {
                    communityId: match[0],
                    target: match[1],
                    topicCategories: match[2],
                    userEmail: match[3],
                    userAccessToken: match[4]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
