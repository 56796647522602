// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";

function make(id, title, createdAt, passedAt, levelId, evaluatedAt) {
  return {
          id: id,
          title: title,
          createdAt: createdAt,
          passedAt: passedAt,
          evaluatedAt: evaluatedAt,
          levelId: levelId
        };
}

function id(t) {
  return t.id;
}

function levelId(t) {
  return t.levelId;
}

function title(t) {
  return t.title;
}

function evaluatedAt(t) {
  return t.evaluatedAt;
}

function sort(submissions) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return DateFns.differenceInSeconds(y.createdAt, x.createdAt);
              }), submissions);
}

function failed(t) {
  var _passedAt = t.passedAt;
  return _passedAt === undefined;
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function timeDistance(t) {
  return DateFns.formatDistanceToNowStrict(t.createdAt, true, undefined, undefined, undefined);
}

function makeFromJs(submissions) {
  return submissions.map(function (submission) {
              var createdAt = DateFns.decodeISO(submission.createdAt);
              var passedAt = Belt_Option.map(submission.passedAt, DateFns.decodeISO);
              var evaluatedAt = Belt_Option.map(submission.evaluatedAt, DateFns.decodeISO);
              return make(submission.id, submission.title, createdAt, passedAt, submission.levelId, evaluatedAt);
            });
}

export {
  make ,
  id ,
  levelId ,
  title ,
  evaluatedAt ,
  sort ,
  failed ,
  createdAtPretty ,
  timeDistance ,
  makeFromJs ,
  
}
/* DateFns Not a pure module */
