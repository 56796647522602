// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as CoursesReview__ChecklistShow from "./CoursesReview__ChecklistShow.bs.js";
import * as CoursesReview__ChecklistEditor from "./CoursesReview__ChecklistEditor.bs.js";

var partial_arg = "components.CoursesReview__Checklist";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function closeEditMode(setState, param) {
  return Curry._1(setState, (function (param) {
                return /* Show */1;
              }));
}

function showEditor(setState, param) {
  return Curry._1(setState, (function (param) {
                return /* Edit */0;
              }));
}

function updateReviewChecklist(setState, updateReviewChecklistCB, reviewChecklist) {
  Curry._1(updateReviewChecklistCB, reviewChecklist);
  return Curry._1(setState, (function (param) {
                return /* Show */1;
              }));
}

function handleEmpty(setState) {
  return React.createElement("div", {
              className: "p-4 md:p-6"
            }, React.createElement("button", {
                  className: "bg-gray-50 border border-primary-500 border-dashed rounded-lg p-3 md:p-5 flex items-center w-full hover:bg-gray-50 hover:border-primary-600 hover:shadow-lg focus:outline-none",
                  onClick: (function (param) {
                      return Curry._1(setState, (function (param) {
                                    return /* Edit */0;
                                  }));
                    })
                }, React.createElement("span", {
                      className: "inline-flex shrink-0 bg-white w-14 h-14 border border-dashed border-primary-500 rounded-full items-center justify-center shadow-md"
                    }, React.createElement("span", {
                          className: "inline-flex items-center justify-center shrink-0 w-10 h-10 rounded-full bg-primary-500 text-white"
                        }, React.createElement("i", {
                              className: "fa fa-plus"
                            }))), React.createElement("span", {
                      className: "block text-left ml-4"
                    }, React.createElement("span", {
                          className: "block text-base font-semibold text-primary-400"
                        }, t(undefined, undefined, "create_review_checklist")), React.createElement("span", {
                          className: "text-sm block"
                        }, t(undefined, undefined, "create_review_checklist_description")))));
}

function CoursesReview__Checklist(Props) {
  var reviewChecklist = Props.reviewChecklist;
  var updateFeedbackCB = Props.updateFeedbackCB;
  var feedback = Props.feedback;
  var updateReviewChecklistCB = Props.updateReviewChecklistCB;
  var targetId = Props.targetId;
  var cancelCB = Props.cancelCB;
  var overlaySubmission = Props.overlaySubmission;
  var submissionDetails = Props.submissionDetails;
  var match = React.useState(function () {
        if (ArrayUtils.isEmpty(reviewChecklist)) {
          return /* Edit */0;
        } else {
          return /* Show */1;
        }
      });
  var setState = match[1];
  return React.createElement("div", undefined, match[0] ? React.createElement(CoursesReview__ChecklistShow.make, {
                    reviewChecklist: reviewChecklist,
                    feedback: feedback,
                    updateFeedbackCB: updateFeedbackCB,
                    showEditorCB: (function (param) {
                        return Curry._1(setState, (function (param) {
                                      return /* Edit */0;
                                    }));
                      }),
                    cancelCB: cancelCB,
                    overlaySubmission: overlaySubmission,
                    submissionDetails: submissionDetails
                  }) : React.createElement(CoursesReview__ChecklistEditor.make, {
                    reviewChecklist: reviewChecklist,
                    updateReviewChecklistCB: (function (param) {
                        Curry._1(updateReviewChecklistCB, param);
                        return Curry._1(setState, (function (param) {
                                      return /* Show */1;
                                    }));
                      }),
                    closeEditModeCB: (function (param) {
                        return Curry._1(setState, (function (param) {
                                      return /* Show */1;
                                    }));
                      }),
                    targetId: targetId
                  }));
}

var make = CoursesReview__Checklist;

export {
  t ,
  str ,
  closeEditMode ,
  showEditor ,
  updateReviewChecklist ,
  handleEmpty ,
  make ,
  
}
/* I18n Not a pure module */
