// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../shared/types/User.bs.js";
import * as React from "react";
import * as Avatar from "../../shared/Avatar.bs.js";
import * as DateFns from "../../shared/utils/DateFns.bs.js";

function str(prim) {
  return prim;
}

var avatarClasses = "w-9 h-9 md:w-9 md:h-9 text-xs rounded-full overflow-hidden shrink-0 object-cover";

function avatar(avatarUrl, name) {
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses,
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses
              });
  }
}

function TopicsShow__UserShow(Props) {
  var user = Props.user;
  var createdAt = Props.createdAt;
  var match = user !== undefined ? [
      User.name(user),
      User.avatarUrl(user),
      User.fullTitle(user)
    ] : [
      "Unknown",
      undefined,
      "Unknown"
    ];
  var name = match[0];
  return React.createElement("div", undefined, React.createElement("p", {
                  className: "hidden lg:block text-xs text-gray-800"
                }, DateFns.format(createdAt, "do MMMM, yyyy HH:mm")), React.createElement("div", {
                  className: "pl-0 py-2 lg:p-2 flex flex-row items-center lg:bg-gray-50 lg:border rounded-lg lg:mt-1"
                }, React.createElement("div", {
                      className: "w-9 h-9 rounded-full bg-gray-500 text-white border border-gray-300 flex items-center justify-center shrink-0 overflow-hidden"
                    }, avatar(match[1], name)), React.createElement("div", {
                      className: "pl-2"
                    }, React.createElement("p", {
                          className: "font-semibold text-xs"
                        }, name), React.createElement("p", {
                          className: "text-xs leadig-normal"
                        }, match[2]))), React.createElement("p", {
                  className: "block lg:hidden pb-2 text-xs text-gray-800"
                }, DateFns.format(createdAt, "do MMMM, yyyy HH:mm")));
}

var make = TopicsShow__UserShow;

export {
  str ,
  avatarClasses ,
  avatar ,
  make ,
  
}
/* User Not a pure module */
