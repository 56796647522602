// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../shared/utils/DateFns.bs.js";

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function createdAt(t) {
  return t.createdAt;
}

function repliesCount(t) {
  return t.repliesCount;
}

function makeFromJs(jsObject) {
  return {
          id: jsObject.id,
          title: jsObject.title,
          createdAt: DateFns.decodeISO(jsObject.createdAt),
          repliesCount: jsObject.liveRepliesCount
        };
}

export {
  id ,
  title ,
  createdAt ,
  repliesCount ,
  makeFromJs ,
  
}
/* DateFns Not a pure module */
