// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function serialNumber(t) {
  return t.serialNumber;
}

function createdAt(t) {
  return t.createdAt;
}

function courseName(t) {
  return t.courseName;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          serialNumber: Json_decode.field("serialNumber", Json_decode.string, json),
          createdAt: Json_decode.field("createdAt", DateFns.decodeISO, json),
          courseName: Json_decode.field("courseName", Json_decode.string, json)
        };
}

export {
  id ,
  serialNumber ,
  createdAt ,
  courseName ,
  decode ,
  
}
/* DateFns Not a pure module */
