// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Radio from "../../../shared/components/Radio.bs.js";
import * as React from "react";
import * as PfIcon from "../../../packages/pf-icon/src/PfIcon.bs.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as UrlUtils from "../../../shared/utils/UrlUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as MarkdownEditor from "../../../shared/components/MarkdownEditor.bs.js";
import * as CoursesCurriculum__FileForm from "./CoursesCurriculum__FileForm.bs.js";
import * as CoursesCurriculum__AudioRecorder from "./CoursesCurriculum__AudioRecorder.bs.js";
import * as CoursesCurriculum__ChecklistItem from "../types/CoursesCurriculum__ChecklistItem.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__SubmissionItem";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function kindIconClasses(result) {
  switch (result.TAG | 0) {
    case /* Files */0 :
        return "if i-file-regular md:text-base text-gray-800 if-fw";
    case /* Link */1 :
        return "if i-link-regular md:text-base text-gray-800 if-fw";
    case /* ShortText */2 :
        return "if i-short-text-regular md:text-base text-gray-800 if-fw";
    case /* LongText */3 :
        return "if i-long-text-regular md:text-base text-gray-800 if-fw";
    case /* MultiChoice */4 :
        return "if i-check-circle-alt-regular md:text-base text-gray-800 if-fw";
    case /* AudioRecord */5 :
        return "if i-microphone-outline-regular md:text-base text-gray-800 if-fw";
    
  }
}

function computeId(index, checklistItem) {
  return String(index) + ("-" + CoursesCurriculum__ChecklistItem.kindAsString(checklistItem));
}

function notBlank(string) {
  return $$String.trim(string) !== "";
}

function placeholder(id, checklistItem) {
  var title = CoursesCurriculum__ChecklistItem.title(checklistItem);
  var optional = CoursesCurriculum__ChecklistItem.optional(checklistItem);
  return React.createElement("div", {
              className: "flex items-start"
            }, React.createElement(PfIcon.make, {
                  className: kindIconClasses(CoursesCurriculum__ChecklistItem.result(checklistItem))
                }), React.createElement("label", {
                  className: "font-semibold text-sm pl-2 tracking-wide overflow-auto",
                  htmlFor: id
                }, React.createElement(MarkdownBlock.make, {
                      markdown: title + (
                        optional ? " (" + I18n.ts(undefined, undefined, "optional") + ")" : ""
                      ),
                      profile: /* Permissive */0
                    })));
}

function showError(message, active) {
  if (active) {
    return React.createElement("div", {
                className: "mt-1 px-1 py-px rounded text-xs font-semibold text-red-600 bg-red-100 inline-flex items-center"
              }, React.createElement("span", {
                    className: "mr-2"
                  }, React.createElement("i", {
                        className: "fas fa-exclamation-triangle"
                      })), React.createElement("span", undefined, message));
  } else {
    return null;
  }
}

function showLink(value, id, updateResultCB) {
  return React.createElement("div", undefined, React.createElement("input", {
                  className: "cursor-pointer truncate h-10 border border-gray-300 focus:outline-none focus:border-primary-400 focus:shadow-inner px-4 items-center font-semibold rounded text-sm mr-2 block w-full",
                  id: id,
                  placeholder: tr(undefined, undefined, "link_placeholder"),
                  type: "text",
                  value: value,
                  onChange: (function (e) {
                      return Curry._1(updateResultCB, {
                                  TAG: /* Link */1,
                                  _0: e.target.value
                                });
                    })
                }), showError(tr(undefined, undefined, "link_error"), UrlUtils.isInvalid(true, value)));
}

function showShortText(value, id, updateResultCB) {
  return React.createElement("div", undefined, React.createElement("input", {
                  className: "cursor-pointer truncate h-10 border border-gray-300 focus:outline-none focus:border-primary-400 focus:shadow-inner px-4 items-center font-semibold rounded text-sm mr-2 block w-full",
                  id: id,
                  maxLength: 250,
                  placeholder: tr(undefined, undefined, "short_text_placeholder"),
                  type: "text",
                  value: value,
                  onChange: (function (e) {
                      return Curry._1(updateResultCB, {
                                  TAG: /* ShortText */2,
                                  _0: e.target.value
                                });
                    })
                }), showError(tr(undefined, undefined, "short_text_error"), !CoursesCurriculum__ChecklistItem.validShortText(value) && $$String.trim(value) !== ""));
}

function longTextWarning(value) {
  var currentLength = value.length;
  var showWarning = $$String.trim(value) !== "" && currentLength > 4500;
  var colors = currentLength < 4900 ? "text-orange-700 bg-orange-100" : "text-red-600 bg-red-100";
  if (showWarning) {
    return React.createElement("div", {
                className: "flex justify-between items-center mt-1"
              }, React.createElement("div", {
                    className: "hidden md:inline px-2 py-px rounded text-xs font-semibold inline-flex items-center " + colors
                  }, React.createElement("span", {
                        className: "mr-2"
                      }, React.createElement("i", {
                            className: "fas fa-exclamation-triangle"
                          })), React.createElement("span", undefined, tr(undefined, undefined, "warning_length_limit"))), React.createElement("div", {
                    className: "shrink-1 text-tiny font-semibold px-1 py-px border border-transparent rounded " + colors
                  }, String(currentLength), " / 5000"));
  } else {
    return null;
  }
}

function updateLongText(updateResultCB, value) {
  return Curry._1(updateResultCB, {
              TAG: /* LongText */3,
              _0: value
            });
}

function showLongText(value, id, updateResultCB) {
  return React.createElement("div", undefined, React.createElement(MarkdownEditor.make, {
                  value: value,
                  onChange: (function (param) {
                      return Curry._1(updateResultCB, {
                                  TAG: /* LongText */3,
                                  _0: param
                                });
                    }),
                  profile: /* Permissive */0,
                  textareaId: id,
                  maxLength: 5000
                }), longTextWarning(value));
}

function checkboxOnChange(choices, itemIndex, updateResultCB, $$event) {
  if ($$event.target.checked) {
    return Curry._1(updateResultCB, {
                TAG: /* MultiChoice */4,
                _0: choices,
                _1: itemIndex
              });
  } else {
    return Curry._1(updateResultCB, {
                TAG: /* MultiChoice */4,
                _0: choices,
                _1: undefined
              });
  }
}

function showMultiChoice(choices, choice, id, updateResultCB) {
  return choices.map(function (label, index) {
              var checked = Belt_Option.mapWithDefault(choice, false, (function (i) {
                      return i === index;
                    }));
              return React.createElement(Radio.make, {
                          id: id + String(index),
                          label: label,
                          onChange: (function (param) {
                              return checkboxOnChange(choices, index, updateResultCB, param);
                            }),
                          checked: checked,
                          key: String(index)
                        });
            });
}

function attachFile(updateResultCB, attachingCB, files, id, filename) {
  Curry._1(attachingCB, false);
  return Curry._1(updateResultCB, {
              TAG: /* Files */0,
              _0: $$Array.append([CoursesCurriculum__ChecklistItem.makeFile(id, filename)], files)
            });
}

function attachRecordingFile(updateResultCB, attachingCB, id, filename) {
  Curry._1(attachingCB, false);
  return Curry._1(updateResultCB, {
              TAG: /* AudioRecord */5,
              _0: {
                id: id,
                name: filename
              }
            });
}

function removeFile(updateResultCB, files, id) {
  return Curry._1(updateResultCB, {
              TAG: /* Files */0,
              _0: files.filter(function (a) {
                    return CoursesCurriculum__ChecklistItem.fileId(a) !== id;
                  })
            });
}

function showFiles(files, preview, id, attachingCB, updateResultCB, index) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex flex-wrap",
                  id: id
                }, $$Array.map((function (file) {
                        return React.createElement("div", {
                                    key: "file-" + CoursesCurriculum__ChecklistItem.fileId(file),
                                    "aria-label": "file-" + CoursesCurriculum__ChecklistItem.filename(file),
                                    className: "w-1/3 pr-2 pb-2",
                                    target: "_blank"
                                  }, React.createElement("div", {
                                        className: "flex justify-between border overflow-hidden rounded border-red-300 bg-white text-red-600 hover:text-red-600"
                                      }, React.createElement("div", {
                                            className: "flex"
                                          }, React.createElement("span", {
                                                className: "flex w-10 justify-center items-center p-2 bg-red-600 text-white"
                                              }, React.createElement("i", {
                                                    className: "far fa-file"
                                                  })), React.createElement("span", {
                                                className: "course-show-attachments__attachment-title rounded text-xs font-semibold inline-block whitespace-normal truncate w-32 md:w-38 pl-3 pr-2 py-2 leading-loose"
                                              }, CoursesCurriculum__ChecklistItem.filename(file))), React.createElement("button", {
                                            className: "flex w-8 justify-center items-center p-2 cursor-pointer bg-gray-50 border-l text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                            title: tr(undefined, undefined, "remove") + CoursesCurriculum__ChecklistItem.filename(file),
                                            onClick: (function (param) {
                                                return removeFile(updateResultCB, files, CoursesCurriculum__ChecklistItem.fileId(file));
                                              })
                                          }, React.createElement(PfIcon.make, {
                                                className: "if i-times-regular text-sm"
                                              }))));
                      }), files)), files.length < 3 ? React.createElement(CoursesCurriculum__FileForm.make, {
                    attachFileCB: (function (param, param$1) {
                        return attachFile(updateResultCB, attachingCB, files, param, param$1);
                      }),
                    attachingCB: attachingCB,
                    preview: preview,
                    index: index
                  }) : null);
}

function showAudioRecorder(attachingCB, updateResultCB, preview) {
  return React.createElement(CoursesCurriculum__AudioRecorder.make, {
              attachingCB: attachingCB,
              attachFileCB: (function (param, param$1) {
                  return attachRecordingFile(updateResultCB, attachingCB, param, param$1);
                }),
              preview: preview
            });
}

function CoursesCurriculum__SubmissionItem(Props) {
  var index = Props.index;
  var checklistItem = Props.checklistItem;
  var updateResultCB = Props.updateResultCB;
  var attachingCB = Props.attachingCB;
  var preview = Props.preview;
  var id = computeId(index, checklistItem);
  var files = CoursesCurriculum__ChecklistItem.result(checklistItem);
  var tmp;
  switch (files.TAG | 0) {
    case /* Files */0 :
        tmp = showFiles(files._0, preview, id, attachingCB, updateResultCB, index);
        break;
    case /* Link */1 :
        tmp = showLink(files._0, id, updateResultCB);
        break;
    case /* ShortText */2 :
        tmp = showShortText(files._0, id, updateResultCB);
        break;
    case /* LongText */3 :
        tmp = showLongText(files._0, id, updateResultCB);
        break;
    case /* MultiChoice */4 :
        tmp = showMultiChoice(files._0, files._1, id, updateResultCB);
        break;
    case /* AudioRecord */5 :
        tmp = showAudioRecorder(attachingCB, updateResultCB, preview);
        break;
    
  }
  return React.createElement("div", {
              "aria-label": id,
              className: "mt-4"
            }, placeholder(id, checklistItem), React.createElement("div", {
                  className: "md:pl-7 pt-2 pr-0 pb-4"
                }, tmp));
}

var ts = I18n.ts;

var make = CoursesCurriculum__SubmissionItem;

export {
  str ,
  tr ,
  ts ,
  kindIconClasses ,
  computeId ,
  notBlank ,
  placeholder ,
  showError ,
  showLink ,
  showShortText ,
  longTextWarning ,
  updateLongText ,
  showLongText ,
  checkboxOnChange ,
  showMultiChoice ,
  attachFile ,
  attachRecordingFile ,
  removeFile ,
  showFiles ,
  showAudioRecorder ,
  make ,
  
}
/* I18n Not a pure module */
