// Generated by ReScript, PLEASE EDIT WITH CARE

import LevelUpSvg from "../images/level-up.svg";
import AccessEndedSvg from "../images/access-ended.svg";
import CourseEndedSvg from "../images/course-ended.svg";
import PreviewModeSvg from "../images/preview-mode.svg";
import CourseCompleteSvg from "../images/course-complete.svg";
import LevelUpBlockedSvg from "../images/level-up-blocked.svg";

var courseEndedImage = CourseEndedSvg;

var courseCompleteImage = CourseCompleteSvg;

var accessEndedImage = AccessEndedSvg;

var levelUpImage = LevelUpSvg;

var previewModeImage = PreviewModeSvg;

var levelUpBlockedImage = LevelUpBlockedSvg;

function icon(t) {
  if (typeof t !== "number") {
    return levelUpBlockedImage;
  }
  switch (t) {
    case /* Preview */0 :
        return previewModeImage;
    case /* CourseEnded */1 :
        return courseEndedImage;
    case /* CourseComplete */2 :
        return courseCompleteImage;
    case /* AccessEnded */3 :
        return accessEndedImage;
    case /* LevelUp */4 :
        return levelUpImage;
    case /* TeamMembersPending */5 :
        return levelUpBlockedImage;
    case /* Nothing */6 :
        return "";
    
  }
}

export {
  courseEndedImage ,
  courseCompleteImage ,
  accessEndedImage ,
  levelUpImage ,
  previewModeImage ,
  levelUpBlockedImage ,
  icon ,
  
}
/* courseEndedImage Not a pure module */
