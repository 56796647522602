// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as CoachesIndex__Root from "../faculty/CoachesIndex__Root.bs.js";
import * as CoachesIndex__Coach from "../faculty/types/CoachesIndex__Coach.bs.js";
import * as CoachesIndex__Course from "../faculty/types/CoachesIndex__Course.bs.js";

function decodeProps(json) {
  return [
          Json_decode.optional((function (param) {
                  return Json_decode.field("subheading", Json_decode.string, param);
                }), json),
          Json_decode.field("coaches", (function (param) {
                  return Json_decode.array(CoachesIndex__Coach.decode, param);
                }), json),
          Json_decode.field("courses", (function (param) {
                  return Json_decode.array(CoachesIndex__Course.decode, param);
                }), json),
          Json_decode.field("studentInCourseIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        ];
}

Psj.match(undefined, "faculty#index", (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag(undefined, undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(CoachesIndex__Root.make, {
                    subheading: match[0],
                    coaches: match[1],
                    courses: match[2],
                    studentInCourseIds: match[3]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
