// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as User from "../../shared/types/User.bs.js";
import * as $$Array from "../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../shared/components/FaIcon.bs.js";
import * as PfIcon from "../../packages/pf-icon/src/PfIcon.bs.js";
import * as DateFns from "../../shared/utils/DateFns.bs.js";
import * as Tooltip from "../../shared/components/Tooltip.bs.js";
import * as Dropdown from "../../shared/components/Dropdown.bs.js";
import * as ArrayUtils from "../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as WindowUtils from "../../shared/utils/WindowUtils.bs.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";
import * as MarkdownBlock from "../../shared/components/MarkdownBlock.bs.js";
import * as TopicsShow__Post from "../types/TopicsShow__Post.bs.js";
import * as TopicsShow__Topic from "../types/TopicsShow__Topic.bs.js";
import * as TopicsShow__UserShow from "./TopicsShow__UserShow.bs.js";
import * as TopicsShow__PostReply from "./TopicsShow__PostReply.bs.js";
import * as TopicsShow__PostEditor from "./TopicsShow__PostEditor.bs.js";
import * as TopicsShow__LikeManager from "./TopicsShow__LikeManager.bs.js";

function str(prim) {
  return prim;
}

import "./TopicsShow__PostShow.css"
;

var partial_arg = "components.TopicsShow__PostShow";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function findUser(userId, users) {
  return Belt_Option.map(userId, (function (userId) {
                return User.findById(userId, users);
              }));
}

var Raw = {};

var query = "mutation MarkAsSolutionMutation($id: ID!)  {\nmarkPostAsSolution(id: $id)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.markPostAsSolution;
  return {
          markPostAsSolution: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.markPostAsSolution;
  var value$2 = value$1.success;
  var markPostAsSolution = {
    success: value$2
  };
  return {
          markPostAsSolution: markPostAsSolution
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var MarkPostAsSolutionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var MarkPostAsSolutionQuery_Graphql_error = include.Graphql_error;

var MarkPostAsSolutionQuery_decodeNotification = include.decodeNotification;

var MarkPostAsSolutionQuery_decodeObject = include.decodeObject;

var MarkPostAsSolutionQuery_decodeNotifications = include.decodeNotifications;

var MarkPostAsSolutionQuery_decodeErrors = include.decodeErrors;

var MarkPostAsSolutionQuery_flashNotifications = include.flashNotifications;

var MarkPostAsSolutionQuery_sendQuery = include.sendQuery;

var MarkPostAsSolutionQuery_query = include.query;

var MarkPostAsSolutionQuery_make = include.make;

var MarkPostAsSolutionQuery = {
  MarkPostAsSolutionQuery_inner: MarkPostAsSolutionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: MarkPostAsSolutionQuery_Graphql_error,
  decodeNotification: MarkPostAsSolutionQuery_decodeNotification,
  decodeObject: MarkPostAsSolutionQuery_decodeObject,
  decodeNotifications: MarkPostAsSolutionQuery_decodeNotifications,
  decodeErrors: MarkPostAsSolutionQuery_decodeErrors,
  flashNotifications: MarkPostAsSolutionQuery_flashNotifications,
  sendQuery: MarkPostAsSolutionQuery_sendQuery,
  query: MarkPostAsSolutionQuery_query,
  $$fetch: $$fetch,
  make: MarkPostAsSolutionQuery_make
};

var Raw$1 = {};

var query$1 = "mutation UnmarkAsSolutionMutation($id: ID!)  {\nunmarkPostAsSolution(id: $id)  {\nsuccess  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.unmarkPostAsSolution;
  return {
          unmarkPostAsSolution: {
            success: value$1.success
          }
        };
}

function serialize$1(value) {
  var value$1 = value.unmarkPostAsSolution;
  var value$2 = value$1.success;
  var unmarkPostAsSolution = {
    success: value$2
  };
  return {
          unmarkPostAsSolution: unmarkPostAsSolution
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$1(id, param) {
  return {
          id: id
        };
}

var UnmarkPostAsSolutionQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$1 = include$1.$$fetch;

var UnmarkPostAsSolutionQuery_Graphql_error = include$1.Graphql_error;

var UnmarkPostAsSolutionQuery_decodeNotification = include$1.decodeNotification;

var UnmarkPostAsSolutionQuery_decodeObject = include$1.decodeObject;

var UnmarkPostAsSolutionQuery_decodeNotifications = include$1.decodeNotifications;

var UnmarkPostAsSolutionQuery_decodeErrors = include$1.decodeErrors;

var UnmarkPostAsSolutionQuery_flashNotifications = include$1.flashNotifications;

var UnmarkPostAsSolutionQuery_sendQuery = include$1.sendQuery;

var UnmarkPostAsSolutionQuery_query = include$1.query;

var UnmarkPostAsSolutionQuery_make = include$1.make;

var UnmarkPostAsSolutionQuery = {
  UnmarkPostAsSolutionQuery_inner: UnmarkPostAsSolutionQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: UnmarkPostAsSolutionQuery_Graphql_error,
  decodeNotification: UnmarkPostAsSolutionQuery_decodeNotification,
  decodeObject: UnmarkPostAsSolutionQuery_decodeObject,
  decodeNotifications: UnmarkPostAsSolutionQuery_decodeNotifications,
  decodeErrors: UnmarkPostAsSolutionQuery_decodeErrors,
  flashNotifications: UnmarkPostAsSolutionQuery_flashNotifications,
  sendQuery: UnmarkPostAsSolutionQuery_sendQuery,
  query: UnmarkPostAsSolutionQuery_query,
  $$fetch: $$fetch$1,
  make: UnmarkPostAsSolutionQuery_make
};

var Raw$2 = {};

var query$2 = "mutation ArchivePostMutation($id: ID!)  {\narchivePost(id: $id)  {\nsuccess  \n}\n\n}\n";

function parse$2(value) {
  var value$1 = value.archivePost;
  return {
          archivePost: {
            success: value$1.success
          }
        };
}

function serialize$2(value) {
  var value$1 = value.archivePost;
  var value$2 = value$1.success;
  var archivePost = {
    success: value$2
  };
  return {
          archivePost: archivePost
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var ArchivePostQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = GraphqlQuery.Extender({
      Raw: Raw$2,
      query: query$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch$2 = include$2.$$fetch;

var ArchivePostQuery_Graphql_error = include$2.Graphql_error;

var ArchivePostQuery_decodeNotification = include$2.decodeNotification;

var ArchivePostQuery_decodeObject = include$2.decodeObject;

var ArchivePostQuery_decodeNotifications = include$2.decodeNotifications;

var ArchivePostQuery_decodeErrors = include$2.decodeErrors;

var ArchivePostQuery_flashNotifications = include$2.flashNotifications;

var ArchivePostQuery_sendQuery = include$2.sendQuery;

var ArchivePostQuery_query = include$2.query;

var ArchivePostQuery_make = include$2.make;

var ArchivePostQuery = {
  ArchivePostQuery_inner: ArchivePostQuery_inner,
  Raw: Raw$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  Graphql_error: ArchivePostQuery_Graphql_error,
  decodeNotification: ArchivePostQuery_decodeNotification,
  decodeObject: ArchivePostQuery_decodeObject,
  decodeNotifications: ArchivePostQuery_decodeNotifications,
  decodeErrors: ArchivePostQuery_decodeErrors,
  flashNotifications: ArchivePostQuery_flashNotifications,
  sendQuery: ArchivePostQuery_sendQuery,
  query: ArchivePostQuery_query,
  $$fetch: $$fetch$2,
  make: ArchivePostQuery_make
};

function markPostAsSolution(postId, markPostAsSolutionCB) {
  return WindowUtils.confirm(undefined, t(undefined, undefined, "mark_solution_confirm"), (function (param) {
                Curry._3($$fetch, undefined, undefined, {
                        id: postId
                      }).then(function (response) {
                      if (response.markPostAsSolution.success) {
                        Curry._1(markPostAsSolutionCB, undefined);
                      }
                      return Promise.resolve(undefined);
                    });
                
              }));
}

function unmarkPostAsSolution(postId, unmarkPostAsSolutionCB) {
  return WindowUtils.confirm(undefined, t(undefined, undefined, "unmark_solution_confirm"), (function (param) {
                Curry._3($$fetch$1, undefined, undefined, {
                        id: postId
                      }).then(function (response) {
                      if (response.unmarkPostAsSolution.success) {
                        Curry._1(unmarkPostAsSolutionCB, undefined);
                      }
                      return Promise.resolve(undefined);
                    });
                
              }));
}

function archivePost(isFirstPost, postId, archivePostCB) {
  if (window.confirm(isFirstPost ? t(undefined, undefined, "delete_topic_confirm_dialog") : t(undefined, undefined, "delete_post_confirm_dialog"))) {
    Curry._3($$fetch$2, undefined, undefined, {
            id: postId
          }).then(function (response) {
          if (response.archivePost.success) {
            Curry._1(archivePostCB, undefined);
          }
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function solutionIcon(userCanUnmarkSolution, unmarkPostAsSolutionCB, postId) {
  var tip = React.createElement("div", {
        className: "text-center"
      }, t(undefined, undefined, "unmark_solution"));
  var solutionIcon$1 = React.createElement("div", {
        className: "flex items-center justify-center pr-2 pl-0 py-2 md:p-3 bg-green-200 text-green-800 rounded-full " + (
          userCanUnmarkSolution ? "hover:bg-gray-50 hover:text-gray-600" : ""
        )
      }, React.createElement(PfIcon.make, {
            className: "if i-check-solid text-sm lg:text-base"
          }));
  var iconWithTip = userCanUnmarkSolution ? React.createElement(Tooltip.make, {
          tip: tip,
          position: "Top",
          children: solutionIcon$1
        }) : solutionIcon$1;
  return React.createElement("div", {
              "aria-label": t(undefined, undefined, "marked_solution_icon"),
              className: "flex lg:flex-col items-center px-2 lg:pl-0 lg:pr-4 bg-green-200 lg:bg-transparent rounded md:mt-4 " + (
                userCanUnmarkSolution ? "cursor-pointer" : ""
              ),
              onClick: (function (param) {
                  if (userCanUnmarkSolution) {
                    return unmarkPostAsSolution(postId, unmarkPostAsSolutionCB);
                  }
                  
                })
            }, iconWithTip, React.createElement("div", {
                  className: "text-xs lg:text-tiny font-semibold text-green-800 lg:pt-1 "
                }, t(undefined, undefined, "solution_icon_label")));
}

function optionsDropdown(post, isPostCreator, isTopicCreator, moderator, isFirstPost, replies, toggleShowPostEdit, archivePostCB, userEmail, userAccessToken) {
  var selected = React.createElement("div", {
        "aria-label": t(undefined, undefined, "options_post") + " " + TopicsShow__Post.id(post),
        className: "flex items-center justify-center w-8 h-8 rounded leading-tight border bg-gray-50 text-gray-800 cursor-pointer hover:bg-gray-50"
      }, React.createElement(PfIcon.make, {
            className: "if i-ellipsis-h-regular text-base"
          }));
  var editPostButton = React.createElement("button", {
        className: "flex w-full px-3 py-2 font-semibold items-center text-gray-600 whitespace-nowrap",
        onClick: (function (param) {
            return Curry._1(toggleShowPostEdit, (function (param) {
                          return true;
                        }));
          })
      }, React.createElement(FaIcon.make, {
            classes: "fas fa-edit fa-fw text-base"
          }), React.createElement("span", {
            className: "ml-2"
          }, isFirstPost ? t(undefined, undefined, "edit_post_string") : t(undefined, undefined, "edit_reply_string")));
  var showDelete = isFirstPost ? moderator || isPostCreator && ArrayUtils.isEmpty(replies) : moderator || isPostCreator;
  var deletePostButton = showDelete ? React.createElement("button", {
          className: "flex w-full px-3 py-2 font-semibold items-center text-gray-600 whitespace-nowrap",
          onClick: (function (param) {
              return archivePost(isFirstPost, TopicsShow__Post.id(post), archivePostCB);
            })
        }, React.createElement(FaIcon.make, {
              classes: "fas fa-trash-alt fa-fw text-base"
            }), React.createElement("span", {
              className: "ml-2"
            }, isFirstPost ? t(undefined, undefined, "delete_topic_string") : t(undefined, undefined, "delete_reply_string"))) : null;
  var _id = TopicsShow__Post.editorId(post);
  var historyButton = _id !== undefined ? React.createElement("a", {
          className: "flex w-full px-3 py-2 font-semibold items-center text-gray-600 whitespace-nowrap",
          href: "/posts/" + (TopicsShow__Post.id(post) + "/versions") + "?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken)
        }, React.createElement(FaIcon.make, {
              classes: "fas fa-history fa-fw text-base"
            }), React.createElement("span", {
              className: "ml-2"
            }, t(undefined, undefined, "history_button_text"))) : null;
  var contents = moderator ? [
      editPostButton,
      historyButton,
      deletePostButton
    ] : (
      isPostCreator ? [
          editPostButton,
          deletePostButton
        ] : []
    );
  return React.createElement(Dropdown.make, {
              selected: selected,
              contents: contents,
              right: true
            });
}

function onBorderAnimationEnd($$event) {
  var element = $$event.target;
  element.className = "";
  
}

function navigateToEditor(param) {
  var element = document.getElementById("add-reply-to-topic");
  setTimeout((function (param) {
          if (element == null) {
            Rollbar.error("Could not find the post to scroll to.");
          } else {
            element.scrollIntoView();
            element.className = "w-full flex flex-col topics-show__highlighted-item";
          }
          
        }), 50);
  
}

function TopicsShow__PostShow(Props) {
  var post = Props.post;
  var topic = Props.topic;
  var users = Props.users;
  var posts = Props.posts;
  var currentUserId = Props.currentUserId;
  var moderator = Props.moderator;
  var isTopicCreator = Props.isTopicCreator;
  var updatePostCB = Props.updatePostCB;
  var addNewReplyCB = Props.addNewReplyCB;
  var addPostLikeCB = Props.addPostLikeCB;
  var removePostLikeCB = Props.removePostLikeCB;
  var markPostAsSolutionCB = Props.markPostAsSolutionCB;
  var unmarkPostAsSolutionCB = Props.unmarkPostAsSolutionCB;
  var archivePostCB = Props.archivePostCB;
  var topicSolutionId = Props.topicSolutionId;
  var userEmail = Props.userEmail;
  var userAccessToken = Props.userAccessToken;
  var creator = findUser(TopicsShow__Post.creatorId(post), users);
  var editor = findUser(TopicsShow__Post.editorId(post), users);
  var isPostCreator = Belt_Option.mapWithDefault(TopicsShow__Post.creatorId(post), false, (function (creatorId) {
          return currentUserId === creatorId;
        }));
  var isFirstPost = TopicsShow__Post.postNumber(post) === 1;
  var repliesToPost = isFirstPost ? [] : TopicsShow__Post.repliesToPost(posts, post);
  var match = React.useState(function () {
        return false;
      });
  var toggleShowPostEdit = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var toggleShowReplies = match$1[1];
  var showReplies = match$1[0];
  var userCanUnmarkSolution = moderator || isTopicCreator;
  var tip = React.createElement("div", {
        className: "text-center"
      }, t(undefined, undefined, "mark_solution"));
  var tmp;
  if (match[0]) {
    tmp = React.createElement("div", {
          className: "flex-1"
        }, React.createElement(TopicsShow__PostEditor.make, {
              editing: true,
              id: "edit-post-" + TopicsShow__Post.id(post),
              topic: topic,
              currentUserId: currentUserId,
              handlePostCB: updatePostCB,
              replies: posts,
              users: users,
              post: post,
              handleCloseCB: (function (param) {
                  return Curry._1(toggleShowPostEdit, (function (param) {
                                return false;
                              }));
                })
            }));
  } else {
    var editedAt = TopicsShow__Post.editedAt(post);
    tmp = React.createElement("div", {
          className: "flex items-start justify-between min-w-0"
        }, React.createElement("div", {
              className: "text-sm min-w-0 w-full"
            }, React.createElement(MarkdownBlock.make, {
                  markdown: TopicsShow__Post.body(post),
                  className: "leading-normal text-sm ",
                  profile: /* Permissive */0
                }), editedAt !== undefined ? React.createElement("div", undefined, React.createElement("div", {
                        className: "mt-1 inline-block px-2 py-1 rounded bg-gray-50 text-xs text-gray-800 "
                      }, React.createElement("span", undefined, t(undefined, undefined, "last_edited_by_label")), React.createElement("span", {
                            className: "font-semibold"
                          }, editor !== undefined ? User.name(editor) : t(undefined, undefined, "deleted_user_name")), React.createElement("span", undefined, " on " + DateFns.format(Caml_option.valFromOption(editedAt), "do MMMM, yyyy HH:mm")))) : null), React.createElement("div", {
              className: "hidden lg:block shrink-0 ml-3"
            }, isPostCreator || moderator || isTopicCreator ? optionsDropdown(post, isPostCreator, isTopicCreator, moderator, isFirstPost, posts, toggleShowPostEdit, archivePostCB, userEmail, userAccessToken) : null));
  }
  return React.createElement("div", {
              id: "post-show-" + TopicsShow__Post.id(post),
              onAnimationEnd: onBorderAnimationEnd
            }, React.createElement("div", {
                  key: TopicsShow__Post.id(post),
                  className: "flex pt-4"
                }, React.createElement("div", {
                      className: "hidden lg:flex flex-col"
                    }, React.createElement(TopicsShow__LikeManager.make, {
                          post: post,
                          addPostLikeCB: addPostLikeCB,
                          removePostLikeCB: removePostLikeCB
                        }), TopicsShow__Post.solution(post) ? solutionIcon(userCanUnmarkSolution, unmarkPostAsSolutionCB, TopicsShow__Post.id(post)) : null, ReactUtils.nullUnless(React.createElement("div", {
                              className: "hidden md:flex md:flex-col items-center text-center md:w-14 pr-3 md:pr-4 md:mt-4"
                            }, React.createElement(Tooltip.make, {
                                  tip: tip,
                                  position: "Top",
                                  children: React.createElement("button", {
                                        "aria-label": t(undefined, undefined, "mark_solution"),
                                        className: "mark-as-solution__button bg-gray-50 flex items-center text-center rounded-full p-2 md:p-3 hover:bg-gray-50 text-gray-600",
                                        onClick: (function (param) {
                                            return markPostAsSolution(TopicsShow__Post.id(post), markPostAsSolutionCB);
                                          })
                                      }, React.createElement(PfIcon.make, {
                                            className: "if i-check-solid text-sm lg:text-base"
                                          }))
                                })), (moderator || isTopicCreator) && !(isFirstPost || TopicsShow__Post.solution(post)) && Belt_Option.isNone(topicSolutionId))), React.createElement("div", {
                      className: "flex-1 pb-6 lg:pb-8 topics-post-show__post-body min-w-0"
                    }, React.createElement("div", {
                          className: "pt-2",
                          id: "body"
                        }, React.createElement("div", {
                              className: "flex justify-between lg:hidden"
                            }, React.createElement(TopicsShow__UserShow.make, {
                                  user: creator,
                                  createdAt: TopicsShow__Post.createdAt(post)
                                }), React.createElement("div", {
                                  className: "shrink-0 mt-1"
                                }, isPostCreator || moderator || isTopicCreator ? optionsDropdown(post, isPostCreator, isTopicCreator, moderator, isFirstPost, posts, toggleShowPostEdit, archivePostCB, userEmail, userAccessToken) : null)), tmp), React.createElement("div", {
                          className: "flex justify-between lg:items-end pt-4"
                        }, React.createElement("div", {
                              className: "flex-1 lg:flex-initial mr-3"
                            }, React.createElement("div", {
                                  className: "hidden lg:block"
                                }, React.createElement(TopicsShow__UserShow.make, {
                                      user: creator,
                                      createdAt: TopicsShow__Post.createdAt(post)
                                    })), React.createElement("div", {
                                  className: "flex items-center lg:items-start justify-between lg:hidden"
                                }, React.createElement("div", {
                                      className: "flex"
                                    }, React.createElement("div", {
                                          className: "flex"
                                        }, React.createElement(TopicsShow__LikeManager.make, {
                                              post: post,
                                              addPostLikeCB: addPostLikeCB,
                                              removePostLikeCB: removePostLikeCB
                                            }), React.createElement("div", {
                                              className: "pr-3"
                                            }, ArrayUtils.isNotEmpty(repliesToPost) ? React.createElement("button", {
                                                    className: "cursor-pointer flex items-center justify-center",
                                                    onClick: (function (param) {
                                                        return Curry._1(toggleShowReplies, (function (showReplies) {
                                                                      return !showReplies;
                                                                    }));
                                                      })
                                                  }, React.createElement("span", {
                                                        className: "flex items-center justify-center rounded-lg lg:bg-gray-50 hover:bg-gray-300 text-gray-600 hover:text-gray-900 h-8 w-8 md:h-10 md:w-10 p-1 md:p-2 mx-auto"
                                                      }, React.createElement(FaIcon.make, {
                                                            classes: "far fa-comment-alt"
                                                          })), React.createElement("span", {
                                                        className: "text-tiny lg:text-xs font-semibold"
                                                      }, String(TopicsShow__Post.replies(post).length))) : null))), React.createElement("div", {
                                      className: "flex space-x-3"
                                    }, ReactUtils.nullUnless(React.createElement("button", {
                                              className: "bg-gray-50 flex md:hidden items-center text-center rounded-lg p-2 hover:bg-gray-50 text-gray-600",
                                              onClick: (function (param) {
                                                  return markPostAsSolution(TopicsShow__Post.id(post), markPostAsSolutionCB);
                                                })
                                            }, React.createElement(PfIcon.make, {
                                                  className: "if i-check-solid text-sm lg:text-base"
                                                }), React.createElement("span", {
                                                  className: "ml-2 leading-tight text-xs md:text-tiny font-semibold block text-gray-900"
                                                }, t(undefined, undefined, "solution"))), (moderator || isTopicCreator) && !(isFirstPost || TopicsShow__Post.solution(post)) && Belt_Option.isNone(topicSolutionId)), TopicsShow__Post.solution(post) ? solutionIcon(userCanUnmarkSolution, unmarkPostAsSolutionCB, TopicsShow__Post.id(post)) : null))), React.createElement("div", {
                              className: "flex items-center text-sm font-semibold lg:mb-1"
                            }, topicSolutionId !== undefined && isFirstPost ? React.createElement("a", {
                                    className: "flex items-center px-3 py-2 bg-green-200 text-green-900 border border-transparent rounded mr-3 text-left focus:border-primary-400 hover:border-green-500 hover:bg-green-300",
                                    href: "#post-show-" + topicSolutionId
                                  }, React.createElement(PfIcon.make, {
                                        className: "if i-arrow-down-circle-regular text-sm lg:text-base"
                                      }), React.createElement("div", {
                                        className: "text-xs font-semibold pl-2"
                                      }, t(undefined, undefined, "go_to_solution_button"))) : null, React.createElement("div", {
                                  className: "hidden lg:block"
                                }, ArrayUtils.isNotEmpty(repliesToPost) ? React.createElement("button", {
                                        "aria-label": t(undefined, undefined, "show_replies") + " " + TopicsShow__Post.id(post),
                                        className: "border bg-white mr-3 p-2 rounded text-xs font-semibold focus:border-primary-400 hover:bg-gray-50",
                                        id: "show-replies-" + TopicsShow__Post.id(post),
                                        onClick: (function (param) {
                                            return Curry._1(toggleShowReplies, (function (showReplies) {
                                                          return !showReplies;
                                                        }));
                                          })
                                      }, t(undefined, TopicsShow__Post.replies(post).length, "show_replies_button"), React.createElement(FaIcon.make, {
                                            classes: "ml-2 fas fa-chevron-" + (
                                              showReplies ? "up" : "down"
                                            )
                                          })) : null), ReactUtils.nullIf(React.createElement("button", {
                                      "aria-label": isFirstPost ? t(undefined, undefined, "add_reply_topic") : t(undefined, undefined, "add_reply_post") + TopicsShow__Post.id(post),
                                      className: "bg-gray-50 lg:border lg:bg-gray-50 p-2 rounded text-xs font-semibold focus:border-primary-400 hover:bg-gray-300",
                                      id: "reply-button-" + TopicsShow__Post.id(post),
                                      onClick: (function (param) {
                                          Curry._1(addNewReplyCB, undefined);
                                          return navigateToEditor(undefined);
                                        })
                                    }, React.createElement(FaIcon.make, {
                                          classes: "fas fa-reply mr-2"
                                        }), t(undefined, undefined, "new_reply_button")), Belt_Option.isSome(TopicsShow__Topic.lockedAt(topic))))), showReplies ? React.createElement("div", {
                            "aria-label": t(undefined, undefined, "replies_post") + " " + TopicsShow__Post.id(post),
                            className: "lg:pl-10 pt-2 topics-post-show__replies-container"
                          }, $$Array.map((function (post) {
                                  return React.createElement(TopicsShow__PostReply.make, {
                                              post: post,
                                              users: users,
                                              key: TopicsShow__Post.id(post)
                                            });
                                }), repliesToPost)) : null)));
}

var make = TopicsShow__PostShow;

export {
  str ,
  t ,
  findUser ,
  MarkPostAsSolutionQuery ,
  UnmarkPostAsSolutionQuery ,
  ArchivePostQuery ,
  markPostAsSolution ,
  unmarkPostAsSolution ,
  archivePost ,
  solutionIcon ,
  optionsDropdown ,
  onBorderAnimationEnd ,
  navigateToEditor ,
  make ,
  
}
/*  Not a pure module */
