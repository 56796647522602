// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as $$Notification from "../../../shared/Notification.bs.js";
import * as AuthenticityToken from "../../../shared/utils/AuthenticityToken.bs.js";
import * as CoursesCurriculum__AudioNavigator from "./CoursesCurriculum__AudioNavigator";

function str(prim) {
  return prim;
}

function audioRecorder(prim0, prim1) {
  return CoursesCurriculum__AudioNavigator.audioRecorder(prim0, prim1);
}

var partial_arg = "components.CoursesCurriculum__AudioRecorder";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function CoursesCurriculum__AudioRecorder(Props) {
  var attachingCB = Props.attachingCB;
  var attachFileCB = Props.attachFileCB;
  var preview = Props.preview;
  var audioRecorder = CoursesCurriculum__AudioNavigator.audioRecorder(AuthenticityToken.fromHead(undefined), attachingCB);
  React.useEffect((function () {
          var id = audioRecorder.id;
          if (id !== undefined) {
            Curry._2(attachFileCB, id, "recorderaudio");
          }
          
        }), [audioRecorder.id]);
  var tmp;
  if (audioRecorder.recording) {
    tmp = React.createElement("div", {
          className: "flex flex-col md:flex-row pointer-cursor pt-2 md:items-center"
        }, React.createElement("button", {
              className: "flex items-center bg-gray-50 border rounded-full hover:bg-gray-300",
              onClick: (function (_e) {
                  return Curry._1(audioRecorder.stopRecording, undefined);
                })
            }, React.createElement("div", {
                  className: "flex shrink-0 items-center justify-center bg-red-600 shadow-md rounded-full h-10 w-10"
                }, React.createElement(Icon.make, {
                      className: "if i-stop-solid text-base text-white relative z-10"
                    }), React.createElement("span", {
                      className: "w-8 h-8 z-0 animate-ping absolute inline-flex rounded-full bg-red-600 opacity-75"
                    })), React.createElement("span", {
                  className: "inline-block pl-3 pr-4 text-xs font-semibold"
                }, t(undefined, undefined, "recording_string"))));
  } else {
    var _url = audioRecorder.url;
    var url = audioRecorder.url;
    var match = audioRecorder.url;
    tmp = React.createElement("div", {
          className: "flex flex-col md:flex-row pointer-cursor pt-2 md:items-center"
        }, React.createElement("button", {
              className: "flex items-center bg-red-100 border rounded-full hover:bg-red-200",
              onClick: (function (_e) {
                  if (preview) {
                    return $$Notification.notice(t(undefined, undefined, "preview_mode"), t(undefined, undefined, "cannot_record"));
                  } else {
                    return Curry._1(audioRecorder.startRecording, undefined);
                  }
                })
            }, React.createElement("div", {
                  className: "flex shrink-0 items-center justify-center bg-white shadow-md rounded-full h-10 w-10"
                }, React.createElement(Icon.make, {
                      className: "if i-microphone-fill-light text-lg text-red-600"
                    })), React.createElement("span", {
                  className: "inline-block pl-3 pr-4 text-xs font-semibold"
                }, _url !== undefined ? t(undefined, undefined, "button_text_record_again") : t(undefined, undefined, "button_text_start_recording"))), url !== undefined ? React.createElement("audio", {
                className: "pt-3 md:pt-0 md:pl-4",
                controls: true,
                src: url
              }) : null, match !== undefined ? React.createElement("div", {
                className: "btn btn-success ml-4",
                onClick: (function (_e) {
                    return Curry._1(audioRecorder.downloadBlob, undefined);
                  })
              }, React.createElement(FaIcon.make, {
                    classes: "fas fa-download"
                  })) : null);
  }
  var size = audioRecorder.blobSize;
  return React.createElement(React.Fragment, undefined, tmp, size !== undefined && size > 5000000 ? React.createElement("div", {
                    className: "text-xs text-red-500 mt-2"
                  }, React.createElement(FaIcon.make, {
                        classes: "fas fa-exclamation-triangle mr-2"
                      }), t(undefined, undefined, "recording_size_limit_warning")) : null);
}

var make = CoursesCurriculum__AudioRecorder;

export {
  str ,
  audioRecorder ,
  t ,
  make ,
  
}
/* I18n Not a pure module */
