// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Tooltip from "../../../shared/components/Tooltip.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as DisablingCover from "../../../shared/components/DisablingCover.bs.js";
import * as SubmissionChecklistItem from "../../review/types/SubmissionChecklistItem.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__ChecklistItem from "../types/CoursesCurriculum__ChecklistItem.bs.js";
import * as CoursesCurriculum__SubmissionItem from "./CoursesCurriculum__SubmissionItem.bs.js";
import * as CoursesCurriculum__LevelUpEligibility from "../types/CoursesCurriculum__LevelUpEligibility.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__SubmissionBuilder";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function buttonContents(formState, checklist) {
  var icon = formState >= 2 ? React.createElement(FaIcon.make, {
          classes: "fas fa-cloud-upload-alt mr-2"
        }) : React.createElement(FaIcon.make, {
          classes: "fas fa-spinner fa-pulse mr-2"
        });
  var text;
  switch (formState) {
    case /* Attaching */0 :
        text = tr(undefined, undefined, "attaching") + "...";
        break;
    case /* Saving */1 :
        text = tr(undefined, undefined, "submitting") + "...";
        break;
    case /* Ready */2 :
        text = ArrayUtils.isEmpty(checklist) ? tr(undefined, undefined, "complete") : tr(undefined, undefined, "submit");
        break;
    
  }
  return React.createElement("span", undefined, icon, text);
}

function initialState(checklist) {
  return {
          formState: /* Ready */2,
          checklist: CoursesCurriculum__ChecklistItem.fromTargetChecklistItem(checklist)
        };
}

function reducer(state, action) {
  if (typeof action !== "number") {
    return {
            formState: /* Ready */2,
            checklist: action._0
          };
  }
  switch (action) {
    case /* SetAttaching */0 :
        return {
                formState: /* Attaching */0,
                checklist: state.checklist
              };
    case /* SetSaving */1 :
        return {
                formState: /* Saving */1,
                checklist: state.checklist
              };
    case /* SetReady */2 :
        return {
                formState: /* Ready */2,
                checklist: state.checklist
              };
    
  }
}

function isBusy(formState) {
  return formState < 2;
}

var Raw = {};

var query = "mutation CreateSubmissionMutation($targetId: ID!, $checklist: JSON!, $fileIds: [ID!]!)  {\ncreateSubmission(targetId: $targetId, checklist: $checklist, fileIds: $fileIds)  {\nsubmission  {\nid  \ncreatedAt  \n}\n\nlevelUpEligibility  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.createSubmission;
  var value$2 = value$1.submission;
  var value$3 = value$1.levelUpEligibility;
  var tmp;
  if (value$3 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$3) {
      case "AtMaxLevel" :
          tmp$1 = "AtMaxLevel";
          break;
      case "CurrentLevelIncomplete" :
          tmp$1 = "CurrentLevelIncomplete";
          break;
      case "DateLocked" :
          tmp$1 = "DateLocked";
          break;
      case "Eligible" :
          tmp$1 = "Eligible";
          break;
      case "NoMilestonesInLevel" :
          tmp$1 = "NoMilestonesInLevel";
          break;
      case "PreviousLevelIncomplete" :
          tmp$1 = "PreviousLevelIncomplete";
          break;
      case "TeamMembersPending" :
          tmp$1 = "TeamMembersPending";
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$3
        };
    }
    tmp = tmp$1;
  }
  return {
          createSubmission: {
            submission: !(value$2 == null) ? ({
                  id: value$2.id,
                  createdAt: value$2.createdAt
                }) : undefined,
            levelUpEligibility: tmp
          }
        };
}

function serialize(value) {
  var value$1 = value.createSubmission;
  var value$2 = value$1.levelUpEligibility;
  var levelUpEligibility = value$2 !== undefined ? (
      typeof value$2 === "object" ? value$2.VAL : (
          value$2 === "DateLocked" ? "DateLocked" : (
              value$2 === "TeamMembersPending" ? "TeamMembersPending" : (
                  value$2 === "Eligible" ? "Eligible" : (
                      value$2 === "PreviousLevelIncomplete" ? "PreviousLevelIncomplete" : (
                          value$2 === "NoMilestonesInLevel" ? "NoMilestonesInLevel" : (
                              value$2 === "CurrentLevelIncomplete" ? "CurrentLevelIncomplete" : "AtMaxLevel"
                            )
                        )
                    )
                )
            )
        )
    ) : null;
  var value$3 = value$1.submission;
  var submission;
  if (value$3 !== undefined) {
    var value$4 = value$3.createdAt;
    var value$5 = value$3.id;
    submission = {
      id: value$5,
      createdAt: value$4
    };
  } else {
    submission = null;
  }
  var createSubmission = {
    submission: submission,
    levelUpEligibility: levelUpEligibility
  };
  return {
          createSubmission: createSubmission
        };
}

function serializeVariables(inp) {
  var a = inp.fileIds;
  return {
          targetId: inp.targetId,
          checklist: inp.checklist,
          fileIds: a.map(function (b) {
                return b;
              })
        };
}

function makeVariables(targetId, checklist, fileIds, param) {
  return {
          targetId: targetId,
          checklist: checklist,
          fileIds: fileIds
        };
}

var CreateSubmissionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CreateSubmissionQuery_Graphql_error = include.Graphql_error;

var CreateSubmissionQuery_decodeNotification = include.decodeNotification;

var CreateSubmissionQuery_decodeObject = include.decodeObject;

var CreateSubmissionQuery_decodeNotifications = include.decodeNotifications;

var CreateSubmissionQuery_decodeErrors = include.decodeErrors;

var CreateSubmissionQuery_flashNotifications = include.flashNotifications;

var CreateSubmissionQuery_sendQuery = include.sendQuery;

var CreateSubmissionQuery_query = include.query;

var CreateSubmissionQuery_fetch = include.$$fetch;

var CreateSubmissionQuery = {
  CreateSubmissionQuery_inner: CreateSubmissionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CreateSubmissionQuery_Graphql_error,
  decodeNotification: CreateSubmissionQuery_decodeNotification,
  decodeObject: CreateSubmissionQuery_decodeObject,
  decodeNotifications: CreateSubmissionQuery_decodeNotifications,
  decodeErrors: CreateSubmissionQuery_decodeErrors,
  flashNotifications: CreateSubmissionQuery_flashNotifications,
  sendQuery: CreateSubmissionQuery_sendQuery,
  query: CreateSubmissionQuery_query,
  $$fetch: CreateSubmissionQuery_fetch,
  make: make
};

function isButtonDisabled(state) {
  var match = state.formState;
  if (match < 2) {
    return true;
  } else {
    return !CoursesCurriculum__ChecklistItem.validChecklist(state.checklist);
  }
}

function submit(state, send, target, addSubmissionCB, $$event) {
  $$event.preventDefault();
  Curry._1(send, /* SetSaving */1);
  var fileIds = CoursesCurriculum__ChecklistItem.fileIds(state.checklist);
  var checklist = CoursesCurriculum__ChecklistItem.encodeArray(state.checklist);
  var targetId = CoursesCurriculum__Target.id(target);
  Curry._3(make, undefined, undefined, {
            targetId: targetId,
            checklist: checklist,
            fileIds: fileIds
          }).then(function (response) {
          var submission = response.createSubmission.submission;
          if (submission !== undefined) {
            var submission$1 = Caml_option.valFromOption(submission);
            var files = CoursesCurriculum__ChecklistItem.makeFiles(state.checklist);
            var submissionChecklist = Json_decode.array((function (param) {
                    return SubmissionChecklistItem.decode(files, param);
                  }), checklist);
            var newSubmission = CoursesCurriculum__Submission.make(submission$1.id, DateFns.decodeISO(submission$1.createdAt), /* Pending */1, submissionChecklist);
            var levelUpEligibility = CoursesCurriculum__LevelUpEligibility.makeOptionFromJs(response.createSubmission.levelUpEligibility);
            Curry._2(addSubmissionCB, newSubmission, levelUpEligibility);
          } else {
            Curry._1(send, /* SetReady */2);
          }
          return Promise.resolve(undefined);
        }).catch(function (_error) {
        Curry._1(send, /* SetReady */2);
        return Promise.resolve(undefined);
      });
  
}

function updateResult(state, send, index, result) {
  return Curry._1(send, /* UpdateResponse */{
              _0: CoursesCurriculum__ChecklistItem.updateResultAtIndex(index, result, state.checklist)
            });
}

function buttonClasses(checklist) {
  return "flex mt-3 " + (
          ArrayUtils.isEmpty(checklist) ? "justify-center" : "justify-end"
        );
}

function setAttaching(send, bool) {
  return Curry._1(send, bool ? /* SetAttaching */0 : /* SetReady */2);
}

function statusText(formState) {
  switch (formState) {
    case /* Attaching */0 :
        return tr(undefined, undefined, "attaching") + "...";
    case /* Saving */1 :
        return tr(undefined, undefined, "submitting") + "...";
    case /* Ready */2 :
        return tr(undefined, undefined, "submit");
    
  }
}

function tooltipText(preview) {
  if (preview) {
    return React.createElement("span", undefined, tr(undefined, undefined, "accessing_preview"), React.createElement("br", undefined), tr(undefined, undefined, "for_course"));
  } else {
    return React.createElement("span", undefined, tr(undefined, undefined, "compete_all"), React.createElement("br", undefined), tr(undefined, undefined, "steps_submit"));
  }
}

function CoursesCurriculum__SubmissionBuilder(Props) {
  var target = Props.target;
  var addSubmissionCB = Props.addSubmissionCB;
  var preview = Props.preview;
  var checklist = Props.checklist;
  var match = React.useReducer(reducer, {
        formState: /* Ready */2,
        checklist: CoursesCurriculum__ChecklistItem.fromTargetChecklistItem(checklist)
      });
  var send = match[1];
  var state = match[0];
  return React.createElement("div", {
              className: "bg-gray-50 p-4 my-4 border rounded-lg",
              id: "submission-builder"
            }, React.createElement(DisablingCover.make, {
                  disabled: state.formState < 2,
                  message: statusText(state.formState),
                  children: null
                }, ArrayUtils.isEmpty(state.checklist) ? React.createElement("div", {
                        className: "text-center"
                      }, tr(undefined, undefined, "no_actions")) : $$Array.mapi((function (index, checklistItem) {
                          return React.createElement(CoursesCurriculum__SubmissionItem.make, {
                                      index: index,
                                      checklistItem: checklistItem,
                                      updateResultCB: (function (param) {
                                          return updateResult(state, send, index, param);
                                        }),
                                      attachingCB: (function (param) {
                                          return setAttaching(send, param);
                                        }),
                                      preview: preview,
                                      key: String(index)
                                    });
                        }), state.checklist), React.createElement("div", {
                      className: buttonClasses(state.checklist)
                    }, React.createElement(Tooltip.make, {
                          tip: tooltipText(preview),
                          position: "Left",
                          disabled: !isButtonDisabled(state),
                          children: React.createElement("button", {
                                className: "btn btn-primary flex justify-center grow md:grow-0",
                                disabled: isButtonDisabled(state) || preview,
                                onClick: (function (param) {
                                    return submit(state, send, target, addSubmissionCB, param);
                                  })
                              }, buttonContents(state.formState, checklist))
                        }))));
}

var make$1 = CoursesCurriculum__SubmissionBuilder;

export {
  str ,
  tr ,
  buttonContents ,
  initialState ,
  reducer ,
  isBusy ,
  CreateSubmissionQuery ,
  isButtonDisabled ,
  submit ,
  updateResult ,
  buttonClasses ,
  setAttaching ,
  statusText ,
  tooltipText ,
  make$1 as make,
  
}
/* include Not a pure module */
