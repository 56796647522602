// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as CoursesReport__Level from "./CoursesReport__Level.bs.js";

function make(submissions, filter, sortDirection) {
  return {
          submissions: submissions,
          filter: filter,
          sortDirection: sortDirection
        };
}

function makeFilter(level, status) {
  return {
          level: level,
          status: status
        };
}

function partiallyLoaded(submissions, filter, sortDirection, cursor) {
  return {
          TAG: /* PartiallyLoaded */0,
          _0: {
            submissions: submissions,
            filter: filter,
            sortDirection: sortDirection
          },
          _1: cursor
        };
}

function fullyLoaded(submissions, filter, sortDirection) {
  return {
          TAG: /* FullyLoaded */1,
          _0: {
            submissions: submissions,
            filter: filter,
            sortDirection: sortDirection
          }
        };
}

function filterLevelId(level) {
  return Belt_Option.mapWithDefault(level, "none", CoursesReport__Level.id);
}

function filterEq(level, status, filter) {
  if (Belt_Option.mapWithDefault(filter.level, "none", CoursesReport__Level.id) === Belt_Option.mapWithDefault(level, "none", CoursesReport__Level.id)) {
    return Caml_obj.caml_equal(filter.status, status);
  } else {
    return false;
  }
}

function needsReloading(selectedLevel, selectedStatus, sortDirection, t) {
  if (typeof t === "number") {
    return true;
  }
  var data = t._0;
  return !(filterEq(selectedLevel, selectedStatus, data.filter) && data.sortDirection === sortDirection);
}

export {
  make ,
  makeFilter ,
  partiallyLoaded ,
  fullyLoaded ,
  filterLevelId ,
  filterEq ,
  needsReloading ,
  
}
/* CoursesReport__Level Not a pure module */
