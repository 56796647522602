// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as React from "react";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as UserDetails from "../../../shared/types/UserDetails.bs.js";
import * as Shared__Level from "../../../shared/types/Shared__Level.bs.js";
import * as CoursesStudents__StudentInfo from "../types/CoursesStudents__StudentInfo.bs.js";
import * as CoursesStudents__PersonalCoaches from "./CoursesStudents__PersonalCoaches.bs.js";

import "./CoursesStudents__Root.css"
;

var partial_arg = "components.CoursesStudents__StudentsList";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function levelInfo(level) {
  return React.createElement("span", {
              className: "inline-flex flex-col items-center rounded bg-orange-100 border border-orange-300 px-2 pt-2 pb-1"
            }, React.createElement("p", {
                  className: "text-xs font-semibold"
                }, ts(undefined, undefined, "level")), React.createElement("p", {
                  className: "font-bold"
                }, String(Shared__Level.number(level))));
}

function userTags(student) {
  var tags = UserDetails.taggings(CoursesStudents__StudentInfo.user(student));
  if (ArrayUtils.isNotEmpty(tags)) {
    return React.createElement("div", {
                className: "hidden md:flex flex-wrap"
              }, tags.map(function (tag) {
                    return React.createElement("div", {
                                key: tag,
                                className: "bg-blue-100 mt-1 mr-1 py-px px-2 text-tiny rounded-lg font-semibold text-gray-900\n            "
                              }, tag);
                  }));
  } else {
    return null;
  }
}

function studentTags(student) {
  if (ArrayUtils.isNotEmpty(CoursesStudents__StudentInfo.taggings(student))) {
    return React.createElement("div", {
                className: "hidden md:flex flex-wrap"
              }, CoursesStudents__StudentInfo.taggings(student).map(function (tag) {
                    return React.createElement("div", {
                                key: tag,
                                className: "bg-gray-100 rounded-lg font-semibold mt-1 mr-1 py-px px-2 text-tiny text-gray-900"
                              }, tag);
                  }));
  } else {
    return null;
  }
}

function showStudent(student) {
  var date = UserDetails.lastSeenAt(CoursesStudents__StudentInfo.user(student));
  return React.createElement(Link.make, {
              href: "/students/" + (CoursesStudents__StudentInfo.id(student) + "/report"),
              ariaLabel: "Student " + UserDetails.name(CoursesStudents__StudentInfo.user(student)),
              className: "flex md:flex-row justify-between bg-white mt-4 rounded-lg shadow cursor-pointer hover:border-primary-500 hover:text-primary-400 hover:shadow-md focus-within:outline-none focus-within:ring-2 focus-within:ring-inset focus-within:ring-focusColor-500",
              children: null,
              includeSearch: true,
              props: {
                "data-student-id": CoursesStudents__StudentInfo.id(student)
              },
              key: CoursesStudents__StudentInfo.id(student)
            }, React.createElement("div", {
                  className: "flex flex-col justify-center md:flex-row "
                }, React.createElement("div", {
                      className: "flex w-full items-start md:items-center p-3 md:px-4 md:py-5"
                    }, CoursesStudents__PersonalCoaches.avatar(undefined, UserDetails.avatarUrl(CoursesStudents__StudentInfo.user(student)), UserDetails.name(CoursesStudents__StudentInfo.user(student))), React.createElement("div", {
                          className: "ml-2 md:ml-3 block text-sm md:pr-2"
                        }, React.createElement("p", {
                              className: "font-semibold inline-block leading-snug"
                            }, UserDetails.name(CoursesStudents__StudentInfo.user(student))), React.createElement("div", {
                              className: "py-px text-gray-600 text-xs leading-snug flex flex-col sm:flex-row sm:items-center"
                            }, React.createElement("span", {
                                  className: "font-semibold pr-2"
                                }, UserDetails.fullTitle(CoursesStudents__StudentInfo.user(student))), React.createElement("span", {
                                  className: "sm:pl-2 sm:border-l border-gray-400 italic"
                                }, date !== undefined ? t([[
                                          "time_string",
                                          DateFns.formatDistanceToNowStrict(Caml_option.valFromOption(date), true, undefined, undefined, undefined)
                                        ]], undefined, "last_seen") : t(undefined, undefined, "no_last_seen"))), React.createElement("div", {
                              className: "text-gray-600 font-semibold text-xs leading-snug flex items-start"
                            }, userTags(student), studentTags(student))))), React.createElement("div", {
                  "aria-label": "student level info:" + CoursesStudents__StudentInfo.id(student),
                  className: "flex items-center gap-6 justify-end md:justify-between p-3 md:p-4"
                }, React.createElement(CoursesStudents__PersonalCoaches.make, {
                      title: React.createElement("div", {
                            className: "mb-1 font-semibold text-gray-800 text-tiny uppercase"
                          }, t(undefined, undefined, "personal_coaches")),
                      className: "hidden md:inline-block",
                      coaches: CoursesStudents__StudentInfo.personalCoaches(student)
                    }), levelInfo(CoursesStudents__StudentInfo.level(student))));
}

function CoursesStudents__StudentsList(Props) {
  var students = Props.students;
  return React.createElement("div", undefined, ArrayUtils.isEmpty(students) ? React.createElement("div", {
                    className: "course-review__reviewed-empty text-lg font-semibold text-center py-4"
                  }, React.createElement("h4", {
                        className: "py-4 mt-4 bg-gray-200 text-gray-800 font-semibold"
                      }, t(undefined, undefined, "no_students"))) : students.map(showStudent));
}

var make = CoursesStudents__StudentsList;

export {
  t ,
  ts ,
  str ,
  levelInfo ,
  userTags ,
  studentTags ,
  showStudent ,
  make ,
  
}
/*  Not a pure module */
