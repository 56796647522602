// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesReview__Reviewer from "../types/CoursesReview__Reviewer.bs.js";
import * as CoursesReview__SubmissionDetails from "../types/CoursesReview__SubmissionDetails.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReview__ReviewerManager";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation AssignReviewerMutation($submissionId: ID!)  {\nassignReviewer(submissionId: $submissionId)  {\nreviewer  {\n...UserProxyFragment   \n}\n\n}\n\n}\n" + UserProxy.Fragment.query;

function parse(value) {
  var value$1 = value.assignReviewer;
  var value$2 = value$1.reviewer;
  return {
          assignReviewer: {
            reviewer: UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.assignReviewer;
  var value$2 = value$1.reviewer;
  var reviewer = UserProxy.Fragment.serialize(value$2);
  var assignReviewer = {
    reviewer: reviewer
  };
  return {
          assignReviewer: assignReviewer
        };
}

function serializeVariables(inp) {
  return {
          submissionId: inp.submissionId
        };
}

function makeVariables(submissionId, param) {
  return {
          submissionId: submissionId
        };
}

var AssignReviewerMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var AssignReviewerMutation_Graphql_error = include.Graphql_error;

var AssignReviewerMutation_decodeNotification = include.decodeNotification;

var AssignReviewerMutation_decodeObject = include.decodeObject;

var AssignReviewerMutation_decodeNotifications = include.decodeNotifications;

var AssignReviewerMutation_decodeErrors = include.decodeErrors;

var AssignReviewerMutation_flashNotifications = include.flashNotifications;

var AssignReviewerMutation_sendQuery = include.sendQuery;

var AssignReviewerMutation_query = include.query;

var AssignReviewerMutation_fetch = include.$$fetch;

var AssignReviewerMutation = {
  AssignReviewerMutation_inner: AssignReviewerMutation_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: AssignReviewerMutation_Graphql_error,
  decodeNotification: AssignReviewerMutation_decodeNotification,
  decodeObject: AssignReviewerMutation_decodeObject,
  decodeNotifications: AssignReviewerMutation_decodeNotifications,
  decodeErrors: AssignReviewerMutation_decodeErrors,
  flashNotifications: AssignReviewerMutation_flashNotifications,
  sendQuery: AssignReviewerMutation_sendQuery,
  query: AssignReviewerMutation_query,
  $$fetch: AssignReviewerMutation_fetch,
  make: make
};

var Raw$1 = {};

var query$1 = "mutation ReassignReviewerMutation($submissionId: ID!)  {\nreassignReviewer(submissionId: $submissionId)  {\nreviewer  {\n...UserProxyFragment   \n}\n\n}\n\n}\n" + UserProxy.Fragment.query;

function parse$1(value) {
  var value$1 = value.reassignReviewer;
  var value$2 = value$1.reviewer;
  return {
          reassignReviewer: {
            reviewer: UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value$2)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.reassignReviewer;
  var value$2 = value$1.reviewer;
  var reviewer = UserProxy.Fragment.serialize(value$2);
  var reassignReviewer = {
    reviewer: reviewer
  };
  return {
          reassignReviewer: reassignReviewer
        };
}

function serializeVariables$1(inp) {
  return {
          submissionId: inp.submissionId
        };
}

function makeVariables$1(submissionId, param) {
  return {
          submissionId: submissionId
        };
}

var ReassignReviewerMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$1 = include$1.make;

var ReassignReviewerMutation_Graphql_error = include$1.Graphql_error;

var ReassignReviewerMutation_decodeNotification = include$1.decodeNotification;

var ReassignReviewerMutation_decodeObject = include$1.decodeObject;

var ReassignReviewerMutation_decodeNotifications = include$1.decodeNotifications;

var ReassignReviewerMutation_decodeErrors = include$1.decodeErrors;

var ReassignReviewerMutation_flashNotifications = include$1.flashNotifications;

var ReassignReviewerMutation_sendQuery = include$1.sendQuery;

var ReassignReviewerMutation_query = include$1.query;

var ReassignReviewerMutation_fetch = include$1.$$fetch;

var ReassignReviewerMutation = {
  ReassignReviewerMutation_inner: ReassignReviewerMutation_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: ReassignReviewerMutation_Graphql_error,
  decodeNotification: ReassignReviewerMutation_decodeNotification,
  decodeObject: ReassignReviewerMutation_decodeObject,
  decodeNotifications: ReassignReviewerMutation_decodeNotifications,
  decodeErrors: ReassignReviewerMutation_decodeErrors,
  flashNotifications: ReassignReviewerMutation_flashNotifications,
  sendQuery: ReassignReviewerMutation_sendQuery,
  query: ReassignReviewerMutation_query,
  $$fetch: ReassignReviewerMutation_fetch,
  make: make$1
};

function assignReviewer(submissionId, setSaving, updateReviewerCB) {
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  Curry._3(make, undefined, undefined, {
            submissionId: submissionId
          }).then(function (response) {
          Curry._1(updateReviewerCB, UserProxy.makeFromJs(response.assignReviewer.reviewer));
          Curry._1(setSaving, (function (param) {
                  return false;
                }));
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(setSaving, (function (param) {
                return false;
              }));
        return Promise.resolve(undefined);
      });
  
}

function reassignReviewer(submissionId, setSaving, updateReviewerCB) {
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  Curry._3(make$1, undefined, undefined, {
            submissionId: submissionId
          }).then(function (response) {
          Curry._1(updateReviewerCB, UserProxy.makeFromJs(response.reassignReviewer.reviewer));
          Curry._1(setSaving, (function (param) {
                  return false;
                }));
          return Promise.resolve(undefined);
        }).catch(function (param) {
        Curry._1(setSaving, (function (param) {
                return false;
              }));
        return Promise.resolve(undefined);
      });
  
}

function CoursesReview__ReviewerManager(Props) {
  var submissionId = Props.submissionId;
  var submissionDetails = Props.submissionDetails;
  var updateReviewerCB = Props.updateReviewerCB;
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var saving = match[0];
  var reviewer = CoursesReview__SubmissionDetails.reviewer(submissionDetails);
  var tmp;
  if (reviewer !== undefined) {
    var avatarUrl = UserProxy.avatarUrl(CoursesReview__Reviewer.user(reviewer));
    tmp = [
      React.createElement("div", {
            key: "reviewer-details",
            className: "inline-flex bg-gray-50 px-3 py-2 mt-2 rounded-md"
          }, avatarUrl !== undefined ? React.createElement("img", {
                  className: "h-9 w-9 md:h-10 md:w-10 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover",
                  src: avatarUrl
                }) : React.createElement(Avatar.make, {
                  name: UserProxy.name(CoursesReview__Reviewer.user(reviewer)),
                  className: "h-9 w-9 md:h-10 md:w-10 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover"
                }), React.createElement("div", {
                className: "ml-2"
              }, React.createElement("p", {
                    className: "text-sm font-semibold"
                  }, UserProxy.name(CoursesReview__Reviewer.user(reviewer))), React.createElement("p", {
                    className: "text-xs text-gray-800"
                  }, t([[
                          "date",
                          DateFns.formatDistanceToNow(CoursesReview__Reviewer.assignedAt(reviewer), undefined, true, undefined)
                        ]], undefined, "assigned_at")))),
      React.createElement("div", {
            key: "change-reviewer-button",
            className: "flex flex-col md:flex-row items-center mt-4"
          }, React.createElement("p", {
                className: "text-sm pr-4"
              }, t([[
                      "current_coach_name",
                      UserProxy.name(CoursesReview__Reviewer.user(reviewer))
                    ]], undefined, "remove_reviewer_assign_to_me")), React.createElement("button", {
                className: "btn md:btn-small btn-default w-full md:w-auto mt-2 md:mt-0",
                disabled: saving,
                onClick: (function (param) {
                    return reassignReviewer(submissionId, setSaving, updateReviewerCB);
                  })
              }, t(undefined, undefined, "change_reviewer_and_start_review")))
    ];
  } else {
    tmp = [
      React.createElement("div", {
            key: "no-reviewer",
            className: "flex items-center justify-center"
          }, React.createElement("div", {
                className: "h-24 w-24 md:h-30 md:w-30 rounded-xl bg-gray-100 flex items-center justify-center"
              }, React.createElement(Icon.make, {
                    className: "if i-eye-solid text-gray-400 text-4xl"
                  }))),
      React.createElement("div", {
            key: "start-review",
            className: "flex items-center justify-center mt-4"
          }, React.createElement("button", {
                className: "btn btn-primary w-full md:w-auto",
                disabled: saving,
                onClick: (function (param) {
                    return assignReviewer(submissionId, setSaving, updateReviewerCB);
                  })
              }, t(undefined, undefined, "start_review")))
    ];
  }
  return React.createElement("div", {
              className: "w-full p-4 md:p-6 space-y-8 mx-auto"
            }, React.createElement("div", undefined, tmp));
}

var UserProxyFragment;

var make$2 = CoursesReview__ReviewerManager;

export {
  str ,
  t ,
  UserProxyFragment ,
  AssignReviewerMutation ,
  ReassignReviewerMutation ,
  assignReviewer ,
  reassignReviewer ,
  make$2 as make,
  
}
/* include Not a pure module */
