// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as $$Notification from "../../../shared/Notification.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__UndoButton";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Raw = {};

var query = "mutation UndoSubmissionMutation($targetId: ID!)  {\nundoSubmission(targetId: $targetId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.undoSubmission;
  return {
          undoSubmission: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.undoSubmission;
  var value$2 = value$1.success;
  var undoSubmission = {
    success: value$2
  };
  return {
          undoSubmission: undoSubmission
        };
}

function serializeVariables(inp) {
  return {
          targetId: inp.targetId
        };
}

function makeVariables(targetId, param) {
  return {
          targetId: targetId
        };
}

var DeleteSubmissionQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var DeleteSubmissionQuery_Graphql_error = include.Graphql_error;

var DeleteSubmissionQuery_decodeNotification = include.decodeNotification;

var DeleteSubmissionQuery_decodeObject = include.decodeObject;

var DeleteSubmissionQuery_decodeNotifications = include.decodeNotifications;

var DeleteSubmissionQuery_decodeErrors = include.decodeErrors;

var DeleteSubmissionQuery_flashNotifications = include.flashNotifications;

var DeleteSubmissionQuery_sendQuery = include.sendQuery;

var DeleteSubmissionQuery_query = include.query;

var DeleteSubmissionQuery_make = include.make;

var DeleteSubmissionQuery = {
  DeleteSubmissionQuery_inner: DeleteSubmissionQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: DeleteSubmissionQuery_Graphql_error,
  decodeNotification: DeleteSubmissionQuery_decodeNotification,
  decodeObject: DeleteSubmissionQuery_decodeObject,
  decodeNotifications: DeleteSubmissionQuery_decodeNotifications,
  decodeErrors: DeleteSubmissionQuery_decodeErrors,
  flashNotifications: DeleteSubmissionQuery_flashNotifications,
  sendQuery: DeleteSubmissionQuery_sendQuery,
  query: DeleteSubmissionQuery_query,
  $$fetch: $$fetch,
  make: DeleteSubmissionQuery_make
};

function handleClick(targetId, setStatus, undoSubmissionCB, $$event) {
  $$event.preventDefault();
  if (window.confirm(tr(undefined, undefined, "window_confirm"))) {
    Curry._1(setStatus, (function (param) {
            return /* Undoing */1;
          }));
    Curry._3($$fetch, undefined, undefined, {
              targetId: targetId
            }).then(function (response) {
            if (response.undoSubmission.success) {
              Curry._1(undoSubmissionCB, undefined);
            } else {
              $$Notification.notice(tr(undefined, undefined, "notification_notice_head"), tr(undefined, undefined, "notification_notice_body"));
              Curry._1(setStatus, (function (param) {
                      return /* Errored */2;
                    }));
            }
            return Promise.resolve(undefined);
          }).catch(function (param) {
          $$Notification.error(tr(undefined, undefined, "notification_error_head"), tr(undefined, undefined, "notification_error_body"));
          Curry._1(setStatus, (function (param) {
                  return /* Errored */2;
                }));
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function buttonContents(status) {
  switch (status) {
    case /* Pending */0 :
        return React.createElement("span", undefined, React.createElement(FaIcon.make, {
                        classes: "fas fa-undo mr-2"
                      }), React.createElement("span", {
                        className: "hidden md:inline"
                      }, "Undo submission"), React.createElement("span", {
                        className: "md:hidden"
                      }, "Undo"));
    case /* Undoing */1 :
        return React.createElement("span", undefined, React.createElement(FaIcon.make, {
                        classes: "fas fa-spinner fa-spin mr-2"
                      }), "Undoing...");
    case /* Errored */2 :
        return React.createElement("span", undefined, React.createElement(FaIcon.make, {
                        classes: "fas fa-exclamation-triangle mr-2"
                      }), "Error!");
    
  }
}

function isDisabled(status) {
  return status !== 0;
}

function buttonClasses(status) {
  var tmp;
  switch (status) {
    case /* Pending */0 :
        tmp = "pointer";
        break;
    case /* Undoing */1 :
        tmp = "wait";
        break;
    case /* Errored */2 :
        tmp = "not-allowed";
        break;
    
  }
  return "btn btn-small btn-danger cursor-" + tmp;
}

function CoursesCurriculum__UndoButton(Props) {
  var undoSubmissionCB = Props.undoSubmissionCB;
  var targetId = Props.targetId;
  var match = React.useState(function () {
        return /* Pending */0;
      });
  var setStatus = match[1];
  var status = match[0];
  return React.createElement("button", {
              className: buttonClasses(status),
              title: tr(undefined, undefined, "undo_submission_title"),
              disabled: status !== 0,
              onClick: (function (param) {
                  return handleClick(targetId, setStatus, undoSubmissionCB, param);
                })
            }, buttonContents(status));
}

var make = CoursesCurriculum__UndoButton;

export {
  str ,
  tr ,
  DeleteSubmissionQuery ,
  handleClick ,
  buttonContents ,
  isDisabled ,
  buttonClasses ,
  make ,
  
}
/* include Not a pure module */
