// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function userId(t) {
  return t.userId;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          userId: Json_decode.field("userId", Json_decode.string, json)
        };
}

export {
  id ,
  userId ,
  decode ,
  
}
/* No side effect */
