// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as CoursesReport__Root from "../courses/report/components/CoursesReport__Root.bs.js";
import * as CoursesReport__Coach from "../courses/report/types/CoursesReport__Coach.bs.js";
import * as CoursesReport__Level from "../courses/report/types/CoursesReport__Level.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("studentId", Json_decode.string, json),
          Json_decode.field("levels", (function (param) {
                  return Json_decode.array(CoursesReport__Level.decode, param);
                }), json),
          Json_decode.field("coaches", (function (param) {
                  return Json_decode.array(CoursesReport__Coach.decode, param);
                }), json),
          Json_decode.field("teamStudentIds", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json)
        ];
}

Psj.match(undefined, "courses#report", (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag("course-student-report__props", undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(CoursesReport__Root.make, {
                    studentId: match[0],
                    levels: match[1],
                    coaches: match[2],
                    teamStudentIds: match[3]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
