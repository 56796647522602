// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as $$String from "../../../node_modules/rescript/lib/es6/string.js";
import * as DateFns from "../shared/utils/DateFns.bs.js";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ArrayUtils from "../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../shared/utils/OptionUtils.bs.js";
import * as StringUtils from "../shared/utils/StringUtils.bs.js";
import * as GraphqlQuery from "../shared/utils/GraphqlQuery.bs.js";
import * as $$Notification from "../shared/Notification.bs.js";
import * as TopicCategory from "../shared/types/TopicCategory.bs.js";
import * as DisablingCover from "../shared/components/DisablingCover.bs.js";
import * as MarkdownEditorWhite from "../shared/components/MarkdownEditorWhite.bs.js";
import * as TopicsShow__LinkedTarget from "../topics/types/TopicsShow__LinkedTarget.bs.js";
import * as CommunitiesNewTopic__TopicSuggestion from "./types/CommunitiesNewTopic__TopicSuggestion.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CommunitiesNewTopic__Root";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var initialState_similar = {
  search: "",
  suggestions: []
};

var initialState = {
  title: "",
  titleTimeoutId: undefined,
  similar: initialState_similar,
  searching: false,
  body: "",
  selectedCategory: undefined,
  saving: false
};

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* BeginSaving */0 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: state.similar,
                  searching: state.searching,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: true
                };
      case /* FailSaving */1 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: state.similar,
                  searching: state.searching,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: false
                };
      case /* BeginSearching */2 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: state.similar,
                  searching: true,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: state.saving
                };
      case /* FailSearching */3 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: state.similar,
                  searching: false,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: state.saving
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* UpdateTitle */0 :
          var title = action._0;
          var similar = $$String.trim(title) === "" ? ({
                search: "",
                suggestions: []
              }) : state.similar;
          return {
                  title: title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: similar,
                  searching: state.searching,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: state.saving
                };
      case /* UpdateTitleAndTimeout */1 :
          return {
                  title: action._0,
                  titleTimeoutId: Caml_option.some(action._1),
                  similar: state.similar,
                  searching: state.searching,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: state.saving
                };
      case /* UpdateBody */2 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: state.similar,
                  searching: state.searching,
                  body: action._0,
                  selectedCategory: state.selectedCategory,
                  saving: state.saving
                };
      case /* SelectCategory */3 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: state.similar,
                  searching: state.searching,
                  body: state.body,
                  selectedCategory: action._0,
                  saving: state.saving
                };
      case /* FinishSearching */4 :
          return {
                  title: state.title,
                  titleTimeoutId: state.titleTimeoutId,
                  similar: {
                    search: action._0,
                    suggestions: action._1
                  },
                  searching: false,
                  body: state.body,
                  selectedCategory: state.selectedCategory,
                  saving: state.saving
                };
      
    }
  }
}

var Raw = {};

var query = "query SimilarTopicsQuery($communityId: ID!, $title: String!)  {\nsimilarTopics(communityId: $communityId, title: $title)  {\nid  \ntitle  \ncreatedAt  \nliveRepliesCount  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.similarTopics;
  return {
          similarTopics: value$1.map(function (value) {
                return {
                        id: value.id,
                        title: value.title,
                        createdAt: value.createdAt,
                        liveRepliesCount: value.liveRepliesCount
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.similarTopics;
  var similarTopics = value$1.map(function (value) {
        var value$1 = value.liveRepliesCount;
        var value$2 = value.createdAt;
        var value$3 = value.title;
        var value$4 = value.id;
        return {
                id: value$4,
                title: value$3,
                createdAt: value$2,
                liveRepliesCount: value$1
              };
      });
  return {
          similarTopics: similarTopics
        };
}

function serializeVariables(inp) {
  return {
          communityId: inp.communityId,
          title: inp.title
        };
}

function makeVariables(communityId, title, param) {
  return {
          communityId: communityId,
          title: title
        };
}

var SimilarTopicsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var SimilarTopicsQuery_Graphql_error = include.Graphql_error;

var SimilarTopicsQuery_decodeNotification = include.decodeNotification;

var SimilarTopicsQuery_decodeObject = include.decodeObject;

var SimilarTopicsQuery_decodeNotifications = include.decodeNotifications;

var SimilarTopicsQuery_decodeErrors = include.decodeErrors;

var SimilarTopicsQuery_flashNotifications = include.flashNotifications;

var SimilarTopicsQuery_sendQuery = include.sendQuery;

var SimilarTopicsQuery_query = include.query;

var SimilarTopicsQuery_fetch = include.$$fetch;

var SimilarTopicsQuery = {
  SimilarTopicsQuery_inner: SimilarTopicsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: SimilarTopicsQuery_Graphql_error,
  decodeNotification: SimilarTopicsQuery_decodeNotification,
  decodeObject: SimilarTopicsQuery_decodeObject,
  decodeNotifications: SimilarTopicsQuery_decodeNotifications,
  decodeErrors: SimilarTopicsQuery_decodeErrors,
  flashNotifications: SimilarTopicsQuery_flashNotifications,
  sendQuery: SimilarTopicsQuery_sendQuery,
  query: SimilarTopicsQuery_query,
  $$fetch: SimilarTopicsQuery_fetch,
  make: make
};

function searchForSimilarTopics(send, title, communityId, param) {
  Curry._1(send, /* BeginSearching */2);
  var trimmedTitle = $$String.trim(title);
  Curry._3(make, undefined, undefined, {
            communityId: communityId,
            title: trimmedTitle
          }).then(function (result) {
          var suggestions = result.similarTopics.map(CommunitiesNewTopic__TopicSuggestion.makeFromJs);
          Curry._1(send, {
                TAG: /* FinishSearching */4,
                _0: trimmedTitle,
                _1: suggestions
              });
          return Promise.resolve(undefined);
        }).catch(function (e) {
        console.log(e);
        $$Notification.warn(tr(undefined, undefined, "oops"), tr(undefined, undefined, "failed_fetch_similar"));
        Curry._1(send, /* FailSaving */1);
        return Promise.resolve(undefined);
      });
  
}

function isInvalidString(s) {
  return $$String.trim(s) === "";
}

function updateTitleAndSearch(state, send, communityId, title) {
  Belt_Option.forEach(state.titleTimeoutId, (function (prim) {
          clearTimeout(prim);
          
        }));
  var trimmedTitle = $$String.trim(title);
  if ($$String.trim(title) === "" || trimmedTitle === state.similar.search) {
    return Curry._1(send, {
                TAG: /* UpdateTitle */0,
                _0: title
              });
  }
  var timeoutId = setTimeout((function (param) {
          return searchForSimilarTopics(send, trimmedTitle, communityId, param);
        }), 1500);
  return Curry._1(send, {
              TAG: /* UpdateTitleAndTimeout */1,
              _0: title,
              _1: timeoutId
            });
}

var Raw$1 = {};

var query$1 = "mutation CreateTopicQuery($title: String!, $body: String!, $communityId: ID!, $targetId: ID, $topicCategoryId: ID)  {\ncreateTopic(body: $body, title: $title, communityId: $communityId, targetId: $targetId, topicCategoryId: $topicCategoryId)  {\ntopicId  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.createTopic;
  var value$2 = value$1.topicId;
  return {
          createTopic: {
            topicId: !(value$2 == null) ? value$2 : undefined
          }
        };
}

function serialize$1(value) {
  var value$1 = value.createTopic;
  var value$2 = value$1.topicId;
  var topicId = value$2 !== undefined ? value$2 : null;
  var createTopic = {
    topicId: topicId
  };
  return {
          createTopic: createTopic
        };
}

function serializeVariables$1(inp) {
  var a = inp.targetId;
  var a$1 = inp.topicCategoryId;
  return {
          title: inp.title,
          body: inp.body,
          communityId: inp.communityId,
          targetId: a !== undefined ? a : undefined,
          topicCategoryId: a$1 !== undefined ? a$1 : undefined
        };
}

function makeVariables$1(title, body, communityId, targetId, topicCategoryId, param) {
  return {
          title: title,
          body: body,
          communityId: communityId,
          targetId: targetId,
          topicCategoryId: topicCategoryId
        };
}

var CreateTopicQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include$1.$$fetch;

var CreateTopicQuery_Graphql_error = include$1.Graphql_error;

var CreateTopicQuery_decodeNotification = include$1.decodeNotification;

var CreateTopicQuery_decodeObject = include$1.decodeObject;

var CreateTopicQuery_decodeNotifications = include$1.decodeNotifications;

var CreateTopicQuery_decodeErrors = include$1.decodeErrors;

var CreateTopicQuery_flashNotifications = include$1.flashNotifications;

var CreateTopicQuery_sendQuery = include$1.sendQuery;

var CreateTopicQuery_query = include$1.query;

var CreateTopicQuery_make = include$1.make;

var CreateTopicQuery = {
  CreateTopicQuery_inner: CreateTopicQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: CreateTopicQuery_Graphql_error,
  decodeNotification: CreateTopicQuery_decodeNotification,
  decodeObject: CreateTopicQuery_decodeObject,
  decodeNotifications: CreateTopicQuery_decodeNotifications,
  decodeErrors: CreateTopicQuery_decodeErrors,
  flashNotifications: CreateTopicQuery_flashNotifications,
  sendQuery: CreateTopicQuery_sendQuery,
  query: CreateTopicQuery_query,
  $$fetch: $$fetch,
  make: CreateTopicQuery_make
};

function redirectToNewTopic(id, title, userEmail, userAccessToken) {
  var redirectPath = "/topics/" + (id + ("/" + StringUtils.parameterize(title))) + "?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken);
  window.location = redirectPath;
  
}

function saveDisabled(state) {
  if ($$String.trim(state.body) === "") {
    return true;
  } else {
    return $$String.trim(state.title) === "";
  }
}

function handleCreateTopic(state, send, communityId, target, topicCategory, userEmail, userAccessToken, $$event) {
  $$event.preventDefault();
  if (saveDisabled(state)) {
    return $$Notification.error(tr(undefined, undefined, "missing_info"), tr(undefined, undefined, "topic_body_present"));
  }
  Curry._1(send, /* BeginSaving */0);
  var targetId = OptionUtils.flatMap(TopicsShow__LinkedTarget.id, target);
  var topicCategoryId = OptionUtils.flatMap((function (tc) {
          return TopicCategory.id(tc);
        }), topicCategory);
  Curry._3($$fetch, undefined, undefined, {
            title: state.title,
            body: state.body,
            communityId: communityId,
            targetId: targetId,
            topicCategoryId: topicCategoryId
          }).then(function (response) {
          var topicId = response.createTopic.topicId;
          if (topicId !== undefined) {
            $$Notification.success(tr(undefined, undefined, "done"), tr(undefined, undefined, "redirecting"));
            redirectToNewTopic(topicId, state.title, userEmail, userAccessToken);
          } else {
            Curry._1(send, /* FailSaving */1);
          }
          return Promise.resolve(undefined);
        }).catch(function (error) {
        console.log(error);
        $$Notification.error(ts(undefined, undefined, "notifications.unexpected_error"), tr(undefined, undefined, "please_reload"));
        return Promise.resolve(undefined);
      });
  
}

function suggestions(state, userEmail, userAccessToken) {
  var suggestions$1 = state.similar.suggestions;
  if (ArrayUtils.isNotEmpty(suggestions$1)) {
    return React.createElement("div", {
                className: "pt-3"
              }, React.createElement("span", {
                    className: "tracking-wide text-gray-900 text-xs font-semibold"
                  }, tr(undefined, undefined, "similar_topics")), state.searching ? React.createElement("span", {
                      className: "ml-2"
                    }, React.createElement(FaIcon.make, {
                          classes: "fa fa-spinner fa-pulse"
                        })) : null, suggestions$1.map(function (suggestion) {
                    var askedOn = DateFns.formatPreset(CommunitiesNewTopic__TopicSuggestion.createdAt(suggestion), true, true, undefined, undefined);
                    var n = CommunitiesNewTopic__TopicSuggestion.repliesCount(suggestion);
                    var match = n !== 0 ? (
                        n !== 1 ? [
                            String(n) + tr(undefined, undefined, "count_replies_label"),
                            "bg-green-500 text-white"
                          ] : [
                            tr(undefined, undefined, "one_reply"),
                            "bg-green-500 text-white"
                          ]
                      ) : [
                        tr(undefined, undefined, "no_replies"),
                        "bg-gray-300 text-gray-600"
                      ];
                    return React.createElement("a", {
                                key: CommunitiesNewTopic__TopicSuggestion.id(suggestion),
                                className: "flex w-full items-center justify-between mt-1 p-3 rounded cursor-pointer border bg-gray-50 hover:text-primary-400 hover:bg-gray-50",
                                href: "/topics/" + (CommunitiesNewTopic__TopicSuggestion.id(suggestion) + ("/" + StringUtils.parameterize(CommunitiesNewTopic__TopicSuggestion.title(suggestion)))) + "?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken),
                                target: "_blank"
                              }, React.createElement("div", {
                                    className: "flex flex-col min-w-0"
                                  }, React.createElement("h5", {
                                        className: "font-semibold text-sm leading-snug md:text-base pr-1 truncate flex-1",
                                        title: CommunitiesNewTopic__TopicSuggestion.title(suggestion)
                                      }, CommunitiesNewTopic__TopicSuggestion.title(suggestion)), React.createElement("p", {
                                        className: "text-xs mt-1 leading-tight text-gray-800"
                                      }, tr(undefined, undefined, "asked_on") + askedOn)), React.createElement("div", {
                                    className: "text-xs px-1 py-px ml-2 rounded font-semibold shrink-0 " + match[1]
                                  }, match[0]));
                  }));
  } else {
    return null;
  }
}

function searchingIndicator(state) {
  if (ArrayUtils.isEmpty(state.similar.suggestions) && state.searching) {
    return React.createElement("div", {
                className: "md:flex-1 pl-1 pb-3 md:p-0"
              }, React.createElement(FaIcon.make, {
                    classes: "fas fa-spinner fa-pulse"
                  }));
  } else {
    return null;
  }
}

function handleSelectTopicCategory(send, topicCategories, $$event) {
  var target = $$event.target;
  var selectedCategoryId = target.value;
  var selectedCategory = selectedCategoryId === "not_selected" ? undefined : ArrayUtils.unsafeFind((function (category) {
            return TopicCategory.id(category) === selectedCategoryId;
          }), tr(undefined, undefined, "no_category_found") + selectedCategoryId, topicCategories);
  return Curry._1(send, {
              TAG: /* SelectCategory */3,
              _0: selectedCategory
            });
}

function CommunitiesNewTopic__Root(Props) {
  var communityId = Props.communityId;
  var target = Props.target;
  var topicCategories = Props.topicCategories;
  var userEmail = Props.userEmail;
  var userAccessToken = Props.userAccessToken;
  var match = React.useReducer(reducer, initialState);
  var send = match[1];
  var state = match[0];
  var category = state.selectedCategory;
  var __x = topicCategories.map(function (category) {
        return React.createElement("option", {
                    key: TopicCategory.id(category),
                    value: TopicCategory.id(category)
                  }, TopicCategory.name(category));
      });
  var partial_arg = state.selectedCategory;
  return React.createElement(DisablingCover.make, {
              disabled: state.saving,
              children: React.createElement("div", {
                    className: "bg-gray-50"
                  }, React.createElement("div", {
                        className: "flex-1 flex flex-col"
                      }, React.createElement("div", {
                            className: "px-3 lg:px-0"
                          }, React.createElement("div", {
                                className: "max-w-3xl w-full mx-auto mt-5 pb-2"
                              }, React.createElement("a", {
                                    className: "btn btn-subtle",
                                    onClick: (function (param) {
                                        return DomUtils.goBack(undefined);
                                      })
                                  }, React.createElement("i", {
                                        className: "fas fa-arrow-left"
                                      }), React.createElement("span", {
                                        className: "ml-2"
                                      }, tr(undefined, undefined, "back"))))), target !== undefined ? React.createElement("div", {
                              className: "max-w-3xl w-full mt-5 mx-auto px-3 lg:px-0"
                            }, React.createElement("div", {
                                  className: "flex py-4 px-4 md:px-5 w-full bg-white border border-primary-500  shadow-md rounded-lg justify-between items-center mb-2"
                                }, React.createElement("p", {
                                      className: "w-3/5 md:w-4/5 text-sm"
                                    }, React.createElement("span", {
                                          className: "font-semibold block text-xs"
                                        }, tr(undefined, undefined, "linked_target")), React.createElement("span", undefined, TopicsShow__LinkedTarget.title(target))), React.createElement("a", {
                                      className: "btn btn-default",
                                      href: "./new_topic?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken)
                                    }, tr(undefined, undefined, "clear")))) : null, React.createElement("h4", {
                            className: "max-w-3xl w-full mx-auto pb-2 mt-2 px-3 lg:px-0"
                          }, tr(undefined, undefined, "create_topic_discussion")), React.createElement("div", {
                            className: "md:px-3"
                          }, React.createElement("div", {
                                className: "mb-8 max-w-3xl w-full mx-auto relative border-t border-b md:border-0 bg-white md:shadow md:rounded-lg"
                              }, React.createElement("div", {
                                    className: "flex w-full flex-col p-3 md:p-6"
                                  }, React.createElement("div", {
                                        className: "flex"
                                      }, React.createElement("div", {
                                            className: "flex-1 mr-2"
                                          }, React.createElement("label", {
                                                className: "inline-block tracking-wide text-gray-900 text-xs font-semibold mb-2",
                                                htmlFor: "title"
                                              }, tr(undefined, undefined, "title")), React.createElement("input", {
                                                className: "appearance-none block w-full bg-white text-gray-900 font-semibold border border-gray-300 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                                                id: "title",
                                                tabIndex: 1,
                                                placeholder: tr(undefined, undefined, "title_placeholder"),
                                                value: state.title,
                                                onChange: (function ($$event) {
                                                    var newTitle = $$event.target.value;
                                                    return updateTitleAndSearch(state, send, communityId, newTitle);
                                                  })
                                              })), ReactUtils.nullIf(React.createElement("div", {
                                                className: "w-1/4"
                                              }, React.createElement("label", {
                                                    className: "inline-block tracking-wide text-gray-900 text-xs font-semibold mb-2",
                                                    htmlFor: "topic_category"
                                                  }, tr(undefined, undefined, "select_category")), React.createElement("select", {
                                                    className: "appearance-none block w-full bg-white text-gray-900 font-semibold border border-gray-300 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
                                                    id: "topic_category",
                                                    value: category !== undefined ? TopicCategory.id(category) : "",
                                                    onChange: (function (param) {
                                                        return handleSelectTopicCategory(send, topicCategories, param);
                                                      })
                                                  }, [React.createElement("option", {
                                                            key: "not_selected",
                                                            value: "not_selected"
                                                          }, tr(undefined, undefined, "not_selected"))].concat(__x))), ArrayUtils.isEmpty(topicCategories)), React.createElement("div", undefined)), React.createElement("label", {
                                        className: "inline-block tracking-wide text-gray-900 text-xs font-semibold mb-2",
                                        htmlFor: "body"
                                      }, tr(undefined, undefined, "body")), React.createElement("div", {
                                        className: "w-full flex flex-col"
                                      }, React.createElement(MarkdownEditorWhite.make, {
                                            value: state.body,
                                            onChange: (function (markdown) {
                                                return Curry._1(send, {
                                                            TAG: /* UpdateBody */2,
                                                            _0: markdown
                                                          });
                                              }),
                                            profile: /* Permissive */0,
                                            textareaId: "body",
                                            maxLength: 10000,
                                            placeholder: tr(undefined, undefined, "be_descriptive"),
                                            tabIndex: 2
                                          }), React.createElement("div", undefined, suggestions(state, userEmail, userAccessToken), React.createElement("div", {
                                                className: "flex flex-col md:flex-row justify-end mt-3 items-center md:items-start"
                                              }, searchingIndicator(state), React.createElement("button", {
                                                    className: "btn btn-primary border border-transparent w-full md:w-auto",
                                                    tabIndex: 3,
                                                    disabled: saveDisabled(state),
                                                    onClick: (function (param) {
                                                        return handleCreateTopic(state, send, communityId, target, partial_arg, userEmail, userAccessToken, param);
                                                      })
                                                  }, tr(undefined, undefined, "create_topic"))))))))))
            });
}

var make$1 = CommunitiesNewTopic__Root;

export {
  str ,
  tr ,
  ts ,
  initialState ,
  reducer ,
  SimilarTopicsQuery ,
  searchForSimilarTopics ,
  isInvalidString ,
  updateTitleAndSearch ,
  CreateTopicQuery ,
  redirectToNewTopic ,
  saveDisabled ,
  handleCreateTopic ,
  suggestions ,
  searchingIndicator ,
  handleSelectTopicCategory ,
  make$1 as make,
  
}
/* include Not a pure module */
