// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../utils/DateFns.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function endsAt(t) {
  return t.endsAt;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          endsAt: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("endsAt", Json_decode.string, param);
                    }), json), DateFns.parseISO)
        };
}

export {
  id ,
  name ,
  endsAt ,
  decode ,
  
}
/* DateFns Not a pure module */
