// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function decode(json) {
  return {
          targetId: Json_decode.field("targetId", Json_decode.string, json),
          passedAt: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("passedAt", Json_decode.string, param);
                    }), json), DateFns.parseISO),
          evaluatedAt: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("evaluatedAt", Json_decode.string, param);
                    }), json), DateFns.parseISO)
        };
}

function targetId(t) {
  return t.targetId;
}

function hasPassed(t) {
  return t.passedAt !== undefined;
}

function hasBeenEvaluated(t) {
  return t.evaluatedAt !== undefined;
}

function make(pending, targetId) {
  return {
          targetId: targetId,
          passedAt: pending ? undefined : Caml_option.some(new Date()),
          evaluatedAt: undefined
        };
}

export {
  decode ,
  targetId ,
  hasPassed ,
  hasBeenEvaluated ,
  make ,
  
}
/* DateFns Not a pure module */
