// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as React from "react";
import * as Avatar from "../../../shared/Avatar.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as CoursesReport__Coach from "../types/CoursesReport__Coach.bs.js";
import * as CoursesReport__Level from "../types/CoursesReport__Level.bs.js";
import * as CoursesReport__StudentOverview from "../types/CoursesReport__StudentOverview.bs.js";
import * as CoursesStudents__PersonalCoaches from "../../students/components/CoursesStudents__PersonalCoaches.bs.js";
import * as CoursesReport__EvaluationCriterion from "../types/CoursesReport__EvaluationCriterion.bs.js";

import "./CoursesReport__Overview.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReport__Overview";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function avatar(avatarUrl, name) {
  var avatarClasses = "w-8 h-8 md:w-10 md:h-10 text-xs border border-gray-300 rounded-full overflow-hidden shrink-0 object-cover";
  if (avatarUrl !== undefined) {
    return React.createElement("img", {
                className: avatarClasses,
                src: avatarUrl
              });
  } else {
    return React.createElement(Avatar.make, {
                name: name,
                className: avatarClasses
              });
  }
}

function userInfo(key, avatarUrl, name, title) {
  return React.createElement("div", {
              key: key,
              className: "w-full md:w-1/2 shadow rounded-lg p-4 flex items-center mt-2 bg-white"
            }, CoursesStudents__PersonalCoaches.avatar(undefined, avatarUrl, name), React.createElement("div", {
                  className: "ml-2 md:ml-3"
                }, React.createElement("div", {
                      className: "text-sm font-semibold"
                    }, name), React.createElement("div", {
                      className: "text-xs"
                    }, title)));
}

function coachInfo(coaches) {
  if (ArrayUtils.isNotEmpty(coaches)) {
    return React.createElement("div", {
                className: "mb-8"
              }, React.createElement("h6", {
                    className: "font-semibold"
                  }, t(undefined, undefined, "personal_coaches")), $$Array.mapi((function (index, coach) {
                      return userInfo(String(index), CoursesReport__Coach.avatarUrl(coach), CoursesReport__Coach.name(coach), CoursesReport__Coach.title(coach));
                    }), coaches));
  } else {
    return null;
  }
}

function doughnutChart(color, percentage) {
  return React.createElement("svg", {
              className: "courses-report-overview__doughnut-chart " + color,
              viewBox: "0 0 36 36"
            }, React.createElement("path", {
                  className: "courses-report-overview__doughnut-chart-bg",
                  d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                }), React.createElement("path", {
                  className: "courses-report-overview__doughnut-chart-stroke",
                  d: "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",
                  strokeDasharray: percentage + ", 100"
                }), React.createElement("text", {
                  className: "courses-report-overview__doughnut-chart-text font-semibold",
                  x: "50%",
                  y: "58%"
                }, percentage + "%"));
}

function targetsCompletionStatus(overview) {
  var targetsCompleted = CoursesReport__StudentOverview.targetsCompleted(overview);
  var totalTargets = CoursesReport__StudentOverview.totalTargets(overview);
  var targetsPendingReview = CoursesReport__StudentOverview.targetsPendingReview(overview);
  var incompleteTargets = ((totalTargets | 0) - (targetsCompleted | 0) | 0) - targetsPendingReview | 0;
  var targetCompletionPercent = String(targetsCompleted / totalTargets * 100.0 | 0);
  return React.createElement("div", {
              "aria-label": "target-completion-status",
              className: "w-full lg:w-1/2 px-2"
            }, React.createElement("div", {
                  className: "courses-report-overview__doughnut-chart-container bg-white flex items-center"
                }, React.createElement("div", undefined, doughnutChart("purple", targetCompletionPercent)), React.createElement("div", {
                      className: "ml-4"
                    }, React.createElement("p", {
                          className: "text-sm text-gray-600 font-semibold mt-1"
                        }, t([[
                                "targetsCount",
                                String(incompleteTargets)
                              ]], undefined, "incomplete_targets")), React.createElement("p", {
                          className: "text-sm text-gray-600 font-semibold mt-1"
                        }, t([[
                                "targetsCount",
                                String(targetsPendingReview)
                              ]], undefined, "targets_pending_review")), React.createElement("p", {
                          className: "text-sm text-gray-600 font-semibold mt-1"
                        }, t([[
                                "targetsCount",
                                String(targetsCompleted | 0)
                              ]], undefined, "targets_completed")))));
}

function quizPerformanceChart(averageQuizScore, quizzesAttempted) {
  if (averageQuizScore !== undefined) {
    return React.createElement("div", {
                "aria-label": "quiz-performance-chart",
                className: "w-full lg:w-1/2 px-2 mt-2 lg:mt-0"
              }, React.createElement("div", {
                    className: "courses-report-overview__doughnut-chart-container bg-white"
                  }, React.createElement("div", undefined, doughnutChart("pink", String(averageQuizScore | 0))), React.createElement("div", {
                        className: "ml-4"
                      }, React.createElement("p", {
                            className: "text-sm font-semibold mt-3"
                          }, t(undefined, undefined, "average_quiz_score")), React.createElement("p", {
                            className: "text-sm text-gray-600 font-semibold leading-tight mt-1"
                          }, t(undefined, quizzesAttempted, "quizzes_attempted")))));
  } else {
    return null;
  }
}

function averageGradeCharts(evaluationCriteria, averageGrades) {
  return $$Array.map((function (grade) {
                var criterion = CoursesReport__StudentOverview.evaluationCriterionForGrade(grade, evaluationCriteria);
                var passGrade = CoursesReport__EvaluationCriterion.passGrade(criterion);
                var averageGrade = CoursesReport__StudentOverview.gradeValue(grade);
                return React.createElement("div", {
                            key: CoursesReport__EvaluationCriterion.id(criterion),
                            "aria-label": "average-grade-for-criterion-" + CoursesReport__EvaluationCriterion.id(criterion),
                            className: "flex w-full lg:w-1/3 px-2 mt-2"
                          }, React.createElement("div", {
                                className: "courses-report-overview__pie-chart-container"
                              }, React.createElement("div", {
                                    className: "flex px-5 pt-4 text-center items-center"
                                  }, React.createElement("svg", {
                                        className: "courses-report-overview__pie-chart " + (
                                          averageGrade < passGrade ? "courses-report-overview__pie-chart--fail" : "courses-report-overview__pie-chart--pass"
                                        ),
                                        viewBox: "0 0 32 32"
                                      }, React.createElement("circle", {
                                            className: "courses-report-overview__pie-chart-circle " + (
                                              averageGrade < passGrade ? "courses-report-overview__pie-chart-circle--fail" : "courses-report-overview__pie-chart-circle--pass"
                                            ),
                                            cx: "16",
                                            cy: "16",
                                            r: "16",
                                            strokeDasharray: CoursesReport__StudentOverview.gradeAsPercentage(grade, criterion) + ", 100"
                                          })), React.createElement("span", {
                                        className: "ml-3 text-lg font-semibold"
                                      }, grade.grade.toString() + ("/" + String(criterion.maxGrade)))), React.createElement("p", {
                                    className: "text-sm font-semibold px-5 pt-3 pb-4"
                                  }, CoursesReport__EvaluationCriterion.name(criterion))));
              }), averageGrades);
}

function studentLevelClasses(levelNumber, levelCompleted, currentLevelNumber) {
  var reached = Caml_obj.caml_lessequal(levelNumber, currentLevelNumber) ? "courses-report-overview__student-level--reached" : "";
  var current = Caml_obj.caml_equal(levelNumber, currentLevelNumber) ? " courses-report-overview__student-level--current" : "";
  var completed = levelCompleted ? " courses-report-overview__student-level--completed" : "";
  return reached + (current + completed);
}

function levelProgressBar(levelId, levels, levelsCompleted) {
  var applicableLevels = levels.filter(function (level) {
        return CoursesReport__Level.number(level) !== 0;
      });
  var courseCompleted = $$Array.for_all((function (level) {
          return $$Array.mem(CoursesReport__Level.id(level), levelsCompleted);
        }), applicableLevels);
  var currentLevelNumber = CoursesReport__Level.number(ArrayUtils.unsafeFind((function (level) {
              return CoursesReport__Level.id(level) === levelId;
            }), "Unable to find level with id" + (levelId + "in CoursesReport__Overview"), applicableLevels));
  return React.createElement("div", {
              className: "mb-8"
            }, React.createElement("div", {
                  className: "flex justify-between items-end"
                }, React.createElement("h6", {
                      className: "text-sm font-semibold"
                    }, t(undefined, undefined, "level_progress")), courseCompleted ? React.createElement("p", {
                        className: "text-green-600 font-semibold"
                      }, "🎉", React.createElement("span", {
                            className: "text-xs ml-px"
                          }, t(undefined, undefined, "course_completed"))) : null), React.createElement("div", {
                  className: "h-14 flex items-center shadow bg-white rounded-lg px-4 py-2 mt-1"
                }, React.createElement("ul", {
                      className: "courses-report-overview__student-level-progress flex w-full " + (
                        courseCompleted ? "courses-report-overview__student-level-progress--completed" : ""
                      )
                    }, $$Array.map((function (level) {
                            var levelNumber = CoursesReport__Level.number(level);
                            var levelCompleted = $$Array.mem(CoursesReport__Level.id(level), levelsCompleted);
                            return React.createElement("li", {
                                        key: CoursesReport__Level.id(level),
                                        className: "flex-1 courses-report-overview__student-level " + studentLevelClasses(levelNumber, levelCompleted, currentLevelNumber)
                                      }, React.createElement("span", {
                                            className: "courses-report-overview__student-level-count"
                                          }, String(levelNumber)));
                          }), CoursesReport__Level.sort(applicableLevels)))));
}

function CoursesReport__Overview(Props) {
  var overviewData = Props.overviewData;
  var levels = Props.levels;
  var coaches = Props.coaches;
  var tmp;
  if (overviewData) {
    var overview = overviewData._0;
    tmp = React.createElement("div", {
          className: "flex flex-col"
        }, React.createElement("div", {
              className: "w-full"
            }, levelProgressBar(CoursesReport__StudentOverview.levelId(overview), levels, CoursesReport__StudentOverview.completedLevelIds(overview)), React.createElement("div", {
                  className: "mb-8"
                }, React.createElement("h6", {
                      className: "font-semibold"
                    }, t(undefined, undefined, "targets_overview")), React.createElement("div", {
                      className: "flex -mx-2 flex-wrap mt-2"
                    }, targetsCompletionStatus(overview), quizPerformanceChart(CoursesReport__StudentOverview.averageQuizScore(overview), CoursesReport__StudentOverview.quizzesAttempted(overview)))), ArrayUtils.isNotEmpty(CoursesReport__StudentOverview.averageGrades(overview)) ? React.createElement("div", {
                    className: "mb-8"
                  }, React.createElement("h6", {
                        className: "font-semibold"
                      }, t(undefined, undefined, "average_grades")), React.createElement("div", {
                        className: "flex -mx-2 flex-wrap"
                      }, averageGradeCharts(CoursesReport__StudentOverview.evaluationCriteria(overview), CoursesReport__StudentOverview.averageGrades(overview)))) : null, coachInfo(coaches)));
  } else {
    tmp = React.createElement("div", {
          className: "flex flex-col"
        }, React.createElement("div", {
              className: "w-full bg-white p-8"
            }, SkeletonLoading.heading(undefined), SkeletonLoading.multiple(2, SkeletonLoading.paragraph(undefined)), SkeletonLoading.multiple(2, SkeletonLoading.userDetails(undefined))));
  }
  return React.createElement("div", {
              className: "max-w-3xl mx-auto"
            }, tmp);
}

var make = CoursesReport__Overview;

export {
  str ,
  t ,
  avatar ,
  userInfo ,
  coachInfo ,
  doughnutChart ,
  targetsCompletionStatus ,
  quizPerformanceChart ,
  averageGradeCharts ,
  studentLevelClasses ,
  levelProgressBar ,
  make ,
  
}
/*  Not a pure module */
