// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as Sorter from "../../../shared/components/Sorter.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as HelpIcon from "../../../shared/components/HelpIcon.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as LevelLabel from "../../../shared/utils/LevelLabel.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as LoadingSpinner from "../../../shared/components/LoadingSpinner.bs.js";
import * as SkeletonLoading from "../../../shared/components/SkeletonLoading.bs.js";
import * as MultiselectDropdown from "../../../packages/multiselect-dropdown/src/components/MultiselectDropdown.bs.js";
import * as CoursesReport__Level from "../types/CoursesReport__Level.bs.js";
import * as CoursesReport__Submission from "../types/CoursesReport__Submission.bs.js";
import * as CoursesReport__Submissions from "../types/CoursesReport__Submissions.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReport__SubmissionsList";

function tr(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var sortBy_criterion = tr(undefined, undefined, "submitted_at");

var sortBy = {
  criterion: sortBy_criterion,
  criterionType: "Number"
};

function statusString(targetStatus) {
  if (targetStatus === "Completed") {
    return tr(undefined, undefined, "completed");
  } else if (targetStatus === "Rejected") {
    return tr(undefined, undefined, "rejected");
  } else {
    return tr(undefined, undefined, "pending_review");
  }
}

var Raw = {};

var query = "query StudentsReportSubmissionsQuery($studentId: ID!, $after: String, $status: SubmissionReviewStatus, $levelId: ID, $sortDirection: SortDirection!)  {\nstudentSubmissions(studentId: $studentId, after: $after, first: 20, status: $status, levelId: $levelId, sortDirection: $sortDirection)  {\nnodes  {\nid  \ncreatedAt  \nlevelId  \ntargetId  \npassedAt  \ntitle  \nevaluatedAt  \nstudentIds  \nteamTarget  \n}\n\npageInfo  {\nhasNextPage  \nendCursor  \n}\n\n}\n\n}\n";

function parse(value) {
  var value$1 = value.studentSubmissions;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  return {
          studentSubmissions: {
            nodes: value$2.map(function (value) {
                  var value$1 = value.passedAt;
                  var value$2 = value.evaluatedAt;
                  var value$3 = value.studentIds;
                  return {
                          id: value.id,
                          createdAt: value.createdAt,
                          levelId: value.levelId,
                          targetId: value.targetId,
                          passedAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                          title: value.title,
                          evaluatedAt: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                          studentIds: value$3.map(function (value) {
                                return value;
                              }),
                          teamTarget: value.teamTarget
                        };
                }),
            pageInfo: {
              hasNextPage: value$3.hasNextPage,
              endCursor: !(value$4 == null) ? value$4 : undefined
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.studentSubmissions;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var endCursor = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.hasNextPage;
  var pageInfo = {
    hasNextPage: value$4,
    endCursor: endCursor
  };
  var value$5 = value$1.nodes;
  var nodes = value$5.map(function (value) {
        var value$1 = value.teamTarget;
        var value$2 = value.studentIds;
        var studentIds = value$2.map(function (value) {
              return value;
            });
        var value$3 = value.evaluatedAt;
        var evaluatedAt = value$3 !== undefined ? Caml_option.valFromOption(value$3) : null;
        var value$4 = value.title;
        var value$5 = value.passedAt;
        var passedAt = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
        var value$6 = value.targetId;
        var value$7 = value.levelId;
        var value$8 = value.createdAt;
        var value$9 = value.id;
        return {
                id: value$9,
                createdAt: value$8,
                levelId: value$7,
                targetId: value$6,
                passedAt: passedAt,
                title: value$4,
                evaluatedAt: evaluatedAt,
                studentIds: studentIds,
                teamTarget: value$1
              };
      });
  var studentSubmissions = {
    nodes: nodes,
    pageInfo: pageInfo
  };
  return {
          studentSubmissions: studentSubmissions
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  var a$1 = inp.status;
  var a$2 = inp.levelId;
  var a$3 = inp.sortDirection;
  return {
          studentId: inp.studentId,
          after: a !== undefined ? a : undefined,
          status: a$1 !== undefined ? (
              a$1 === "Completed" ? "Completed" : (
                  a$1 === "Rejected" ? "Rejected" : "PendingReview"
                )
            ) : undefined,
          levelId: a$2 !== undefined ? a$2 : undefined,
          sortDirection: a$3 === "Descending" ? "Descending" : "Ascending"
        };
}

function makeVariables(studentId, after, status, levelId, sortDirection, param) {
  return {
          studentId: studentId,
          after: after,
          status: status,
          levelId: levelId,
          sortDirection: sortDirection
        };
}

var StudentSubmissionsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var StudentSubmissionsQuery_Graphql_error = include.Graphql_error;

var StudentSubmissionsQuery_decodeNotification = include.decodeNotification;

var StudentSubmissionsQuery_decodeObject = include.decodeObject;

var StudentSubmissionsQuery_decodeNotifications = include.decodeNotifications;

var StudentSubmissionsQuery_decodeErrors = include.decodeErrors;

var StudentSubmissionsQuery_flashNotifications = include.flashNotifications;

var StudentSubmissionsQuery_sendQuery = include.sendQuery;

var StudentSubmissionsQuery_query = include.query;

var StudentSubmissionsQuery_fetch = include.$$fetch;

var StudentSubmissionsQuery = {
  StudentSubmissionsQuery_inner: StudentSubmissionsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: StudentSubmissionsQuery_Graphql_error,
  decodeNotification: StudentSubmissionsQuery_decodeNotification,
  decodeObject: StudentSubmissionsQuery_decodeObject,
  decodeNotifications: StudentSubmissionsQuery_decodeNotifications,
  decodeErrors: StudentSubmissionsQuery_decodeErrors,
  flashNotifications: StudentSubmissionsQuery_flashNotifications,
  sendQuery: StudentSubmissionsQuery_sendQuery,
  query: StudentSubmissionsQuery_query,
  $$fetch: StudentSubmissionsQuery_fetch,
  make: make
};

function label(t) {
  if (t.TAG === /* Level */0) {
    return LevelLabel.format(undefined, undefined, String(CoursesReport__Level.number(t._0)));
  } else {
    return tr(undefined, undefined, "status");
  }
}

function value(t) {
  if (t.TAG === /* Level */0) {
    return CoursesReport__Level.name(t._0);
  } else {
    return statusString(t._0);
  }
}

function searchString(t) {
  if (t.TAG !== /* Level */0) {
    return "status " + statusString(t._0);
  }
  var level = t._0;
  return LevelLabel.searchString(String(CoursesReport__Level.number(level)), CoursesReport__Level.name(level));
}

function color(t) {
  if (t.TAG === /* Level */0) {
    return "gray";
  }
  var status = t._0;
  if (status === "Completed") {
    return "green";
  } else if (status === "Rejected") {
    return "red";
  } else {
    return "blue";
  }
}

function level(level$1) {
  return {
          TAG: /* Level */0,
          _0: level$1
        };
}

function targetStatus(targetStatus$1) {
  return {
          TAG: /* TargetStatus */1,
          _0: targetStatus$1
        };
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  level: level,
  targetStatus: targetStatus
};

var Multiselect = MultiselectDropdown.Make(Selectable);

function unselected(levels, selectedLevel, selectedStatus) {
  var unselectedLevels = $$Array.map(level, levels.filter(function (level) {
              return CoursesReport__Level.number(level) !== 0;
            }).filter(function (level) {
            return OptionUtils.mapWithDefault((function (selectedLevel) {
                          return CoursesReport__Level.id(level) !== CoursesReport__Level.id(selectedLevel);
                        }), true, selectedLevel);
          }));
  var unselectedStatus = $$Array.map(targetStatus, [
          "PendingReview",
          "Rejected",
          "Completed"
        ].filter(function (status) {
            return OptionUtils.mapWithDefault((function (selectedStatus) {
                          return status !== selectedStatus;
                        }), true, selectedStatus);
          }));
  return $$Array.append(unselectedStatus, unselectedLevels);
}

function selected(selectedLevel, selectedStatus) {
  var selectedLevel$1 = OptionUtils.mapWithDefault((function (selectedLevel) {
          return [{
                    TAG: /* Level */0,
                    _0: selectedLevel
                  }];
        }), [], selectedLevel);
  var selectedStatus$1 = OptionUtils.mapWithDefault((function (selectedStatus) {
          return [{
                    TAG: /* TargetStatus */1,
                    _0: selectedStatus
                  }];
        }), [], selectedStatus);
  return $$Array.append(selectedStatus$1, selectedLevel$1);
}

function onSelectFilter(send, updateSelectedLevelCB, updateSelectedStatusCB, selectable) {
  Curry._1(send, /* UpdateFilterString */{
        _0: ""
      });
  if (selectable.TAG === /* Level */0) {
    return Curry._1(updateSelectedLevelCB, selectable._0);
  } else {
    return Curry._1(updateSelectedStatusCB, selectable._0);
  }
}

function onDeselectFilter(updateSelectedLevelCB, updateSelectedStatusCB, selectable) {
  if (selectable.TAG === /* Level */0) {
    return Curry._1(updateSelectedLevelCB, undefined);
  } else {
    return Curry._1(updateSelectedStatusCB, undefined);
  }
}

function criterion(t) {
  return t.criterion;
}

function criterionType(t) {
  return t.criterionType;
}

var Sortable = {
  criterion: criterion,
  criterionType: criterionType
};

var SubmissionsSorter = Sorter.Make(Sortable);

function submissionsSorter(sortDirection, updateSortDirectionCB) {
  var criteria = [sortBy];
  return React.createElement("div", {
              "aria-label": "Change submissions sorting",
              className: "shrink-0 mt-3 md:mt-0 md:ml-2"
            }, React.createElement("label", {
                  className: "block text-tiny font-semibold uppercase"
                }, tr(undefined, undefined, "sort_by")), React.createElement(SubmissionsSorter.make, {
                  criteria: criteria,
                  selectedCriterion: sortBy,
                  direction: sortDirection,
                  onDirectionChange: Curry.__1(updateSortDirectionCB),
                  onCriterionChange: (function (param) {
                      
                    })
                }));
}

function filterPlaceholder(selectedLevel, selectedStatus) {
  if (selectedLevel !== undefined) {
    if (selectedStatus !== undefined) {
      return tr(undefined, undefined, "filter_by_another_level");
    } else {
      return tr(undefined, undefined, "filter_by_another_level_or_status");
    }
  } else if (selectedStatus !== undefined) {
    return tr(undefined, undefined, "filter_by_level");
  } else {
    return tr(undefined, undefined, "filter_by_level_or_status");
  }
}

function reducer(state, action) {
  if (typeof action !== "number") {
    return {
            loading: state.loading,
            filterString: action._0
          };
  }
  switch (action) {
    case /* BeginLoadingMore */0 :
        return {
                loading: /* LoadingMore */2,
                filterString: state.filterString
              };
    case /* BeginReloading */1 :
        return {
                loading: /* Reloading */1,
                filterString: state.filterString
              };
    case /* CompletedLoading */2 :
        return {
                loading: /* Loaded */0,
                filterString: state.filterString
              };
    
  }
}

function updateStudentSubmissions(send, updateSubmissionsCB, endCursor, hasNextPage, submissions, selectedLevel, selectedStatus, sortDirection, nodes) {
  var updatedSubmissions = submissions.concat(CoursesReport__Submission.makeFromJs(nodes));
  var filter = CoursesReport__Submissions.makeFilter(selectedLevel, selectedStatus);
  var submissionsData = CoursesReport__Submissions.make(updatedSubmissions, filter, sortDirection);
  var submissionsData$1 = hasNextPage && endCursor !== undefined ? ({
        TAG: /* PartiallyLoaded */0,
        _0: submissionsData,
        _1: endCursor
      }) : ({
        TAG: /* FullyLoaded */1,
        _0: submissionsData
      });
  Curry._1(updateSubmissionsCB, submissionsData$1);
  return Curry._1(send, /* CompletedLoading */2);
}

function getStudentSubmissions(studentId, cursor, send, level, status, sortDirection, submissions, updateSubmissionsCB) {
  var levelId = Belt_Option.flatMap(level, (function (level) {
          return CoursesReport__Level.id(level);
        }));
  var status$1 = Belt_Option.flatMap(status, (function (status) {
          return status;
        }));
  Curry._3(make, undefined, undefined, {
          studentId: studentId,
          after: cursor,
          status: status$1,
          levelId: levelId,
          sortDirection: sortDirection
        }).then(function (response) {
        updateStudentSubmissions(send, updateSubmissionsCB, response.studentSubmissions.pageInfo.endCursor, response.studentSubmissions.pageInfo.hasNextPage, submissions, level, status$1, sortDirection, response.studentSubmissions.nodes);
        return Promise.resolve(undefined);
      });
  
}

function showSubmissionStatus(submission) {
  var match = CoursesReport__Submission.status(submission);
  if (match === "Completed") {
    return React.createElement("div", {
                className: "bg-green-100 border border-green-500 shrink-0 leading-normal text-green-800 font-semibold px-3 py-px rounded"
              }, tr(undefined, undefined, "completed"));
  } else if (match === "Rejected") {
    return React.createElement("div", {
                className: "bg-red-100 border border-red-500 shrink-0 leading-normal text-red-800 font-semibold px-3 py-px rounded"
              }, tr(undefined, undefined, "rejected"));
  } else {
    return React.createElement("div", {
                className: "bg-blue-100 border border-blue-500 shrink-0 leading-normal text-blue-800 font-semibold px-3 py-px rounded"
              }, tr(undefined, undefined, "pending_review"));
  }
}

function submissionCardClasses(submission) {
  var match = CoursesReport__Submission.status(submission);
  return "flex flex-col md:flex-row items-start md:items-center justify-between rounded-lg bg-white border-l-3 p-3 md:py-6 md:px-5 mt-4 cursor-pointer shadow hover:border-primary-500 hover:text-primary-400 hover:shadow-md focus:outline-none focus:border-2 focus:border-focusColor-500 " + (
          match === "Completed" ? "border-green-500" : (
              match === "Rejected" ? "border-red-500" : "border-blue-500"
            )
        );
}

function showSubmission(submissions, levels, teamStudentIds) {
  return React.createElement("div", undefined, $$Array.map((function (submission) {
                    var studentIds = CoursesReport__Submission.targetRole(submission);
                    var teamMismatch = studentIds ? Caml_obj.caml_notequal(teamStudentIds, studentIds._0) : false;
                    var submissionHref = teamMismatch ? "/submissions/" + CoursesReport__Submission.id(submission) : "/targets/" + CoursesReport__Submission.targetId(submission);
                    return React.createElement("div", {
                                key: CoursesReport__Submission.id(submission),
                                className: ""
                              }, React.createElement("a", {
                                    "aria-label": "Student submission " + CoursesReport__Submission.id(submission),
                                    className: "block relative z-10 rounded-lg focus:outline-none focus:ring focus-ring-inset focus:ring-focusColor-500",
                                    href: submissionHref
                                  }, React.createElement("div", {
                                        key: CoursesReport__Submission.id(submission),
                                        className: submissionCardClasses(submission)
                                      }, React.createElement("div", {
                                            className: "w-full md:w-3/4"
                                          }, React.createElement("div", {
                                                className: "block text-sm md:pr-2"
                                              }, React.createElement("span", {
                                                    className: "bg-gray-300 text-xs font-semibold px-2 py-px rounded"
                                                  }, CoursesReport__Level.levelLabel(levels, CoursesReport__Submission.levelId(submission))), React.createElement("span", {
                                                    className: "ml-2 font-semibold text-base"
                                                  }, CoursesReport__Submission.title(submission))), React.createElement("div", {
                                                className: "mt-1 ml-px text-xs text-gray-900"
                                              }, React.createElement("span", {
                                                    className: "ml-1"
                                                  }, tr([[
                                                          "date",
                                                          CoursesReport__Submission.createdAtPretty(submission)
                                                        ]], undefined, "submitted_on")))), React.createElement("div", {
                                            className: "w-auto md:w-1/4 text-xs flex justify-end mt-2 md:mt-0"
                                          }, showSubmissionStatus(submission)))), teamMismatch ? React.createElement("div", {
                                      "aria-label": "Team change notice for submission " + CoursesReport__Submission.id(submission),
                                      className: "w-full text-xs rounded-b bg-blue-100 text-blue-700 px-4 pt-3 pb-2 -mt-1 flex flex-1 justify-between items-center"
                                    }, React.createElement("div", {
                                          className: "flex flex-1 justify-start items-center pr-8"
                                        }, React.createElement(FaIcon.make, {
                                              classes: "fas fa-exclamation-triangle text-sm md:text-base mt-1"
                                            }), React.createElement("div", {
                                              className: "inline-block pl-3"
                                            }, tr(undefined, undefined, "submission_not_considered"), React.createElement(HelpIcon.make, {
                                                  className: "ml-1",
                                                  children: React.createElement("span", {
                                                        dangerouslySetInnerHTML: {
                                                          __html: tr(undefined, undefined, "submission_not_considered_help")
                                                        }
                                                      })
                                                }))), React.createElement("a", {
                                          className: "shrink-0 px-2 py-1 text-xs font-semibold text-blue-700 hover:bg-blue-200 hover:text-blue-800 rounded focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500",
                                          href: "/targets/" + CoursesReport__Submission.targetId(submission)
                                        }, React.createElement("span", {
                                              className: "hidden md:inline"
                                            }, tr(undefined, undefined, "view")), ts(undefined, undefined, "target"), React.createElement(FaIcon.make, {
                                              classes: "fas fa-arrow-right ml-2"
                                            }))) : null);
                  }), submissions));
}

function showSubmissions(submissions, levels, teamStudentIds) {
  if (ArrayUtils.isEmpty(submissions)) {
    return React.createElement("div", {
                className: "course-review__reviewed-empty text-lg font-semibold text-center py-4"
              }, React.createElement("h5", {
                    className: "py-4 mt-4 bg-gray-50 text-gray-800 font-semibold"
                  }, tr(undefined, undefined, "no_submissions_to_show")));
  } else {
    return showSubmission(submissions, levels, teamStudentIds);
  }
}

function CoursesReport__SubmissionsList(Props) {
  var studentId = Props.studentId;
  var levels = Props.levels;
  var submissions = Props.submissions;
  var updateSubmissionsCB = Props.updateSubmissionsCB;
  var teamStudentIds = Props.teamStudentIds;
  var selectedLevel = Props.selectedLevel;
  var selectedStatus = Props.selectedStatus;
  var sortDirection = Props.sortDirection;
  var updateSelectedLevelCB = Props.updateSelectedLevelCB;
  var updateSelectedStatusCB = Props.updateSelectedStatusCB;
  var updateSortDirectionCB = Props.updateSortDirectionCB;
  var match = React.useReducer(reducer, {
        loading: /* Loaded */0,
        filterString: ""
      });
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          if (CoursesReport__Submissions.needsReloading(selectedLevel, selectedStatus, sortDirection, submissions)) {
            Curry._1(send, /* BeginReloading */1);
            getStudentSubmissions(studentId, undefined, send, selectedLevel, selectedStatus, sortDirection, [], updateSubmissionsCB);
          }
          
        }), [
        selectedLevel,
        selectedStatus,
        sortDirection
      ]);
  var tmp;
  if (typeof submissions === "number") {
    tmp = SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined));
  } else if (submissions.TAG === /* PartiallyLoaded */0) {
    var cursor = submissions._1;
    var submissions$1 = submissions._0.submissions;
    var match$1 = state.loading;
    var tmp$1;
    switch (match$1) {
      case /* Loaded */0 :
          tmp$1 = React.createElement("button", {
                className: "btn btn-primary-ghost cursor-pointer w-full mt-4 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500",
                onClick: (function (param) {
                    Curry._1(send, /* BeginLoadingMore */0);
                    return getStudentSubmissions(studentId, cursor, send, selectedLevel, selectedStatus, sortDirection, submissions$1, updateSubmissionsCB);
                  })
              }, ts(undefined, undefined, "load_more"));
          break;
      case /* Reloading */1 :
          tmp$1 = null;
          break;
      case /* LoadingMore */2 :
          tmp$1 = SkeletonLoading.multiple(3, SkeletonLoading.card(undefined, undefined));
          break;
      
    }
    tmp = React.createElement("div", undefined, showSubmissions(submissions$1, levels, teamStudentIds), tmp$1);
  } else {
    tmp = showSubmissions(submissions._0.submissions, levels, teamStudentIds);
  }
  var tmp$2;
  if (typeof submissions === "number") {
    tmp$2 = null;
  } else {
    var match$2 = state.loading;
    var loading = match$2 === 1;
    tmp$2 = React.createElement(LoadingSpinner.make, {
          loading: loading
        });
  }
  return React.createElement("div", {
              className: "max-w-3xl mx-auto"
            }, React.createElement("div", {
                  className: "md:flex items-end w-full pb-4",
                  role: "form"
                }, React.createElement("div", {
                      className: "flex-1"
                    }, React.createElement("label", {
                          className: "block text-tiny font-semibold uppercase",
                          htmlFor: "filter"
                        }, "Filter by:"), React.createElement(Multiselect.make, {
                          id: "filter",
                          placeholder: filterPlaceholder(selectedLevel, selectedStatus),
                          onChange: (function (filterString) {
                              return Curry._1(send, /* UpdateFilterString */{
                                          _0: filterString
                                        });
                            }),
                          value: state.filterString,
                          unselected: unselected(levels, selectedLevel, selectedStatus),
                          selected: selected(selectedLevel, selectedStatus),
                          onSelect: (function (param) {
                              return onSelectFilter(send, updateSelectedLevelCB, updateSelectedStatusCB, param);
                            }),
                          onDeselect: (function (param) {
                              return onDeselectFilter(updateSelectedLevelCB, updateSelectedStatusCB, param);
                            })
                        })), submissionsSorter(sortDirection, updateSortDirectionCB)), React.createElement("div", {
                  "aria-label": "Student submissions"
                }, tmp), tmp$2);
}

var make$1 = CoursesReport__SubmissionsList;

export {
  str ,
  tr ,
  ts ,
  sortBy ,
  statusString ,
  StudentSubmissionsQuery ,
  Selectable ,
  Multiselect ,
  unselected ,
  selected ,
  onSelectFilter ,
  onDeselectFilter ,
  Sortable ,
  SubmissionsSorter ,
  submissionsSorter ,
  filterPlaceholder ,
  reducer ,
  updateStudentSubmissions ,
  getStudentSubmissions ,
  showSubmissionStatus ,
  submissionCardClasses ,
  showSubmission ,
  showSubmissions ,
  make$1 as make,
  
}
/* sortBy Not a pure module */
