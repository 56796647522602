// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as UserProxy from "../../../shared/types/UserProxy.bs.js";

function user(t) {
  return t.user;
}

function assignedAt(t) {
  return t.assignedAt;
}

function makeFromJs(reviewerInfo) {
  return {
          user: UserProxy.makeFromJs(reviewerInfo.user),
          assignedAt: DateFns.decodeISO(reviewerInfo.assignedAt)
        };
}

function setReviewer(reviewer) {
  return {
          user: reviewer,
          assignedAt: new Date()
        };
}

export {
  user ,
  assignedAt ,
  makeFromJs ,
  setReviewer ,
  
}
/* DateFns Not a pure module */
