// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as GraphqlQuery from "../shared/utils/GraphqlQuery.bs.js";
import PermanentDeleteSvg from "./images/permanent-delete.svg";

var permanentDeleteIcon = PermanentDeleteSvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.UsersDeleteAccount";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var partial_arg$1 = "shared";

function ts(param, param$1, param$2) {
  return I18n.t(partial_arg$1, param, param$1, param$2);
}

var Raw = {};

var query = "mutation DeleteAccountMutation($token: String!)  {\ndeleteAccount(token: $token)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.deleteAccount;
  return {
          deleteAccount: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.deleteAccount;
  var value$2 = value$1.success;
  var deleteAccount = {
    success: value$2
  };
  return {
          deleteAccount: deleteAccount
        };
}

function serializeVariables(inp) {
  return {
          token: inp.token
        };
}

function makeVariables(token, param) {
  return {
          token: token
        };
}

var DeleteAccountQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var DeleteAccountQuery_Graphql_error = include.Graphql_error;

var DeleteAccountQuery_decodeNotification = include.decodeNotification;

var DeleteAccountQuery_decodeObject = include.decodeObject;

var DeleteAccountQuery_decodeNotifications = include.decodeNotifications;

var DeleteAccountQuery_decodeErrors = include.decodeErrors;

var DeleteAccountQuery_flashNotifications = include.flashNotifications;

var DeleteAccountQuery_sendQuery = include.sendQuery;

var DeleteAccountQuery_query = include.query;

var DeleteAccountQuery_make = include.make;

var DeleteAccountQuery = {
  DeleteAccountQuery_inner: DeleteAccountQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: DeleteAccountQuery_Graphql_error,
  decodeNotification: DeleteAccountQuery_decodeNotification,
  decodeObject: DeleteAccountQuery_decodeObject,
  decodeNotifications: DeleteAccountQuery_decodeNotifications,
  decodeErrors: DeleteAccountQuery_decodeErrors,
  flashNotifications: DeleteAccountQuery_flashNotifications,
  sendQuery: DeleteAccountQuery_sendQuery,
  query: DeleteAccountQuery_query,
  $$fetch: $$fetch,
  make: DeleteAccountQuery_make
};

function deleteAccount(token, setState, $$event) {
  $$event.preventDefault();
  Curry._1(setState, (function (param) {
          return /* Deleting */1;
        }));
  Curry._3($$fetch, undefined, undefined, {
            token: token
          }).then(function (result) {
          if (result.deleteAccount.success) {
            Curry._1(setState, (function (param) {
                    return /* Deleted */2;
                  }));
            setTimeout((function (param) {
                    return DomUtils.redirect("/users/sign_out");
                  }), 5000);
          }
          return Promise.resolve(undefined);
        }).catch(function (param) {
        return Promise.resolve(undefined);
      });
  
}

function UsersDeleteAccount(Props) {
  var token = Props.token;
  var match = React.useState(function () {
        return /* Waiting */0;
      });
  var setState = match[1];
  var tmp;
  switch (match[0]) {
    case /* Waiting */0 :
        tmp = React.createElement("div", {
              className: "flex flex-col items-center justify-center text-center max-w-sm mx-auto"
            }, React.createElement("h3", undefined, t(undefined, undefined, "head")), React.createElement("p", {
                  className: "mt-1"
                }, t(undefined, undefined, "click_button_below")), React.createElement("div", {
                  className: "flex mt-4 justify-center"
                }, React.createElement("a", {
                      className: "btn btn-default mr-2",
                      href: "/dashboard"
                    }, t(undefined, undefined, "cancel")), React.createElement("button", {
                      className: "btn btn-danger",
                      onClick: (function (param) {
                          return deleteAccount(token, setState, param);
                        })
                    }, t(undefined, undefined, "delete_account"))));
        break;
    case /* Deleting */1 :
        tmp = React.createElement("div", {
              className: "text-center max-w-sm mx-auto font-semibold text-red-600"
            }, React.createElement("p", undefined, t(undefined, undefined, "please_wait")), React.createElement("i", {
                  className: "my-3 text-3xl fa fa-spinner fa-pulse"
                }), React.createElement("p", undefined, t(undefined, undefined, "queuing_deletion")));
        break;
    case /* Deleted */2 :
        tmp = React.createElement("div", {
              className: "text-center max-w-sm mx-auto font-semibold text-red-600"
            }, React.createElement("p", undefined, t(undefined, undefined, "deletion_progresss")), React.createElement("i", {
                  className: "my-3 text-3xl fa fa-spinner fa-pulse"
                }), React.createElement("p", undefined, t(undefined, undefined, "signed_out_notified")));
        break;
    
  }
  return React.createElement("div", {
              className: "m-6"
            }, React.createElement("div", {
                  className: "w-64 h-64 mx-auto"
                }, React.createElement("img", {
                      className: "object-contain mx-auto",
                      src: permanentDeleteIcon
                    })), tmp);
}

var make = UsersDeleteAccount;

export {
  permanentDeleteIcon ,
  str ,
  t ,
  ts ,
  DeleteAccountQuery ,
  deleteAccount ,
  make ,
  
}
/* permanentDeleteIcon Not a pure module */
