// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";
import * as CoursesReview__ReviewChecklistResult from "./CoursesReview__ReviewChecklistResult.bs.js";

function title(t) {
  return t.title;
}

function result(t) {
  return t.result;
}

function make(title, result) {
  return {
          title: title,
          result: result
        };
}

function makeFromJs(data) {
  return data.map(function (rc) {
              return {
                      title: rc.title,
                      result: CoursesReview__ReviewChecklistResult.makeFromJs(rc.result)
                    };
            });
}

function empty(param) {
  return [{
            title: "",
            result: [CoursesReview__ReviewChecklistResult.empty(undefined)]
          }];
}

function emptyTemplate(param) {
  return [{
            title: "Default checklist",
            result: CoursesReview__ReviewChecklistResult.emptyTemplate(undefined)
          }];
}

function updateTitle(title, t) {
  return {
          title: title,
          result: t.result
        };
}

function updateChecklist(result, t) {
  return {
          title: t.title,
          result: result
        };
}

function replace(t, itemIndex, result) {
  return $$Array.mapi((function (index, item) {
                if (index === itemIndex) {
                  return t;
                } else {
                  return item;
                }
              }), result);
}

function appendEmptyChecklistItem(t) {
  return {
          title: t.title,
          result: $$Array.append(t.result, [CoursesReview__ReviewChecklistResult.empty(undefined)])
        };
}

function moveResultItemUp(index, t) {
  return {
          title: t.title,
          result: ArrayUtils.swapUp(index, t.result)
        };
}

function moveResultItemDown(index, t) {
  return {
          title: t.title,
          result: ArrayUtils.swapDown(index, t.result)
        };
}

function deleteResultItem(index, t) {
  return {
          title: t.title,
          result: t.result.filter(function (_el, i) {
                return i !== index;
              })
        };
}

function trim(t) {
  return {
          title: $$String.trim(t.title),
          result: $$Array.map(CoursesReview__ReviewChecklistResult.trim, t.result)
        };
}

function encode(t) {
  return Json_encode.object_({
              hd: [
                "title",
                t.title
              ],
              tl: {
                hd: [
                  "result",
                  Json_encode.array(CoursesReview__ReviewChecklistResult.encode, t.result)
                ],
                tl: /* [] */0
              }
            });
}

function encodeArray(checklist) {
  return Json_encode.array(encode, checklist);
}

export {
  title ,
  result ,
  make ,
  makeFromJs ,
  empty ,
  emptyTemplate ,
  updateTitle ,
  updateChecklist ,
  replace ,
  appendEmptyChecklistItem ,
  moveResultItemUp ,
  moveResultItemDown ,
  deleteResultItem ,
  trim ,
  encode ,
  encodeArray ,
  
}
/* ArrayUtils Not a pure module */
