// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

import "./Radio.css"
;

function str(prim) {
  return prim;
}

function Radio(Props) {
  var id = Props.id;
  var label = Props.label;
  var onChange = Props.onChange;
  var checkedOpt = Props.checked;
  var checked = checkedOpt !== undefined ? checkedOpt : false;
  return React.createElement("div", {
              className: "relative"
            }, React.createElement("input", {
                  className: "w-0 h-0 absolute radio-input focus:outline-none",
                  id: id,
                  checked: checked,
                  type: "radio",
                  onChange: onChange
                }), React.createElement("label", {
                  className: "radio-label flex items-center",
                  htmlFor: id
                }, React.createElement("span", undefined, React.createElement("svg", {
                          height: "14px",
                          width: "14px",
                          viewBox: "0 0 14 14"
                        }, React.createElement("circle", {
                              cx: "8",
                              cy: "8",
                              fill: "white",
                              r: "3"
                            }))), React.createElement("span", {
                      className: "text-sm flex-1 font-semibold leading-loose"
                    }, label)));
}

var make = Radio;

export {
  str ,
  make ,
  
}
/*  Not a pure module */
