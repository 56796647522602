// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as Spread from "../../../shared/components/Spread.bs.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as Checkbox from "../../../shared/components/Checkbox.bs.js";
import * as DateTime from "../../../shared/utils/DateTime.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as CoursesReview__OverlaySubmission from "../types/CoursesReview__OverlaySubmission.bs.js";
import * as CoursesReview__SubmissionDetails from "../types/CoursesReview__SubmissionDetails.bs.js";
import * as CoursesReview__ReviewChecklistItem from "../types/CoursesReview__ReviewChecklistItem.bs.js";
import * as CoursesReview__ReviewChecklistResult from "../types/CoursesReview__ReviewChecklistResult.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesReview__ChecklistShow";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function selectChecklist(itemIndex, resultIndex, setSelecton) {
  return Curry._1(setSelecton, (function (selection) {
                return selection.concat([{
                              itemIndex: itemIndex,
                              resultIndex: resultIndex
                            }]);
              }));
}

function unSelectChecklist(itemIndex, resultIndex, setSelecton) {
  return Curry._1(setSelecton, (function (selection) {
                return selection.filter(function (item) {
                            return !(item.itemIndex === itemIndex && item.resultIndex === resultIndex);
                          });
              }));
}

function checkboxOnChange(itemIndex, resultIndex, setSelecton, $$event) {
  if ($$event.target.checked) {
    return selectChecklist(itemIndex, resultIndex, setSelecton);
  } else {
    return unSelectChecklist(itemIndex, resultIndex, setSelecton);
  }
}

function generateFeedback(checklist, selection, feedback, setSelecton, updateFeedbackCB) {
  var newFeedback = feedback + ((
      $$String.trim(feedback) === "" ? "" : "\n\n"
    ) + ArrayUtils.flattenV2(checklist.map(function (reviewChecklistItem, i) {
                var resultIndexList = selection.filter(function (selectionItem) {
                        return selectionItem.itemIndex === i;
                      }).map(function (item) {
                      return item.resultIndex;
                    });
                return ArrayUtils.flattenV2(CoursesReview__ReviewChecklistItem.result(reviewChecklistItem).map(function (resultItem, index) {
                                if (!resultIndexList.some(function (i) {
                                        return i === index;
                                      })) {
                                  return [];
                                }
                                var feedback = CoursesReview__ReviewChecklistResult.feedback(resultItem);
                                if (feedback !== undefined) {
                                  return [feedback];
                                } else {
                                  return [];
                                }
                              }));
              })).join("\n\n"));
  Curry._1(setSelecton, (function (param) {
          return [];
        }));
  return Curry._1(updateFeedbackCB, newFeedback);
}

function checklistItemCheckedClasses(itemIndex, selection) {
  return "absolute w-1 inset-0 rounded-r-md " + (
          ArrayUtils.isNotEmpty(selection.filter(function (s) {
                    return s.itemIndex === itemIndex;
                  })) ? "bg-green-400" : "bg-gray-500"
        );
}

function feedbackGeneratable(submissionDetails, overlaySubmission) {
  if (Belt_Option.isSome(CoursesReview__SubmissionDetails.reviewer(submissionDetails))) {
    return true;
  } else {
    return Belt_Option.isSome(CoursesReview__OverlaySubmission.evaluatedAt(overlaySubmission));
  }
}

function checklistItemChecked(itemIndex, resultIndex, selection) {
  return ArrayUtils.isNotEmpty(selection.filter(function (s) {
                  if (s.itemIndex === itemIndex) {
                    return s.resultIndex === resultIndex;
                  } else {
                    return false;
                  }
                }));
}

function updateChecklistResultFeedback(itemIndex, resultIndex, feedback, reviewChecklistItem, resultItem, setChecklist) {
  var newReviewChecklistItem = CoursesReview__ReviewChecklistItem.updateChecklist(CoursesReview__ReviewChecklistResult.updateFeedback(feedback, resultItem, resultIndex, CoursesReview__ReviewChecklistItem.result(reviewChecklistItem)), reviewChecklistItem);
  return Curry._1(setChecklist, (function (checklist) {
                return CoursesReview__ReviewChecklistItem.replace(newReviewChecklistItem, itemIndex, checklist);
              }));
}

function generateFeedbackButton(checklist, selection, feedback, setSelecton, updateFeedbackCB) {
  return React.createElement("button", {
              className: "btn btn-primary w-full md:w-auto",
              disabled: ArrayUtils.isEmpty(selection),
              onClick: (function (param) {
                  return generateFeedback(checklist, selection, feedback, setSelecton, updateFeedbackCB);
                })
            }, t(undefined, undefined, "generate_feedback_button"));
}

function CoursesReview__ChecklistShow(Props) {
  var reviewChecklist = Props.reviewChecklist;
  var feedback = Props.feedback;
  var updateFeedbackCB = Props.updateFeedbackCB;
  var showEditorCB = Props.showEditorCB;
  var cancelCB = Props.cancelCB;
  var overlaySubmission = Props.overlaySubmission;
  var submissionDetails = Props.submissionDetails;
  var match = React.useState(function () {
        return reviewChecklist;
      });
  var setChecklist = match[1];
  var checklist = match[0];
  var match$1 = React.useState(function () {
        return [];
      });
  var setSelecton = match$1[1];
  var selection = match$1[0];
  var match$2 = React.useState(function () {
        return DateTime.randomId(undefined) + "-review-checkbox-";
      });
  var id = match$2[0];
  return React.createElement("div", undefined, React.createElement("div", {
                  className: "flex items-center px-4 md:px-6 py-3 bg-white border-b sticky top-0 z-50 h-16"
                }, React.createElement("div", {
                      className: "flex flex-1 items-center justify-between"
                    }, React.createElement("button", {
                          className: "btn btn-subtle focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 transition",
                          onClick: cancelCB
                        }, React.createElement(FaIcon.make, {
                              classes: "fas fa-arrow-left text-gray-500"
                            }), React.createElement("p", {
                              className: "pl-2"
                            }, "Back to Review")))), React.createElement("div", {
                  className: "p-4 md:px-6 pb-0"
                }, React.createElement("div", {
                      className: "flex items-end justify-between"
                    }, React.createElement("h5", {
                          className: "font-semibold flex items-center tracking-wide text-sm"
                        }, t(undefined, undefined, "review_checklist")), React.createElement("button", {
                          className: "btn btn-small btn-default",
                          onClick: (function (param) {
                              return Curry._1(showEditorCB, undefined);
                            })
                        }, React.createElement("i", {
                              className: "far fa-edit"
                            }), React.createElement("div", {
                              className: "ml-2"
                            }, t(undefined, undefined, "edit_checklist_button")))), React.createElement("div", {
                      className: "border bg-white rounded-lg py-2 md:py-4 mt-2 space-y-8"
                    }, checklist.map(function (reviewChecklistItem, itemIndex) {
                          return React.createElement(Spread.make, {
                                      props: {
                                        "data-checklist-item": String(itemIndex)
                                      },
                                      children: React.createElement("div", undefined, React.createElement("h4", {
                                                className: "relative text-sm font-semibold mt-2 md:mt-0 px-6 w-full md:w-4/5"
                                              }, React.createElement("div", {
                                                    className: checklistItemCheckedClasses(itemIndex, selection)
                                                  }), CoursesReview__ReviewChecklistItem.title(reviewChecklistItem)), React.createElement("div", {
                                                className: "space-y-3 pt-3"
                                              }, CoursesReview__ReviewChecklistItem.result(reviewChecklistItem).map(function (checklistItem, resultIndex) {
                                                    var isSelected = Belt_Option.isSome(Caml_option.undefined_to_opt(selection.find(function (s) {
                                                                  if (s.itemIndex === itemIndex) {
                                                                    return s.resultIndex === resultIndex;
                                                                  } else {
                                                                    return false;
                                                                  }
                                                                })));
                                                    return React.createElement(Spread.make, {
                                                                props: {
                                                                  "data-result-item": String(resultIndex)
                                                                },
                                                                children: React.createElement("div", {
                                                                      className: "px-6"
                                                                    }, React.createElement(Checkbox.make, {
                                                                          id: id + (String(itemIndex) + String(resultIndex)),
                                                                          onChange: (function (param) {
                                                                              return checkboxOnChange(itemIndex, resultIndex, setSelecton, param);
                                                                            }),
                                                                          checked: checklistItemChecked(itemIndex, resultIndex, selection),
                                                                          label: CoursesReview__ReviewChecklistResult.title(checklistItem),
                                                                          disabled: !feedbackGeneratable(submissionDetails, overlaySubmission)
                                                                        }), ReactUtils.nullUnless(React.createElement("div", {
                                                                              className: "pl-7 pt-2"
                                                                            }, React.createElement("textarea", {
                                                                                  className: "appearance-none border border-gray-300 bg-white rounded-b text-sm align-top py-2 px-4 leading-relaxed w-full focus:outline-none focus:bg-white focus:border-primary-300",
                                                                                  id: "result_" + (String(resultIndex) + "_feedback"),
                                                                                  cols: 33,
                                                                                  disabled: !feedbackGeneratable(submissionDetails, overlaySubmission),
                                                                                  placeholder: t(undefined, undefined, "feedback_placeholder"),
                                                                                  rows: 4,
                                                                                  type: "text",
                                                                                  value: Belt_Option.getWithDefault(CoursesReview__ReviewChecklistResult.feedback(checklistItem), ""),
                                                                                  onChange: (function ($$event) {
                                                                                      return updateChecklistResultFeedback(itemIndex, resultIndex, $$event.target.value, reviewChecklistItem, checklistItem, setChecklist);
                                                                                    })
                                                                                })), isSelected || !feedbackGeneratable(submissionDetails, overlaySubmission))),
                                                                key: String(itemIndex) + String(resultIndex)
                                                              });
                                                  }))),
                                      key: String(itemIndex)
                                    });
                        }))), React.createElement("div", {
                  className: "flex justify-end border-t bg-gray-50 opacity-90 sticky bottom-0 px-4 md:px-6 py-2 md:py-4 mt-4"
                }, feedbackGeneratable(submissionDetails, overlaySubmission) ? generateFeedbackButton(checklist, selection, feedback, setSelecton, updateFeedbackCB) : null));
}

var make = CoursesReview__ChecklistShow;

export {
  str ,
  t ,
  selectChecklist ,
  unSelectChecklist ,
  checkboxOnChange ,
  generateFeedback ,
  checklistItemCheckedClasses ,
  feedbackGeneratable ,
  checklistItemChecked ,
  updateChecklistResultFeedback ,
  generateFeedbackButton ,
  make ,
  
}
/* I18n Not a pure module */
