// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as AppRouter from "../layouts/app_router/AppRouter.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as AppRouter__User from "../layouts/app_router/types/AppRouter__User.bs.js";
import * as AppRouter__Course from "../layouts/app_router/types/AppRouter__Course.bs.js";
import * as AppRouter__School from "../layouts/app_router/types/AppRouter__School.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("courses", (function (param) {
                  return Json_decode.array(AppRouter__Course.decode, param);
                }), json),
          Json_decode.field("currentUser", (function (param) {
                  return Json_decode.optional(AppRouter__User.decode, param);
                }), json),
          Json_decode.field("school", AppRouter__School.decode, json)
        ];
}

Psj.matchPaths(undefined, [
      "courses/:id/review",
      "submissions/:id/review",
      "courses/:id/students",
      "students/:id/report"
    ], (function (param) {
        var match = decodeProps(DomUtils.parseJSONTag("app-router-data", undefined));
        var root = document.querySelector("#app-router");
        if (!(root == null)) {
          ReactDom.render(React.createElement(AppRouter.make, {
                    school: match[2],
                    courses: match[0],
                    currentUser: match[1]
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
