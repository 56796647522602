// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../shared/utils/I18n.bs.js";
import * as Link from "../../shared/components/Link.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "../../../../node_modules/rescript/lib/es6/string.js";
import * as LoadingV2 from "../../shared/types/LoadingV2.bs.js";
import * as UserProxy from "../../shared/types/UserProxy.bs.js";
import * as ArrayUtils from "../../shared/utils/ArrayUtils.bs.js";
import * as Caml_array from "../../../../node_modules/rescript/lib/es6/caml_array.js";
import * as Pagination from "../../shared/utils/Pagination.bs.js";
import * as ReactUtils from "../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../../shared/utils/OptionUtils.bs.js";
import * as SorterWhite from "../../shared/components/SorterWhite.bs.js";
import * as StringUtils from "../../shared/utils/StringUtils.bs.js";
import * as GraphqlQuery from "../../shared/utils/GraphqlQuery.bs.js";
import * as ReactHelmet from "react-helmet";
import * as Shared__Level from "../../shared/types/Shared__Level.bs.js";
import * as LoadingSpinner from "../../shared/components/LoadingSpinner.bs.js";
import * as SkeletonLoading from "../../shared/components/SkeletonLoading.bs.js";
import * as AppRouter__Course from "../../layouts/app_router/types/AppRouter__Course.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesReview__Filter from "./types/CoursesReview__Filter.bs.js";
import * as MultiselectDropdownWhite from "../../packages/multiselect-dropdown/src/components/MultiselectDropdownWhite.bs.js";
import * as CoursesReview__TargetInfo from "./types/CoursesReview__TargetInfo.bs.js";
import * as CoursesReview__SubmissionCard from "./components/CoursesReview__SubmissionCard.bs.js";
import * as CoursesReview__IndexSubmission from "./types/CoursesReview__IndexSubmission.bs.js";

function str(prim) {
  return prim;
}

import "./CoursesReview__Root.css"
;

var partial_arg = "components.CoursesReview__Root";

function tc(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var Item = {};

var PagedSubmission = Pagination.Make(Item);

function coachFilterTranslationKey(coachFilter) {
  switch (coachFilter) {
    case /* Assigned */0 :
        return "assigned_to";
    case /* Personal */1 :
        return "personal_coach";
    case /* ReviewedBy */2 :
        return "reviewed_by";
    
  }
}

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* UnsetSearchString */0 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: "",
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: state.filterLoading,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* BeginLoadingMore */1 :
          return {
                  loading: /* LoadingMore */0,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: state.filterLoading,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* BeginReloading */2 :
          return {
                  loading: LoadingV2.setReloading(state.loading),
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: state.filterLoading,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* SetLevelLoading */3 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: true,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: /* Loading */1,
                  coachesLoaded: state.coachesLoaded
                };
      case /* SetTargetLoading */4 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: true,
                  filterLoader: state.filterLoader,
                  targetsLoaded: /* Loading */1,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* SetCoachLoading */5 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: true,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: /* Loading */1
                };
      case /* ClearLoader */6 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: state.filterLoading,
                  filterLoader: undefined,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* UpdateFilterInput */0 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: action._0,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: state.filterLoading,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* LoadSubmissions */1 :
          var newTopics = action._2;
          var match = state.loading;
          var updatedTopics = match ? newTopics : Curry._1(PagedSubmission.toArray, state.submissions).concat(newTopics);
          return {
                  loading: LoadingV2.setNotLoading(state.loading),
                  submissions: Curry._3(PagedSubmission.make, updatedTopics, action._1, action._0),
                  levels: ArrayUtils.isEmpty(state.levels) ? Belt_Option.mapWithDefault(action._5, [], (function (t) {
                            return [t];
                          })) : state.levels,
                  coaches: ArrayUtils.isEmpty(state.coaches) ? action._6 : state.coaches,
                  targets: ArrayUtils.isEmpty(state.targets) ? Belt_Option.mapWithDefault(action._4, [], (function (t) {
                            return [t];
                          })) : state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: action._3,
                  filterLoading: state.filterLoading,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* LoadLevels */2 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: action._0,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: false,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: /* Loaded */2,
                  coachesLoaded: state.coachesLoaded
                };
      case /* LoadCoaches */3 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: action._0,
                  targets: state.targets,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: false,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: /* Loaded */2
                };
      case /* LoadTargets */4 :
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: action._0,
                  filterInput: state.filterInput,
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: false,
                  filterLoader: state.filterLoader,
                  targetsLoaded: /* Loaded */2,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      case /* SetLoader */5 :
          var loader = action._0;
          return {
                  loading: state.loading,
                  submissions: state.submissions,
                  levels: state.levels,
                  coaches: state.coaches,
                  targets: state.targets,
                  filterInput: typeof loader === "number" ? (
                      loader !== 0 ? tc(undefined, undefined, "filter_input.target") : tc(undefined, undefined, "filter_input.level")
                    ) : tc(undefined, undefined, "filter_input." + coachFilterTranslationKey(loader._0)),
                  totalEntriesCount: state.totalEntriesCount,
                  filterLoading: state.filterLoading,
                  filterLoader: state.filterLoader,
                  targetsLoaded: state.targetsLoaded,
                  levelsLoaded: state.levelsLoaded,
                  coachesLoaded: state.coachesLoaded
                };
      
    }
  }
}

function updateParams(filter) {
  return RescriptReactRouter.push("?" + CoursesReview__Filter.toQueryString(filter));
}

var Raw = {};

var query = "query SubmissionsQuery($courseId: ID!, $search: String, $targetId: ID, $status: SubmissionStatus, $sortDirection: SortDirection!, $sortCriterion: SubmissionSortCriterion!, $levelId: ID, $personalCoachId: ID, $assignedCoachId: ID, $reviewingCoachId: ID, $includeInactive: Boolean, $coachIds: [ID!], $after: String)  {\nsubmissions(courseId: $courseId, search: $search, targetId: $targetId, status: $status, sortDirection: $sortDirection, sortCriterion: $sortCriterion, levelId: $levelId, personalCoachId: $personalCoachId, assignedCoachId: $assignedCoachId, reviewingCoachId: $reviewingCoachId, includeInactive: $includeInactive, first: 20, after: $after)  {\nnodes  {\nid  \ntitle  \nuserNames  \nevaluatedAt  \npassedAt  \nfeedbackSent  \ncreatedAt  \nteamName  \nlevelNumber  \nreviewer  {\nname  \nassignedAt  \n}\n\n}\n\npageInfo  {\nendCursor  \nhasNextPage  \n}\n\ntotalCount  \n}\n\nlevel(levelId: $levelId, courseId: $courseId)  {\nid  \nname  \nnumber  \n}\n\ncoaches(coachIds: $coachIds, courseId: $courseId)  {\n...UserProxyFragment   \n}\n\ntargetInfo(targetId: $targetId, courseId: $courseId)  {\nid  \ntitle  \n}\n\n}\n" + UserProxy.Fragment.query;

function parse(value) {
  var value$1 = value.submissions;
  var value$2 = value$1.nodes;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.endCursor;
  var value$5 = value.level;
  var value$6 = value.coaches;
  var value$7 = value.targetInfo;
  return {
          submissions: {
            nodes: value$2.map(function (value) {
                  var value$1 = value.evaluatedAt;
                  var value$2 = value.passedAt;
                  var value$3 = value.teamName;
                  var value$4 = value.reviewer;
                  return {
                          id: value.id,
                          title: value.title,
                          userNames: value.userNames,
                          evaluatedAt: !(value$1 == null) ? Caml_option.some(value$1) : undefined,
                          passedAt: !(value$2 == null) ? Caml_option.some(value$2) : undefined,
                          feedbackSent: value.feedbackSent,
                          createdAt: value.createdAt,
                          teamName: !(value$3 == null) ? value$3 : undefined,
                          levelNumber: value.levelNumber,
                          reviewer: !(value$4 == null) ? ({
                                name: value$4.name,
                                assignedAt: value$4.assignedAt
                              }) : undefined
                        };
                }),
            pageInfo: {
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: value$3.hasNextPage
            },
            totalCount: value$1.totalCount
          },
          level: !(value$5 == null) ? ({
                id: value$5.id,
                name: value$5.name,
                number: value$5.number
              }) : undefined,
          coaches: value$6.map(function (value) {
                return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
              }),
          targetInfo: !(value$7 == null) ? ({
                id: value$7.id,
                title: value$7.title
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.targetInfo;
  var targetInfo;
  if (value$1 !== undefined) {
    var value$2 = value$1.title;
    var value$3 = value$1.id;
    targetInfo = {
      id: value$3,
      title: value$2
    };
  } else {
    targetInfo = null;
  }
  var value$4 = value.coaches;
  var coaches = value$4.map(function (value) {
        return UserProxy.Fragment.serialize(value);
      });
  var value$5 = value.level;
  var level;
  if (value$5 !== undefined) {
    var value$6 = value$5.number;
    var value$7 = value$5.name;
    var value$8 = value$5.id;
    level = {
      id: value$8,
      name: value$7,
      number: value$6
    };
  } else {
    level = null;
  }
  var value$9 = value.submissions;
  var value$10 = value$9.totalCount;
  var value$11 = value$9.pageInfo;
  var value$12 = value$11.hasNextPage;
  var value$13 = value$11.endCursor;
  var endCursor = value$13 !== undefined ? value$13 : null;
  var pageInfo = {
    endCursor: endCursor,
    hasNextPage: value$12
  };
  var value$14 = value$9.nodes;
  var nodes = value$14.map(function (value) {
        var value$1 = value.reviewer;
        var reviewer;
        if (value$1 !== undefined) {
          var value$2 = value$1.assignedAt;
          var value$3 = value$1.name;
          reviewer = {
            name: value$3,
            assignedAt: value$2
          };
        } else {
          reviewer = null;
        }
        var value$4 = value.levelNumber;
        var value$5 = value.teamName;
        var teamName = value$5 !== undefined ? value$5 : null;
        var value$6 = value.createdAt;
        var value$7 = value.feedbackSent;
        var value$8 = value.passedAt;
        var passedAt = value$8 !== undefined ? Caml_option.valFromOption(value$8) : null;
        var value$9 = value.evaluatedAt;
        var evaluatedAt = value$9 !== undefined ? Caml_option.valFromOption(value$9) : null;
        var value$10 = value.userNames;
        var value$11 = value.title;
        var value$12 = value.id;
        return {
                id: value$12,
                title: value$11,
                userNames: value$10,
                evaluatedAt: evaluatedAt,
                passedAt: passedAt,
                feedbackSent: value$7,
                createdAt: value$6,
                teamName: teamName,
                levelNumber: value$4,
                reviewer: reviewer
              };
      });
  var submissions = {
    nodes: nodes,
    pageInfo: pageInfo,
    totalCount: value$10
  };
  return {
          submissions: submissions,
          level: level,
          coaches: coaches,
          targetInfo: targetInfo
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.targetId;
  var a$2 = inp.status;
  var a$3 = inp.sortDirection;
  var a$4 = inp.sortCriterion;
  var a$5 = inp.levelId;
  var a$6 = inp.personalCoachId;
  var a$7 = inp.assignedCoachId;
  var a$8 = inp.reviewingCoachId;
  var a$9 = inp.includeInactive;
  var a$10 = inp.coachIds;
  var a$11 = inp.after;
  return {
          courseId: inp.courseId,
          search: a !== undefined ? a : undefined,
          targetId: a$1 !== undefined ? a$1 : undefined,
          status: a$2 !== undefined ? (
              a$2 === "Reviewed" ? "Reviewed" : "Pending"
            ) : undefined,
          sortDirection: a$3 === "Descending" ? "Descending" : "Ascending",
          sortCriterion: a$4 === "EvaluatedAt" ? "EvaluatedAt" : "SubmittedAt",
          levelId: a$5 !== undefined ? a$5 : undefined,
          personalCoachId: a$6 !== undefined ? a$6 : undefined,
          assignedCoachId: a$7 !== undefined ? a$7 : undefined,
          reviewingCoachId: a$8 !== undefined ? a$8 : undefined,
          includeInactive: a$9 !== undefined ? a$9 : undefined,
          coachIds: a$10 !== undefined ? a$10.map(function (b) {
                  return b;
                }) : undefined,
          after: a$11 !== undefined ? a$11 : undefined
        };
}

function makeVariables(courseId, search, targetId, status, sortDirection, sortCriterion, levelId, personalCoachId, assignedCoachId, reviewingCoachId, includeInactive, coachIds, after, param) {
  return {
          courseId: courseId,
          search: search,
          targetId: targetId,
          status: status,
          sortDirection: sortDirection,
          sortCriterion: sortCriterion,
          levelId: levelId,
          personalCoachId: personalCoachId,
          assignedCoachId: assignedCoachId,
          reviewingCoachId: reviewingCoachId,
          includeInactive: includeInactive,
          coachIds: coachIds,
          after: after
        };
}

var SubmissionsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var SubmissionsQuery_Graphql_error = include.Graphql_error;

var SubmissionsQuery_decodeNotification = include.decodeNotification;

var SubmissionsQuery_decodeObject = include.decodeObject;

var SubmissionsQuery_decodeNotifications = include.decodeNotifications;

var SubmissionsQuery_decodeErrors = include.decodeErrors;

var SubmissionsQuery_flashNotifications = include.flashNotifications;

var SubmissionsQuery_sendQuery = include.sendQuery;

var SubmissionsQuery_query = include.query;

var SubmissionsQuery_fetch = include.$$fetch;

var SubmissionsQuery = {
  SubmissionsQuery_inner: SubmissionsQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: SubmissionsQuery_Graphql_error,
  decodeNotification: SubmissionsQuery_decodeNotification,
  decodeObject: SubmissionsQuery_decodeObject,
  decodeNotifications: SubmissionsQuery_decodeNotifications,
  decodeErrors: SubmissionsQuery_decodeErrors,
  flashNotifications: SubmissionsQuery_flashNotifications,
  sendQuery: SubmissionsQuery_sendQuery,
  query: SubmissionsQuery_query,
  $$fetch: SubmissionsQuery_fetch,
  make: make
};

var Raw$1 = {};

var query$1 = "query LevelsQuery($courseId: ID!)  {\nlevels(courseId: $courseId)  {\nid  \nname  \nnumber  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.levels;
  return {
          levels: value$1.map(function (value) {
                return {
                        id: value.id,
                        name: value.name,
                        number: value.number
                      };
              })
        };
}

function serialize$1(value) {
  var value$1 = value.levels;
  var levels = value$1.map(function (value) {
        var value$1 = value.number;
        var value$2 = value.name;
        var value$3 = value.id;
        return {
                id: value$3,
                name: value$2,
                number: value$1
              };
      });
  return {
          levels: levels
        };
}

function serializeVariables$1(inp) {
  return {
          courseId: inp.courseId
        };
}

function makeVariables$1(courseId, param) {
  return {
          courseId: courseId
        };
}

var LevelsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = GraphqlQuery.Extender({
      Raw: Raw$1,
      query: query$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$1 = include$1.make;

var LevelsQuery_Graphql_error = include$1.Graphql_error;

var LevelsQuery_decodeNotification = include$1.decodeNotification;

var LevelsQuery_decodeObject = include$1.decodeObject;

var LevelsQuery_decodeNotifications = include$1.decodeNotifications;

var LevelsQuery_decodeErrors = include$1.decodeErrors;

var LevelsQuery_flashNotifications = include$1.flashNotifications;

var LevelsQuery_sendQuery = include$1.sendQuery;

var LevelsQuery_query = include$1.query;

var LevelsQuery_fetch = include$1.$$fetch;

var LevelsQuery = {
  LevelsQuery_inner: LevelsQuery_inner,
  Raw: Raw$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  Graphql_error: LevelsQuery_Graphql_error,
  decodeNotification: LevelsQuery_decodeNotification,
  decodeObject: LevelsQuery_decodeObject,
  decodeNotifications: LevelsQuery_decodeNotifications,
  decodeErrors: LevelsQuery_decodeErrors,
  flashNotifications: LevelsQuery_flashNotifications,
  sendQuery: LevelsQuery_sendQuery,
  query: LevelsQuery_query,
  $$fetch: LevelsQuery_fetch,
  make: make$1
};

var Raw$2 = {};

var query$2 = "query CoachesQuery($courseId: ID!)  {\ncoaches(courseId: $courseId)  {\n...UserProxyFragment   \n}\n\n}\n" + UserProxy.Fragment.query;

function parse$2(value) {
  var value$1 = value.coaches;
  return {
          coaches: value$1.map(function (value) {
                return UserProxy.Fragment.verifyArgsAndParse("UserProxyFragment", value);
              })
        };
}

function serialize$2(value) {
  var value$1 = value.coaches;
  var coaches = value$1.map(function (value) {
        return UserProxy.Fragment.serialize(value);
      });
  return {
          coaches: coaches
        };
}

function serializeVariables$2(inp) {
  return {
          courseId: inp.courseId
        };
}

function makeVariables$2(courseId, param) {
  return {
          courseId: courseId
        };
}

var CoachesQuery_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = GraphqlQuery.Extender({
      Raw: Raw$2,
      query: query$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$2 = include$2.make;

var CoachesQuery_Graphql_error = include$2.Graphql_error;

var CoachesQuery_decodeNotification = include$2.decodeNotification;

var CoachesQuery_decodeObject = include$2.decodeObject;

var CoachesQuery_decodeNotifications = include$2.decodeNotifications;

var CoachesQuery_decodeErrors = include$2.decodeErrors;

var CoachesQuery_flashNotifications = include$2.flashNotifications;

var CoachesQuery_sendQuery = include$2.sendQuery;

var CoachesQuery_query = include$2.query;

var CoachesQuery_fetch = include$2.$$fetch;

var CoachesQuery = {
  CoachesQuery_inner: CoachesQuery_inner,
  Raw: Raw$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  Graphql_error: CoachesQuery_Graphql_error,
  decodeNotification: CoachesQuery_decodeNotification,
  decodeObject: CoachesQuery_decodeObject,
  decodeNotifications: CoachesQuery_decodeNotifications,
  decodeErrors: CoachesQuery_decodeErrors,
  flashNotifications: CoachesQuery_flashNotifications,
  sendQuery: CoachesQuery_sendQuery,
  query: CoachesQuery_query,
  $$fetch: CoachesQuery_fetch,
  make: make$2
};

var Raw$3 = {};

var query$3 = "query ReviewedTargetsInfoQuery($courseId: ID!)  {\nreviewedTargetsInfo(courseId: $courseId)  {\nid  \ntitle  \n}\n\n}\n";

function parse$3(value) {
  var value$1 = value.reviewedTargetsInfo;
  return {
          reviewedTargetsInfo: value$1.map(function (value) {
                return {
                        id: value.id,
                        title: value.title
                      };
              })
        };
}

function serialize$3(value) {
  var value$1 = value.reviewedTargetsInfo;
  var reviewedTargetsInfo = value$1.map(function (value) {
        var value$1 = value.title;
        var value$2 = value.id;
        return {
                id: value$2,
                title: value$1
              };
      });
  return {
          reviewedTargetsInfo: reviewedTargetsInfo
        };
}

function serializeVariables$3(inp) {
  return {
          courseId: inp.courseId
        };
}

function makeVariables$3(courseId, param) {
  return {
          courseId: courseId
        };
}

var ReviewedTargetsInfoQuery_inner = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var include$3 = GraphqlQuery.Extender({
      Raw: Raw$3,
      query: query$3,
      parse: parse$3,
      serialize: serialize$3,
      serializeVariables: serializeVariables$3,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make$3 = include$3.make;

var ReviewedTargetsInfoQuery_Graphql_error = include$3.Graphql_error;

var ReviewedTargetsInfoQuery_decodeNotification = include$3.decodeNotification;

var ReviewedTargetsInfoQuery_decodeObject = include$3.decodeObject;

var ReviewedTargetsInfoQuery_decodeNotifications = include$3.decodeNotifications;

var ReviewedTargetsInfoQuery_decodeErrors = include$3.decodeErrors;

var ReviewedTargetsInfoQuery_flashNotifications = include$3.flashNotifications;

var ReviewedTargetsInfoQuery_sendQuery = include$3.sendQuery;

var ReviewedTargetsInfoQuery_query = include$3.query;

var ReviewedTargetsInfoQuery_fetch = include$3.$$fetch;

var ReviewedTargetsInfoQuery = {
  ReviewedTargetsInfoQuery_inner: ReviewedTargetsInfoQuery_inner,
  Raw: Raw$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3,
  Graphql_error: ReviewedTargetsInfoQuery_Graphql_error,
  decodeNotification: ReviewedTargetsInfoQuery_decodeNotification,
  decodeObject: ReviewedTargetsInfoQuery_decodeObject,
  decodeNotifications: ReviewedTargetsInfoQuery_decodeNotifications,
  decodeErrors: ReviewedTargetsInfoQuery_decodeErrors,
  flashNotifications: ReviewedTargetsInfoQuery_flashNotifications,
  sendQuery: ReviewedTargetsInfoQuery_sendQuery,
  query: ReviewedTargetsInfoQuery_query,
  $$fetch: ReviewedTargetsInfoQuery_fetch,
  make: make$3
};

function getSubmissions(send, courseId, cursor, filter) {
  var coachIds = ArrayUtils.flattenV2([
          CoursesReview__Filter.personalCoachId(filter),
          CoursesReview__Filter.assignedCoachId(filter),
          CoursesReview__Filter.reviewingCoachId(filter)
        ].map(function (__x) {
            return Belt_Option.mapWithDefault(__x, [], (function (t) {
                          return [t];
                        }));
          }));
  var variables = makeVariables(courseId, CoursesReview__Filter.nameOrEmail(filter), CoursesReview__Filter.targetId(filter), CoursesReview__Filter.tab(filter), CoursesReview__Filter.defaultDirection(filter), CoursesReview__Filter.sortCriterion(filter), CoursesReview__Filter.levelId(filter), CoursesReview__Filter.personalCoachId(filter), CoursesReview__Filter.assignedCoachId(filter), CoursesReview__Filter.reviewingCoachId(filter), CoursesReview__Filter.includeInactive(filter), coachIds, cursor, undefined);
  Curry._3(make, undefined, undefined, variables).then(function (response) {
        var target = OptionUtils.map(CoursesReview__TargetInfo.makeFromJs, response.targetInfo);
        var coaches = response.coaches.map(UserProxy.makeFromJs);
        var level = OptionUtils.map(Shared__Level.makeFromJs, response.level);
        Curry._1(send, {
              TAG: /* LoadSubmissions */1,
              _0: response.submissions.pageInfo.endCursor,
              _1: response.submissions.pageInfo.hasNextPage,
              _2: response.submissions.nodes.map(CoursesReview__IndexSubmission.makeFromJS),
              _3: response.submissions.totalCount,
              _4: target,
              _5: level,
              _6: coaches
            });
        return Promise.resolve(undefined);
      });
  
}

function getLevels(send, courseId, state) {
  if (state.levelsLoaded === /* Unloaded */0) {
    Curry._1(send, /* SetLevelLoading */3);
    Curry._3(make$1, undefined, undefined, {
            courseId: courseId
          }).then(function (response) {
          Curry._1(send, {
                TAG: /* LoadLevels */2,
                _0: response.levels.map(Shared__Level.makeFromJs)
              });
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function getCoaches(send, courseId, state) {
  if (state.coachesLoaded === /* Unloaded */0) {
    Curry._1(send, /* SetCoachLoading */5);
    Curry._3(make$2, undefined, undefined, {
            courseId: courseId
          }).then(function (response) {
          Curry._1(send, {
                TAG: /* LoadCoaches */3,
                _0: response.coaches.map(UserProxy.makeFromJs)
              });
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function getTargets(send, courseId, state) {
  if (state.targetsLoaded === /* Unloaded */0) {
    Curry._1(send, /* SetTargetLoading */4);
    Curry._3(make$3, undefined, undefined, {
            courseId: courseId
          }).then(function (response) {
          Curry._1(send, {
                TAG: /* LoadTargets */4,
                _0: response.reviewedTargetsInfo.map(CoursesReview__TargetInfo.makeFromJs)
              });
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function criterion(t) {
  if (t === "EvaluatedAt") {
    return tc(undefined, undefined, "reviewed_at");
  } else {
    return tc(undefined, undefined, "submitted_at");
  }
}

function criterionType(_t) {
  return "Number";
}

var Sortable = {
  criterion: criterion,
  criterionType: criterionType
};

var SubmissionsSorter = SorterWhite.Make(Sortable);

function submissionsSorter(filter) {
  var c = CoursesReview__Filter.tab(filter);
  var criteria = c !== undefined ? (
      c === "Reviewed" ? [
          "SubmittedAt",
          "EvaluatedAt"
        ] : ["SubmittedAt"]
    ) : ["SubmittedAt"];
  return React.createElement("div", {
              "aria-label": "Change submissions sorting",
              className: "shrink-0 md:ml-2"
            }, React.createElement("label", {
                  className: "hidden md:block text-tiny font-semibold uppercase pb-1"
                }, tc(undefined, undefined, "sort_by")), React.createElement(SubmissionsSorter.make, {
                  criteria: criteria,
                  selectedCriterion: filter.sortCriterion,
                  direction: CoursesReview__Filter.defaultDirection(filter),
                  onDirectionChange: (function (direction) {
                      return updateParams({
                                  nameOrEmail: filter.nameOrEmail,
                                  levelId: filter.levelId,
                                  personalCoachId: filter.personalCoachId,
                                  assignedCoachId: filter.assignedCoachId,
                                  reviewingCoachId: filter.reviewingCoachId,
                                  targetId: filter.targetId,
                                  sortCriterion: filter.sortCriterion,
                                  sortDirection: direction,
                                  tab: filter.tab,
                                  includeInactive: filter.includeInactive
                                });
                    }),
                  onCriterionChange: (function (sortCriterion) {
                      return updateParams({
                                  nameOrEmail: filter.nameOrEmail,
                                  levelId: filter.levelId,
                                  personalCoachId: filter.personalCoachId,
                                  assignedCoachId: filter.assignedCoachId,
                                  reviewingCoachId: filter.reviewingCoachId,
                                  targetId: filter.targetId,
                                  sortCriterion: sortCriterion,
                                  sortDirection: filter.sortDirection,
                                  tab: filter.tab,
                                  includeInactive: filter.includeInactive
                                });
                    })
                }));
}

function label(t) {
  if (typeof t === "number") {
    return tc(undefined, undefined, "include");
  }
  switch (t.TAG | 0) {
    case /* Level */0 :
        return tc(undefined, undefined, "level");
    case /* AssignedToCoach */1 :
        return tc(undefined, undefined, "assigned_to");
    case /* PersonalCoach */2 :
        return tc(undefined, undefined, "personal_coach");
    case /* ReviewedBy */3 :
        return tc(undefined, undefined, "reviewed_by");
    case /* Loader */4 :
        var l = t._0;
        if (typeof l === "number") {
          if (l !== 0) {
            return tc(undefined, undefined, "target");
          } else {
            return tc(undefined, undefined, "level");
          }
        } else {
          return tc(undefined, undefined, coachFilterTranslationKey(l._0));
        }
    case /* Target */5 :
        return tc(undefined, undefined, "target");
    case /* Status */6 :
        return tc(undefined, undefined, "status");
    case /* NameOrEmail */7 :
        return tc(undefined, undefined, "name_or_email");
    
  }
}

function value(t) {
  if (typeof t === "number") {
    return tc(undefined, undefined, "inactive_students");
  }
  switch (t.TAG | 0) {
    case /* Level */0 :
        var level = t._0;
        return String(Shared__Level.number(level)) + ", " + Shared__Level.name(level);
    case /* Loader */4 :
        var l = t._0;
        if (typeof l === "number") {
          if (l !== 0) {
            return tc(undefined, undefined, "filter_by_target");
          } else {
            return tc(undefined, undefined, "filter_by_level");
          }
        } else {
          return tc(undefined, undefined, "coach_filter_by." + coachFilterTranslationKey(l._0));
        }
    case /* Target */5 :
        return CoursesReview__TargetInfo.title(t._0);
    case /* Status */6 :
        if (t._0 === "Reviewed") {
          return tc(undefined, undefined, "reviewed");
        } else {
          return tc(undefined, undefined, "pending");
        }
    case /* NameOrEmail */7 :
        return t._0;
    default:
      var coach = t._0;
      if (UserProxy.id(coach) === t._1) {
        return tc(undefined, undefined, "me");
      } else {
        return UserProxy.name(coach);
      }
  }
}

function searchString(t) {
  if (typeof t === "number") {
    return tc(undefined, undefined, "include") + ": " + tc(undefined, undefined, "inactive_students");
  }
  switch (t.TAG | 0) {
    case /* Level */0 :
        var level = t._0;
        return tc(undefined, undefined, "search.level") + " " + String(Shared__Level.number(level)) + ", " + Shared__Level.name(level);
    case /* AssignedToCoach */1 :
        var coach = t._0;
        return tc(undefined, undefined, "search.assigned_to") + " " + (
                UserProxy.id(coach) === t._1 ? tc(undefined, undefined, "me") : UserProxy.name(coach)
              );
    case /* PersonalCoach */2 :
        var coach$1 = t._0;
        return tc(undefined, undefined, "search.personal_coach") + " " + (
                UserProxy.id(coach$1) === t._1 ? tc(undefined, undefined, "me") : UserProxy.name(coach$1)
              );
    case /* ReviewedBy */3 :
        var coach$2 = t._0;
        return tc(undefined, undefined, "search.reviewed_by") + " " + (
                UserProxy.id(coach$2) === t._1 ? tc(undefined, undefined, "me") : UserProxy.name(coach$2)
              );
    case /* Loader */4 :
        var key = t._0;
        if (typeof key === "number") {
          if (key !== 0) {
            return tc(undefined, undefined, "search.target");
          } else {
            return tc(undefined, undefined, "search.level");
          }
        } else {
          return tc(undefined, undefined, "search." + coachFilterTranslationKey(key._0));
        }
    case /* Target */5 :
        return tc(undefined, undefined, "search.target") + " " + CoursesReview__TargetInfo.title(t._0);
    case /* Status */6 :
        if (t._0 === "Reviewed") {
          return tc(undefined, undefined, "search.status") + tc(undefined, undefined, "reviewed");
        } else {
          return tc(undefined, undefined, "search.status") + tc(undefined, undefined, "pending");
        }
    case /* NameOrEmail */7 :
        return t._0;
    
  }
}

function color(t) {
  if (typeof t === "number") {
    return "gray";
  }
  switch (t.TAG | 0) {
    case /* Level */0 :
        return "blue";
    case /* AssignedToCoach */1 :
        return "purple";
    case /* PersonalCoach */2 :
        return "green";
    case /* ReviewedBy */3 :
        return "orange";
    case /* Loader */4 :
        var match = t._0;
        if (typeof match === "number") {
          if (match !== 0) {
            return "red";
          } else {
            return "blue";
          }
        }
        switch (match._0) {
          case /* Assigned */0 :
              return "purple";
          case /* Personal */1 :
              return "green";
          case /* ReviewedBy */2 :
              return "orange";
          
        }
    case /* Target */5 :
        return "red";
    case /* Status */6 :
        if (t._0 === "Reviewed") {
          return "green";
        } else {
          return "yellow";
        }
    case /* NameOrEmail */7 :
        return "gray";
    
  }
}

function level(level$1) {
  return {
          TAG: /* Level */0,
          _0: level$1
        };
}

function assignedToCoach(coach, currentCoachId) {
  return {
          TAG: /* AssignedToCoach */1,
          _0: coach,
          _1: currentCoachId
        };
}

function personalCoach(coach, currentCoachId) {
  return {
          TAG: /* PersonalCoach */2,
          _0: coach,
          _1: currentCoachId
        };
}

function reviewedBy(coach, currentCoachId) {
  return {
          TAG: /* ReviewedBy */3,
          _0: coach,
          _1: currentCoachId
        };
}

function makeLoader(l) {
  return {
          TAG: /* Loader */4,
          _0: l
        };
}

function makecoachLoader(key) {
  return {
          TAG: /* Loader */4,
          _0: /* ShowCoaches */{
            _0: key
          }
        };
}

function target(target$1) {
  return {
          TAG: /* Target */5,
          _0: target$1
        };
}

function status(status$1) {
  return {
          TAG: /* Status */6,
          _0: status$1
        };
}

function nameOrEmail(search) {
  return {
          TAG: /* NameOrEmail */7,
          _0: search
        };
}

function includeInactive(param) {
  return /* IncludeInactive */0;
}

var Selectable = {
  label: label,
  value: value,
  searchString: searchString,
  color: color,
  level: level,
  assignedToCoach: assignedToCoach,
  personalCoach: personalCoach,
  reviewedBy: reviewedBy,
  makeLoader: makeLoader,
  makecoachLoader: makecoachLoader,
  target: target,
  status: status,
  nameOrEmail: nameOrEmail,
  includeInactive: includeInactive
};

var Multiselect = MultiselectDropdownWhite.Make(Selectable);

function unSelectedStatus(filter) {
  var s = CoursesReview__Filter.tab(filter);
  if (s !== undefined) {
    if (s === "Reviewed") {
      return [{
                TAG: /* Status */6,
                _0: "Pending"
              }];
    } else {
      return [{
                TAG: /* Status */6,
                _0: "Reviewed"
              }];
    }
  } else {
    return [
            {
              TAG: /* Status */6,
              _0: "Pending"
            },
            {
              TAG: /* Status */6,
              _0: "Reviewed"
            }
          ];
  }
}

function nameOrEmailFilter(state) {
  var input = $$String.trim(state.filterInput);
  var firstWord = Caml_array.get(input.split(" "), 0);
  if (input === "" || firstWord === tc(undefined, undefined, "search.level") || firstWord === tc(undefined, undefined, "search.target") || firstWord === tc(undefined, undefined, "search.assigned_to")) {
    return [];
  } else {
    return [{
              TAG: /* NameOrEmail */7,
              _0: input
            }];
  }
}

function unselected(state, currentCoachId, filter) {
  var unselectedLevels = state.levels.filter(function (level) {
          return OptionUtils.mapWithDefault((function (selectedLevel) {
                        return Shared__Level.id(level) !== selectedLevel;
                      }), true, CoursesReview__Filter.levelId(filter));
        }).map(level);
  var unselectedTargets = state.targets.filter(function (target) {
          return OptionUtils.mapWithDefault((function (selectedTarget) {
                        return CoursesReview__TargetInfo.id(target) !== selectedTarget;
                      }), true, filter.targetId);
        }).map(target);
  var unselectedCoaches = function (getCoachId, getSelectable) {
    return state.coaches.filter(function (coach) {
                  return Belt_Option.mapWithDefault(Curry._1(getCoachId, filter), true, (function (selectedCoach) {
                                return UserProxy.id(coach) !== selectedCoach;
                              }));
                }).map(function (coach) {
                return Curry._2(getSelectable, coach, currentCoachId);
              });
  };
  var unselectedAssignedCoaches = unselectedCoaches((function (filter) {
          return filter.assignedCoachId;
        }), assignedToCoach);
  var unselectedPersonalCoaches = unselectedCoaches((function (filter) {
          return filter.personalCoachId;
        }), personalCoach);
  var unselectedReviewers = unselectedCoaches((function (filter) {
          return filter.reviewingCoachId;
        }), reviewedBy);
  return ArrayUtils.flattenV2([
              unSelectedStatus(filter),
              unselectedLevels,
              unselectedAssignedCoaches,
              unselectedPersonalCoaches,
              unselectedReviewers,
              unselectedTargets,
              state.levelsLoaded === /* Loaded */2 ? [] : [{
                    TAG: /* Loader */4,
                    _0: /* ShowLevels */0
                  }],
              state.coachesLoaded === /* Loaded */2 ? [] : [
                  {
                    TAG: /* Loader */4,
                    _0: /* ShowCoaches */{
                      _0: /* Assigned */0
                    }
                  },
                  {
                    TAG: /* Loader */4,
                    _0: /* ShowCoaches */{
                      _0: /* Personal */1
                    }
                  },
                  {
                    TAG: /* Loader */4,
                    _0: /* ShowCoaches */{
                      _0: /* ReviewedBy */2
                    }
                  }
                ],
              state.targetsLoaded === /* Loaded */2 ? [] : [{
                    TAG: /* Loader */4,
                    _0: /* ShowTargets */1
                  }],
              nameOrEmailFilter(state),
              CoursesReview__Filter.includeInactive(filter) ? [] : [/* IncludeInactive */0]
            ]);
}

function selected(state, filter, currentCoachId) {
  var selectedLevel = Belt_Option.mapWithDefault(CoursesReview__Filter.levelId(filter), [], (function (levelId) {
          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(state.levels.find(function (l) {
                              return Shared__Level.id(l) === levelId;
                            })), [], (function (l) {
                        return [{
                                  TAG: /* Level */0,
                                  _0: l
                                }];
                      }));
        }));
  var selectedTarget = Belt_Option.mapWithDefault(filter.targetId, [], (function (targetId) {
          return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(state.targets.find(function (t) {
                              return CoursesReview__TargetInfo.id(t) === targetId;
                            })), [], (function (t) {
                        return [{
                                  TAG: /* Target */5,
                                  _0: t
                                }];
                      }));
        }));
  var selectedCoaches = function (getCoachId, getSelectable) {
    return Belt_Option.mapWithDefault(Curry._1(getCoachId, filter), [], (function (coachId) {
                  return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(state.coaches.find(function (c) {
                                      return UserProxy.id(c) === coachId;
                                    })), [], (function (c) {
                                return [Curry._2(getSelectable, c, currentCoachId)];
                              }));
                }));
  };
  var selectedAssiginedCoach = selectedCoaches((function (filter) {
          return filter.assignedCoachId;
        }), assignedToCoach);
  var selectedPersonalCoach = selectedCoaches((function (filter) {
          return filter.personalCoachId;
        }), personalCoach);
  var selectedReviewer = selectedCoaches((function (filter) {
          return filter.reviewingCoachId;
        }), reviewedBy);
  var selectedStatus = OptionUtils.mapWithDefault((function (t) {
          return [{
                    TAG: /* Status */6,
                    _0: t
                  }];
        }), [], filter.tab);
  var selectedSearchString = OptionUtils.mapWithDefault((function (nameOrEmail) {
          return [{
                    TAG: /* NameOrEmail */7,
                    _0: nameOrEmail
                  }];
        }), [], filter.nameOrEmail);
  return ArrayUtils.flattenV2([
              selectedStatus,
              selectedLevel,
              selectedAssiginedCoach,
              selectedPersonalCoach,
              selectedReviewer,
              selectedTarget,
              selectedSearchString,
              CoursesReview__Filter.includeInactive(filter) ? [/* IncludeInactive */0] : []
            ]);
}

function onSelectFilter(send, courseId, state, filter, selectable) {
  if (typeof selectable === "number" || selectable.TAG !== /* Loader */4) {
    Curry._1(send, /* UnsetSearchString */0);
  }
  if (typeof selectable === "number") {
    return updateParams({
                nameOrEmail: filter.nameOrEmail,
                levelId: filter.levelId,
                personalCoachId: filter.personalCoachId,
                assignedCoachId: filter.assignedCoachId,
                reviewingCoachId: filter.reviewingCoachId,
                targetId: filter.targetId,
                sortCriterion: filter.sortCriterion,
                sortDirection: filter.sortDirection,
                tab: filter.tab,
                includeInactive: true
              });
  }
  switch (selectable.TAG | 0) {
    case /* Level */0 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: Shared__Level.id(selectable._0),
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* AssignedToCoach */1 :
        var otherTab = CoursesReview__Filter.tab(filter);
        var tab = otherTab === "Reviewed" ? "Pending" : otherTab;
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: UserProxy.id(selectable._0),
                    reviewingCoachId: undefined,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: tab,
                    includeInactive: filter.includeInactive
                  });
    case /* PersonalCoach */2 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: UserProxy.id(selectable._0),
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* ReviewedBy */3 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: undefined,
                    reviewingCoachId: UserProxy.id(selectable._0),
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: "Reviewed",
                    includeInactive: filter.includeInactive
                  });
    case /* Loader */4 :
        var l = selectable._0;
        Curry._1(send, {
              TAG: /* SetLoader */5,
              _0: l
            });
        if (typeof l === "number") {
          if (l !== 0) {
            return getTargets(send, courseId, state);
          } else {
            return getLevels(send, courseId, state);
          }
        } else {
          return getCoaches(send, courseId, state);
        }
    case /* Target */5 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: CoursesReview__TargetInfo.id(selectable._0),
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* Status */6 :
        if (selectable._0 === "Reviewed") {
          return updateParams({
                      nameOrEmail: filter.nameOrEmail,
                      levelId: filter.levelId,
                      personalCoachId: filter.personalCoachId,
                      assignedCoachId: undefined,
                      reviewingCoachId: filter.reviewingCoachId,
                      targetId: filter.targetId,
                      sortCriterion: "EvaluatedAt",
                      sortDirection: filter.sortDirection,
                      tab: "Reviewed",
                      includeInactive: filter.includeInactive
                    });
        } else {
          return updateParams({
                      nameOrEmail: filter.nameOrEmail,
                      levelId: filter.levelId,
                      personalCoachId: filter.personalCoachId,
                      assignedCoachId: filter.assignedCoachId,
                      reviewingCoachId: undefined,
                      targetId: filter.targetId,
                      sortCriterion: "SubmittedAt",
                      sortDirection: filter.sortDirection,
                      tab: "Pending",
                      includeInactive: filter.includeInactive
                    });
        }
    case /* NameOrEmail */7 :
        return updateParams({
                    nameOrEmail: selectable._0,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    
  }
}

function onDeselectFilter(send, filter, selectable) {
  if (typeof selectable === "number") {
    return updateParams({
                nameOrEmail: filter.nameOrEmail,
                levelId: filter.levelId,
                personalCoachId: filter.personalCoachId,
                assignedCoachId: filter.assignedCoachId,
                reviewingCoachId: filter.reviewingCoachId,
                targetId: filter.targetId,
                sortCriterion: filter.sortCriterion,
                sortDirection: filter.sortDirection,
                tab: filter.tab,
                includeInactive: false
              });
  }
  switch (selectable.TAG | 0) {
    case /* Level */0 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: undefined,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* AssignedToCoach */1 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: undefined,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* PersonalCoach */2 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: undefined,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* ReviewedBy */3 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: undefined,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* Loader */4 :
        return Curry._1(send, /* ClearLoader */6);
    case /* Target */5 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: undefined,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    case /* Status */6 :
        return updateParams({
                    nameOrEmail: filter.nameOrEmail,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: "SubmittedAt",
                    sortDirection: filter.sortDirection,
                    tab: undefined,
                    includeInactive: filter.includeInactive
                  });
    case /* NameOrEmail */7 :
        return updateParams({
                    nameOrEmail: undefined,
                    levelId: filter.levelId,
                    personalCoachId: filter.personalCoachId,
                    assignedCoachId: filter.assignedCoachId,
                    reviewingCoachId: filter.reviewingCoachId,
                    targetId: filter.targetId,
                    sortCriterion: filter.sortCriterion,
                    sortDirection: filter.sortDirection,
                    tab: filter.tab,
                    includeInactive: filter.includeInactive
                  });
    
  }
}

function defaultOptions(state, filter) {
  return ArrayUtils.flattenV2([
              nameOrEmailFilter(state),
              [
                {
                  TAG: /* Loader */4,
                  _0: /* ShowLevels */0
                },
                {
                  TAG: /* Loader */4,
                  _0: /* ShowCoaches */{
                    _0: /* Assigned */0
                  }
                },
                {
                  TAG: /* Loader */4,
                  _0: /* ShowCoaches */{
                    _0: /* Personal */1
                  }
                },
                {
                  TAG: /* Loader */4,
                  _0: /* ShowCoaches */{
                    _0: /* ReviewedBy */2
                  }
                },
                {
                  TAG: /* Loader */4,
                  _0: /* ShowTargets */1
                },
                /* IncludeInactive */0
              ],
              unSelectedStatus(filter)
            ]);
}

function reloadSubmissions(courseId, filter, send) {
  Curry._1(send, /* BeginReloading */2);
  return getSubmissions(send, courseId, undefined, filter);
}

function submissionsLoadedData(totalSubmissionsCount, loadedSubmissionsCount) {
  return React.createElement("p", {
              className: "inline-block mt-2 mx-auto text-gray-800 text-xs px-2 text-center font-semibold",
              tabIndex: 0
            }, totalSubmissionsCount === loadedSubmissionsCount ? tc(undefined, loadedSubmissionsCount, "submissions_fully_loaded_text") : tc([
                    [
                      "total_submissions",
                      String(totalSubmissionsCount)
                    ],
                    [
                      "loaded_submissions_count",
                      String(loadedSubmissionsCount)
                    ]
                  ], loadedSubmissionsCount, "submissions_partially_loaded_text"));
}

function submissionsList(submissions, state, filter) {
  return React.createElement("div", undefined, React.createElement(CoursesReview__SubmissionCard.make, {
                  submissions: submissions,
                  selectedTab: CoursesReview__Filter.tab(filter),
                  filterString: CoursesReview__Filter.toQueryString(filter)
                }), ReactUtils.nullIf(React.createElement("div", {
                      className: "text-center pb-4"
                    }, submissionsLoadedData(state.totalEntriesCount, submissions.length)), ArrayUtils.isEmpty(submissions)));
}

function filterPlaceholder(filter) {
  var match = CoursesReview__Filter.levelId(filter);
  var match$1 = CoursesReview__Filter.assignedCoachId(filter);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return tc(undefined, undefined, "filter_by_another_level");
    } else {
      return tc(undefined, undefined, "filter_by_another_level_or_submissions_assigned");
    }
  } else if (match$1 !== undefined) {
    return tc(undefined, undefined, "filter_by_level");
  } else {
    return tc(undefined, undefined, "filter_by_level_or_submissions_assigned");
  }
}

function loadFilters(send, courseId, state) {
  if (!StringUtils.isPresent(state.filterInput)) {
    return ;
  }
  var input = $$String.lowercase_ascii(state.filterInput);
  if (StringUtils.test(tc(undefined, undefined, "search.level"), input)) {
    getLevels(send, courseId, state);
  }
  if (StringUtils.test(tc(undefined, undefined, "search.assigned_to"), input) || StringUtils.test(tc(undefined, undefined, "search.personal_coach"), input) || StringUtils.test(tc(undefined, undefined, "search.reviewed_by"), input)) {
    getCoaches(send, courseId, state);
  }
  if (StringUtils.test(tc(undefined, undefined, "search.target"), input)) {
    return getTargets(send, courseId, state);
  }
  
}

function shortCutClasses(selected) {
  return "cursor-pointer flex justify-center md:flex-auto rounded-md p-1.5 md:border-b-3 md:rounded-b-none md:border-transparent md:px-4 md:hover:bg-gray-50 md:py-2 text-sm font-semibold text-gray-800 hover:text-primary-600 hover:bg-gray-50 focus:outline-none focus:ring-inset focus:ring-2 focus:bg-gray-50 focus:ring-focusColor-500 md:focus:border-b-none md:focus:rounded-t-md " + (
          selected ? "bg-white shadow md:shadow-none rounded-md md:rounded-none md:bg-transparent md:border-b-3 hover:bg-white md:hover:bg-transparent text-primary-400 md:border-primary-500" : ""
        );
}

function computeInitialState(param) {
  return {
          loading: LoadingV2.empty(undefined),
          submissions: /* Unloaded */0,
          levels: [],
          coaches: [],
          targets: [],
          filterInput: "",
          totalEntriesCount: 0,
          filterLoading: false,
          filterLoader: undefined,
          targetsLoaded: /* Unloaded */0,
          levelsLoaded: /* Unloaded */0,
          coachesLoaded: /* Unloaded */0
        };
}

function pageTitle(courses, courseId) {
  var currentCourse = ArrayUtils.unsafeFind((function (course) {
          return AppRouter__Course.id(course) === courseId;
        }), "Could not find currentCourse with ID " + courseId + " in CoursesReview__Root", courses);
  return tc(undefined, undefined, "review") + " | " + AppRouter__Course.name(currentCourse);
}

function CoursesReview__Root(Props) {
  var courseId = Props.courseId;
  var currentCoachId = Props.currentCoachId;
  var courses = Props.courses;
  var match = React.useReducer(reducer, computeInitialState(undefined));
  var send = match[1];
  var state = match[0];
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var filter = CoursesReview__Filter.makeFromQueryParams(url.search);
  React.useEffect((function () {
          reloadSubmissions(courseId, filter, send);
          
        }), [url]);
  React.useEffect((function () {
          loadFilters(send, courseId, state);
          
        }), [state.filterInput]);
  var submissions = state.submissions;
  var tmp;
  if (typeof submissions === "number") {
    tmp = React.createElement("div", undefined, SkeletonLoading.multiple(6, SkeletonLoading.card(undefined, undefined)));
  } else if (submissions.TAG === /* PartiallyLoaded */0) {
    var cursor = submissions._1;
    var times = state.loading;
    tmp = React.createElement("div", undefined, submissionsList(submissions._0, state, filter), times ? ReactUtils.nullUnless(React.createElement("div", {
                    className: "pb-6"
                  }, React.createElement("button", {
                        className: "btn btn-primary-ghost cursor-pointer w-full",
                        onClick: (function (param) {
                            Curry._1(send, /* BeginLoadingMore */1);
                            return getSubmissions(send, courseId, cursor, filter);
                          })
                      }, tc(undefined, undefined, "button_load_more"))), ArrayUtils.isEmpty(times._0)) : React.createElement("div", undefined, SkeletonLoading.multiple(1, SkeletonLoading.card(undefined, undefined))));
  } else {
    tmp = React.createElement("div", undefined, submissionsList(submissions._0, state, filter));
  }
  var match$1 = state.submissions;
  var tmp$1;
  if (typeof match$1 === "number") {
    tmp$1 = null;
  } else {
    var times$1 = state.loading;
    var loading = times$1 ? ArrayUtils.isNotEmpty(times$1._0) : false;
    tmp$1 = React.createElement(LoadingSpinner.make, {
          loading: loading
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ReactHelmet.Helmet, {
                  children: React.createElement("title", undefined, pageTitle(courses, courseId))
                }), React.createElement("div", {
                  "aria-label": "Review",
                  className: "flex-1 flex flex-col",
                  role: "main"
                }, React.createElement("div", {
                      className: "hidden md:block h-16"
                    }), React.createElement("div", {
                      className: "course-review-root__submissions-list-container"
                    }, React.createElement("div", {
                          className: "bg-gray-50"
                        }, React.createElement("div", {
                              className: "max-w-4xl 2xl:max-w-5xl mx-auto"
                            }, React.createElement("div", {
                                  className: "flex items-center justify-between bg-white md:bg-transparent px-4 py-2 md:pt-4 border-b md:border-none"
                                }, React.createElement("h4", {
                                      className: "font-semibold"
                                    }, tc(undefined, undefined, "review")), React.createElement("div", {
                                      className: "block md:hidden"
                                    }, submissionsSorter(filter))), React.createElement("div", {
                                  className: "px-4"
                                }, React.createElement("div", {
                                      className: "flex pt-3 md:border-b border-gray-300"
                                    }, React.createElement("div", {
                                          "aria-label": "Status tabs",
                                          className: "flex flex-1 md:flex-none p-1 md:p-0 space-x-1 md:space-x-0 text-center rounded-lg justify-between md:justify-start bg-gray-300 md:bg-transparent ",
                                          role: "tablist"
                                        }, React.createElement("div", {
                                              "aria-selected": filter.tab === undefined,
                                              className: "flex-1",
                                              role: "tab"
                                            }, React.createElement(Link.make, {
                                                  href: "/courses/" + courseId + "/review?" + CoursesReview__Filter.toQueryString({
                                                        nameOrEmail: filter.nameOrEmail,
                                                        levelId: filter.levelId,
                                                        personalCoachId: filter.personalCoachId,
                                                        assignedCoachId: filter.assignedCoachId,
                                                        reviewingCoachId: filter.reviewingCoachId,
                                                        targetId: filter.targetId,
                                                        sortCriterion: "SubmittedAt",
                                                        sortDirection: CoursesReview__Filter.sortDirection(filter),
                                                        tab: undefined,
                                                        includeInactive: filter.includeInactive
                                                      }),
                                                  className: shortCutClasses(filter.tab === undefined),
                                                  children: React.createElement("p", undefined, I18n.ts(undefined, undefined, "all"))
                                                })), React.createElement("div", {
                                              "aria-selected": filter.tab === "Pending",
                                              className: "flex-1",
                                              role: "tab"
                                            }, React.createElement(Link.make, {
                                                  href: "/courses/" + courseId + "/review?" + CoursesReview__Filter.toQueryString({
                                                        nameOrEmail: filter.nameOrEmail,
                                                        levelId: filter.levelId,
                                                        personalCoachId: filter.personalCoachId,
                                                        assignedCoachId: filter.assignedCoachId,
                                                        reviewingCoachId: filter.reviewingCoachId,
                                                        targetId: filter.targetId,
                                                        sortCriterion: "SubmittedAt",
                                                        sortDirection: CoursesReview__Filter.sortDirection(filter),
                                                        tab: "Pending",
                                                        includeInactive: filter.includeInactive
                                                      }),
                                                  className: shortCutClasses(filter.tab === "Pending"),
                                                  children: React.createElement("p", undefined, tc(undefined, undefined, "pending"))
                                                })), React.createElement("div", {
                                              "aria-selected": filter.tab === "Reviewed",
                                              className: "flex-1",
                                              role: "tab"
                                            }, React.createElement(Link.make, {
                                                  href: "/courses/" + courseId + "/review?" + CoursesReview__Filter.toQueryString({
                                                        nameOrEmail: filter.nameOrEmail,
                                                        levelId: filter.levelId,
                                                        personalCoachId: filter.personalCoachId,
                                                        assignedCoachId: filter.assignedCoachId,
                                                        reviewingCoachId: filter.reviewingCoachId,
                                                        targetId: filter.targetId,
                                                        sortCriterion: "EvaluatedAt",
                                                        sortDirection: CoursesReview__Filter.sortDirection(filter),
                                                        tab: "Reviewed",
                                                        includeInactive: filter.includeInactive
                                                      }),
                                                  className: shortCutClasses(filter.tab === "Reviewed"),
                                                  children: React.createElement("p", undefined, tc(undefined, undefined, "reviewed"))
                                                }))))))), React.createElement("div", {
                          className: "md:sticky md:top-0 bg-gray-50"
                        }, React.createElement("div", {
                              className: "max-w-4xl 2xl:max-w-5xl mx-auto"
                            }, React.createElement("div", {
                                  className: "md:flex w-full items-start pt-4 pb-3 px-4 md:pt-6",
                                  role: "form"
                                }, React.createElement("div", {
                                      className: "flex-1"
                                    }, React.createElement("label", {
                                          className: "block text-tiny font-semibold uppercase",
                                          htmlFor: "filter"
                                        }, tc(undefined, undefined, "filter_by")), React.createElement(Multiselect.make, {
                                          id: "filter",
                                          placeholder: filterPlaceholder(filter),
                                          onChange: (function (filterInput) {
                                              return Curry._1(send, {
                                                          TAG: /* UpdateFilterInput */0,
                                                          _0: filterInput
                                                        });
                                            }),
                                          value: state.filterInput,
                                          unselected: unselected(state, currentCoachId, filter),
                                          selected: selected(state, filter, currentCoachId),
                                          onSelect: (function (param) {
                                              return onSelectFilter(send, courseId, state, filter, param);
                                            }),
                                          onDeselect: (function (param) {
                                              return onDeselectFilter(send, filter, param);
                                            }),
                                          hint: tc(undefined, undefined, "filter_hint"),
                                          defaultOptions: defaultOptions(state, filter),
                                          loading: state.filterLoading
                                        })), React.createElement("div", {
                                      className: "hidden md:block"
                                    }, submissionsSorter(filter))))), React.createElement("div", {
                          className: "max-w-4xl 2xl:max-w-5xl mx-auto px-4"
                        }, React.createElement("div", undefined, tmp), tmp$1)), React.createElement("div", {
                      className: "md:hidden h-16"
                    })));
}

var UserProxyFragment;

var make$4 = CoursesReview__Root;

export {
  str ,
  tc ,
  Item ,
  PagedSubmission ,
  coachFilterTranslationKey ,
  reducer ,
  updateParams ,
  UserProxyFragment ,
  SubmissionsQuery ,
  LevelsQuery ,
  CoachesQuery ,
  ReviewedTargetsInfoQuery ,
  getSubmissions ,
  getLevels ,
  getCoaches ,
  getTargets ,
  Sortable ,
  SubmissionsSorter ,
  submissionsSorter ,
  Selectable ,
  Multiselect ,
  unSelectedStatus ,
  nameOrEmailFilter ,
  unselected ,
  selected ,
  onSelectFilter ,
  onDeselectFilter ,
  defaultOptions ,
  reloadSubmissions ,
  submissionsLoadedData ,
  submissionsList ,
  filterPlaceholder ,
  loadFilters ,
  shortCutClasses ,
  computeInitialState ,
  pageTitle ,
  make$4 as make,
  
}
/*  Not a pure module */
