// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";
import * as CoursesReview__OverlaySubmission from "./CoursesReview__OverlaySubmission.bs.js";

function id(t) {
  return t.id;
}

function title(t) {
  return t.title;
}

function createdAt(t) {
  return t.createdAt;
}

function levelNumber(t) {
  return t.levelNumber;
}

function userNames(t) {
  return t.userNames;
}

function teamName(t) {
  return t.teamName;
}

function reviewer(t) {
  return t.reviewer;
}

function reviewerName(reviewer) {
  return reviewer.name;
}

function reviewerAssignedAt(reviewer) {
  return reviewer.assignedAt;
}

function failed(t) {
  var status = t.status;
  if (status !== undefined) {
    return OptionUtils.mapWithDefault((function (param) {
                  return false;
                }), true, status.passedAt);
  } else {
    return false;
  }
}

function pendingReview(t) {
  return OptionUtils.mapWithDefault((function (param) {
                return false;
              }), true, t.status);
}

function feedbackSent(t) {
  return OptionUtils.mapWithDefault((function (status) {
                return status.feedbackSent;
              }), false, t.status);
}

function createdAtPretty(t) {
  return DateFns.format(t.createdAt, "MMMM d, yyyy");
}

function timeDistance(t) {
  return DateFns.formatDistanceToNowStrict(t.createdAt, true, undefined, undefined, undefined);
}

function make(id, title, createdAt, userNames, status, teamName, levelNumber, reviewer) {
  return {
          id: id,
          title: title,
          createdAt: createdAt,
          userNames: userNames,
          status: status,
          teamName: teamName,
          levelNumber: levelNumber,
          reviewer: reviewer
        };
}

function makeStatus(passedAt, feedbackSent) {
  return {
          passedAt: passedAt,
          feedbackSent: feedbackSent
        };
}

function makeReviewerInfo(reviewer) {
  return {
          name: reviewer.name,
          assignedAt: DateFns.decodeISO(reviewer.assignedAt)
        };
}

function makeFromJS(submission) {
  var status = Belt_Option.map(submission.evaluatedAt, (function (param) {
          return {
                  passedAt: Belt_Option.map(submission.passedAt, DateFns.decodeISO),
                  feedbackSent: submission.feedbackSent
                };
        }));
  return make(submission.id, submission.title, DateFns.decodeISO(submission.createdAt), submission.userNames, status, submission.teamName, submission.levelNumber, Belt_Option.map(submission.reviewer, makeReviewerInfo));
}

function replace(e, l) {
  return $$Array.map((function (s) {
                if (s.id === e.id) {
                  return e;
                } else {
                  return s;
                }
              }), l);
}

function statusEq(overlaySubmission, t) {
  var match = t.status;
  var match$1 = CoursesReview__OverlaySubmission.evaluatedAt(overlaySubmission);
  if (match !== undefined) {
    if (match$1 !== undefined) {
      return Caml_obj.caml_equal(match.passedAt, CoursesReview__OverlaySubmission.passedAt(overlaySubmission));
    } else {
      return false;
    }
  } else {
    return match$1 === undefined;
  }
}

export {
  id ,
  title ,
  createdAt ,
  levelNumber ,
  userNames ,
  teamName ,
  reviewer ,
  reviewerName ,
  reviewerAssignedAt ,
  failed ,
  pendingReview ,
  feedbackSent ,
  createdAtPretty ,
  timeDistance ,
  make ,
  makeStatus ,
  makeReviewerInfo ,
  makeFromJS ,
  replace ,
  statusEq ,
  
}
/* DateFns Not a pure module */
