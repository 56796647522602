// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";

var InvalidSubmissionReportCompletionStatus = /* @__PURE__ */Caml_exceptions.create("CoursesReview__SubmissionReport.InvalidSubmissionReportCompletionStatus");

function decodeConclusion(conclusion) {
  if (conclusion === "failure") {
    return /* Failure */1;
  } else if (conclusion === "success") {
    return /* Success */0;
  } else {
    return /* Error */2;
  }
}

function decodeCompletedStatus(startedAt, completedAt, conclusion) {
  if (startedAt !== undefined) {
    if (completedAt !== undefined) {
      if (conclusion !== undefined) {
        return {
                TAG: /* Completed */2,
                _0: {
                  startedAt: DateFns.decodeISO(Caml_option.valFromOption(startedAt)),
                  completedAt: DateFns.decodeISO(Caml_option.valFromOption(completedAt))
                },
                _1: decodeConclusion(conclusion)
              };
      }
      Rollbar.critical("Invalid completion status of submission report - conclusion missing for completed test");
      throw {
            RE_EXN_ID: InvalidSubmissionReportCompletionStatus,
            Error: new Error()
          };
    }
    Rollbar.critical("Invalid completion status of submission report - end time missing for completed test");
    throw {
          RE_EXN_ID: InvalidSubmissionReportCompletionStatus,
          Error: new Error()
        };
  }
  Rollbar.critical("Invalid completion status of submission report - start time missing for completed test");
  throw {
        RE_EXN_ID: InvalidSubmissionReportCompletionStatus,
        Error: new Error()
      };
}

function decodeStatus(status, conclusion, queuedAt, startedAt, completedAt) {
  if (status === "completed") {
    return decodeCompletedStatus(startedAt, completedAt, conclusion);
  }
  if (status === "queued") {
    return {
            TAG: /* Queued */0,
            _0: DateFns.decodeISO(queuedAt)
          };
  }
  if (startedAt !== undefined) {
    return {
            TAG: /* InProgress */1,
            _0: DateFns.decodeISO(Caml_option.valFromOption(startedAt))
          };
  }
  Rollbar.critical("Invalid completion status of submission report - start time missing for test in progress");
  throw {
        RE_EXN_ID: InvalidSubmissionReportCompletionStatus,
        Error: new Error()
      };
}

function makeFromJS(object) {
  return {
          id: object.id,
          status: decodeStatus(object.status, object.conclusion, object.queuedAt, object.startedAt, object.completedAt),
          testReport: object.testReport,
          queuedAt: DateFns.decodeISO(object.queuedAt)
        };
}

function id(t) {
  return t.id;
}

function status(t) {
  return t.status;
}

function testReport(t) {
  return t.testReport;
}

function startedAt(t) {
  return t.startedAt;
}

export {
  InvalidSubmissionReportCompletionStatus ,
  decodeConclusion ,
  decodeCompletedStatus ,
  decodeStatus ,
  makeFromJS ,
  id ,
  status ,
  testReport ,
  startedAt ,
  
}
/* DateFns Not a pure module */
