// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as CoursesReview__Grade from "./CoursesReview__Grade.bs.js";
import * as CoursesReview__Feedback from "./CoursesReview__Feedback.bs.js";
import * as SubmissionChecklistItem from "./SubmissionChecklistItem.bs.js";

function id(t) {
  return t.id;
}

function createdAt(t) {
  return t.createdAt;
}

function passedAt(t) {
  return t.passedAt;
}

function evaluatorName(t) {
  return t.evaluatorName;
}

function evaluatedAt(t) {
  return t.evaluatedAt;
}

function grades(t) {
  return t.grades;
}

function feedback(t) {
  return t.feedback;
}

function checklist(t) {
  return t.checklist;
}

function archivedAt(t) {
  return t.archivedAt;
}

function make(id, createdAt, passedAt, evaluatorName, feedback, grades, evaluatedAt, archivedAt, checklist) {
  return {
          id: id,
          createdAt: createdAt,
          passedAt: passedAt,
          evaluatorName: evaluatorName,
          evaluatedAt: evaluatedAt,
          archivedAt: archivedAt,
          feedback: feedback,
          grades: grades,
          checklist: checklist
        };
}

function makeFromJs(s) {
  var partial_arg = SubmissionChecklistItem.makeFiles(s.files);
  return make(s.id, DateFns.decodeISO(s.createdAt), Belt_Option.map(s.passedAt, DateFns.decodeISO), s.evaluatorName, s.feedback.map(function (f) {
                  return CoursesReview__Feedback.make(f.coachName, f.coachAvatarUrl, f.coachTitle, DateFns.decodeISO(f.createdAt), f.value);
                }), s.grades.map(function (g) {
                  return CoursesReview__Grade.make(g.evaluationCriterionId, g.grade);
                }), Belt_Option.map(s.evaluatedAt, DateFns.decodeISO), Belt_Option.map(s.archivedAt, DateFns.decodeISO), Json_decode.array((function (param) {
                    return SubmissionChecklistItem.decode(partial_arg, param);
                  }), s.checklist));
}

function update(passedAt, evaluatorName, feedback, grades, evaluatedAt, checklist, t) {
  return {
          id: t.id,
          createdAt: t.createdAt,
          passedAt: passedAt,
          evaluatorName: evaluatorName,
          evaluatedAt: evaluatedAt,
          archivedAt: t.archivedAt,
          feedback: feedback,
          grades: grades,
          checklist: checklist
        };
}

function feedbackSent(t) {
  return ArrayUtils.isNotEmpty(t.feedback);
}

function updateFeedback(feedback, t) {
  return {
          id: t.id,
          createdAt: t.createdAt,
          passedAt: t.passedAt,
          evaluatorName: t.evaluatorName,
          evaluatedAt: t.evaluatedAt,
          archivedAt: t.archivedAt,
          feedback: feedback,
          grades: t.grades,
          checklist: t.checklist
        };
}

export {
  id ,
  createdAt ,
  passedAt ,
  evaluatorName ,
  evaluatedAt ,
  grades ,
  feedback ,
  checklist ,
  archivedAt ,
  make ,
  makeFromJs ,
  update ,
  feedbackSent ,
  updateFeedback ,
  
}
/* DateFns Not a pure module */
