// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as TopicCategory from "../shared/types/TopicCategory.bs.js";
import * as TopicsShow__LinkedTarget from "../topics/types/TopicsShow__LinkedTarget.bs.js";
import * as CommunitiesNewTopic__Root from "../communities/CommunitiesNewTopic__Root.bs.js";

function decodeProps(json) {
  return {
          communityId: Json_decode.field("communityId", Json_decode.string, json),
          target: Json_decode.optional((function (param) {
                  return Json_decode.field("target", TopicsShow__LinkedTarget.decode, param);
                }), json),
          topicCategories: Json_decode.field("topicCategories", (function (param) {
                  return Json_decode.array(TopicCategory.decode, param);
                }), json),
          userEmail: Json_decode.field("userEmail", Json_decode.string, json),
          userAccessToken: Json_decode.field("userAccessToken", Json_decode.string, json)
        };
}

Psj.match(undefined, "communities#new_topic", (function (param) {
        var props = decodeProps(DomUtils.parseJSONTag(undefined, undefined));
        var root = document.querySelector("#react-root");
        if (!(root == null)) {
          ReactDom.render(React.createElement(CommunitiesNewTopic__Root.make, {
                    communityId: props.communityId,
                    target: props.target,
                    topicCategories: props.topicCategories,
                    userEmail: props.userEmail,
                    userAccessToken: props.userAccessToken
                  }), root);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
