// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as React from "react";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as SubmissionChecklistItemShow from "./SubmissionChecklistItemShow.bs.js";

function str(prim) {
  return prim;
}

var partial_arg = "components.SubmissionChecklistShow";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function SubmissionChecklistShow(Props) {
  var checklist = Props.checklist;
  var updateChecklistCB = Props.updateChecklistCB;
  return React.createElement("div", {
              className: "space-y-8"
            }, ArrayUtils.isEmpty(checklist) ? React.createElement("div", undefined, t(undefined, undefined, "target_marked_as_complete")) : checklist.map(function (checklistItem, index) {
                    return React.createElement(SubmissionChecklistItemShow.make, {
                                index: index,
                                checklistItem: checklistItem,
                                updateChecklistCB: updateChecklistCB,
                                checklist: checklist,
                                key: String(index)
                              });
                  }));
}

var make = SubmissionChecklistShow;

export {
  str ,
  t ,
  make ,
  
}
/* I18n Not a pure module */
