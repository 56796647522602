// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as LevelLabel from "../../../shared/utils/LevelLabel.bs.js";
import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

var partial_arg = "components.CoursesReport__Level";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function number(t) {
  return t.number;
}

function unlocked(t) {
  return t.unlocked;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json),
          number: Json_decode.field("number", Json_decode.$$int, json),
          unlocked: Json_decode.field("unlocked", Json_decode.bool, json)
        };
}

function shortName(t) {
  return LevelLabel.format(true, undefined, String(t.number));
}

function levelLabel(levels, id) {
  return LevelLabel.format(undefined, undefined, String(ArrayUtils.unsafeFind((function (level) {
                        return level.id === id;
                      }), "Unable to find level with ID: " + (id + " in CoursesReport"), levels).number));
}

function sort(levels) {
  return ArrayUtils.copyAndSort((function (x, y) {
                return x.number - y.number | 0;
              }), levels);
}

export {
  t ,
  id ,
  name ,
  number ,
  unlocked ,
  decode ,
  shortName ,
  levelLabel ,
  sort ,
  
}
/* I18n Not a pure module */
