// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as UsersDashboard__Course from "../users/home/types/UsersDashboard__Course.bs.js";
import * as UsersDashboard__Community from "../users/home/types/UsersDashboard__Community.bs.js";
import * as UsersDashboard__IssuedCertificate from "../users/home/types/UsersDashboard__IssuedCertificate.bs.js";

function decodeProps(json) {
  return [
          Json_decode.field("currentSchoolAdmin", Json_decode.bool, json),
          Json_decode.field("courses", (function (param) {
                  return Json_decode.array(UsersDashboard__Course.decode, param);
                }), json),
          Json_decode.field("communities", (function (param) {
                  return Json_decode.array(UsersDashboard__Community.decode, param);
                }), json),
          Json_decode.field("showUserEdit", Json_decode.bool, json),
          Json_decode.field("userName", Json_decode.string, json),
          Caml_option.null_to_opt(Json_decode.field("preferredName", (function (param) {
                      return Json_decode.nullable(Json_decode.string, param);
                    }), json)),
          Json_decode.field("userTitle", Json_decode.string, json),
          Json_decode.optional((function (param) {
                  return Json_decode.field("avatarUrl", Json_decode.string, param);
                }), json),
          Json_decode.field("issuedCertificates", (function (param) {
                  return Json_decode.array(UsersDashboard__IssuedCertificate.decode, param);
                }), json)
        ];
}

Psj.match(undefined, "users#dashboard", (function (param) {
        decodeProps(DomUtils.parseJSONTag("users-dashboard-data", undefined));
        return DomUtils.redirect("/school");
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
