// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as EvaluationCriterion from "../../../shared/types/EvaluationCriterion.bs.js";

function make(evaluationCriterionId, value) {
  return {
          evaluationCriterionId: evaluationCriterionId,
          value: value
        };
}

function sort(criteria, grades) {
  return ArrayUtils.copyAndSort((function (g1, g2) {
                var ec1 = ArrayUtils.unsafeFind((function (ec) {
                        return EvaluationCriterion.id(ec) === g1.evaluationCriterionId;
                      }), "Unable to find evaluation criterion with ID: " + (g1.evaluationCriterionId + " in CoursesReview__Grade"), criteria);
                var ec2 = ArrayUtils.unsafeFind((function (ec) {
                        return EvaluationCriterion.id(ec) === g2.evaluationCriterionId;
                      }), "Unable to find evaluation criterion with ID: " + (g2.evaluationCriterionId + " in CoursesReview__Grade"), criteria);
                return $$String.compare(EvaluationCriterion.name(ec1), EvaluationCriterion.name(ec2));
              }), grades);
}

function evaluationCriterionId(t) {
  return t.evaluationCriterionId;
}

function value(t) {
  return t.value;
}

function asJsType(t) {
  return {
          evaluationCriterionId: t.evaluationCriterionId,
          grade: t.value
        };
}

export {
  make ,
  sort ,
  evaluationCriterionId ,
  value ,
  asJsType ,
  
}
/* ArrayUtils Not a pure module */
