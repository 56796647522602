// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Array from "../../../../../node_modules/rescript/lib/es6/array.js";
import * as $$String from "../../../../../node_modules/rescript/lib/es6/string.js";
import * as Pervasives from "../../../../../node_modules/rescript/lib/es6/pervasives.js";
import * as Json_encode from "../../../../../node_modules/@glennsl/bs-json/src/Json_encode.bs.js";

function title(t) {
  return t.title;
}

function feedback(t) {
  return t.feedback;
}

function make(title, feedback) {
  return {
          title: title,
          feedback: feedback
        };
}

function makeFromJs(data) {
  return data.map(function (r) {
              return {
                      title: r.title,
                      feedback: r.feedback
                    };
            });
}

function emptyTemplate(param) {
  return [
          {
            title: "Yes",
            feedback: "Sample feedback for yes"
          },
          {
            title: "No",
            feedback: "Sample feedback for no"
          }
        ];
}

function empty(param) {
  return {
          title: "",
          feedback: undefined
        };
}

function replace(t, index, checklist) {
  return $$Array.mapi((function (resultIndex, result) {
                if (resultIndex === index) {
                  return t;
                } else {
                  return result;
                }
              }), checklist);
}

function updateTitle(title, t, index, checklist) {
  return replace({
              title: title,
              feedback: t.feedback
            }, index, checklist);
}

function updateFeedback(feedback, t, index, checklist) {
  var optionalFeedback = feedback.trim() === "" ? undefined : feedback;
  return replace({
              title: t.title,
              feedback: optionalFeedback
            }, index, checklist);
}

function trim(t) {
  return {
          title: $$String.trim(t.title),
          feedback: t.feedback
        };
}

function encode(t) {
  var title_0 = [
    "title",
    t.title
  ];
  var title = {
    hd: title_0,
    tl: /* [] */0
  };
  var f = t.feedback;
  var feedback = f !== undefined ? ({
        hd: [
          "feedback",
          f
        ],
        tl: /* [] */0
      }) : /* [] */0;
  return Json_encode.object_(Pervasives.$at(title, feedback));
}

export {
  title ,
  feedback ,
  make ,
  makeFromJs ,
  emptyTemplate ,
  empty ,
  replace ,
  updateTitle ,
  updateFeedback ,
  trim ,
  encode ,
  
}
/* No side effect */
