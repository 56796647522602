// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";

function title(t) {
  return t.title;
}

function url(t) {
  return t.url;
}

function local(t) {
  return t.local;
}

function decode(json) {
  return {
          title: Json_decode.field("title", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json),
          local: Json_decode.field("local", Json_decode.bool, json)
        };
}

export {
  title ,
  url ,
  local ,
  decode ,
  
}
/* No side effect */
