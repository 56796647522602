// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as StringUtils from "../../../shared/utils/StringUtils.bs.js";

function name(t) {
  return t.name;
}

function id(t) {
  return t.id;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

function path(t) {
  return "/communities/" + (t.id + ("/" + StringUtils.parameterize(t.name)));
}

export {
  name ,
  id ,
  decode ,
  path ,
  
}
/* No side effect */
