// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../Icon.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Dropdown2 from "./Dropdown2.bs.js";
import * as ArrayUtils from "../utils/ArrayUtils.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as GraphqlQuery from "../utils/GraphqlQuery.bs.js";
import * as RescriptReactRouter from "../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as MultiselectDropdownWhite from "../../packages/multiselect-dropdown/src/components/MultiselectDropdownWhite.bs.js";

function str(prim) {
  return prim;
}

function makeFilter(key, label, filterType, color) {
  return {
          key: key,
          label: label,
          filterType: filterType,
          color: color
        };
}

function makeSorter(key, options, $$default) {
  return {
          key: key,
          options: options,
          default: $$default
        };
}

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* UnsetSearchString */0) {
      return {
              filterInput: "",
              filterLoading: state.filterLoading,
              filterData: state.filterData
            };
    } else {
      return {
              filterInput: state.filterInput,
              filterLoading: true,
              filterData: state.filterData
            };
    }
  } else if (action.TAG === /* UpdateFilterInput */0) {
    return {
            filterInput: action._0,
            filterLoading: state.filterLoading,
            filterData: state.filterData
          };
  } else {
    return {
            filterInput: state.filterInput,
            filterLoading: false,
            filterData: action._0
          };
  }
}

var Raw = {};

var query = "query CourseResourceInfoInfoQuery($courseId: ID!, $resources: [CourseResource!]!)  {\ncourseResourceInfo(courseId: $courseId, resources: $resources)  {\nresource  \nvalues  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.courseResourceInfo;
  return {
          courseResourceInfo: value$1.map(function (value) {
                var value$1 = value.resource;
                var tmp;
                switch (value$1) {
                  case "Coach" :
                      tmp = "Coach";
                      break;
                  case "Cohort" :
                      tmp = "Cohort";
                      break;
                  case "Level" :
                      tmp = "Level";
                      break;
                  case "StudentTag" :
                      tmp = "StudentTag";
                      break;
                  case "UserTag" :
                      tmp = "UserTag";
                      break;
                  default:
                    tmp = {
                      NAME: "FutureAddedValue",
                      VAL: value$1
                    };
                }
                var value$2 = value.values;
                return {
                        resource: tmp,
                        values: value$2.map(function (value) {
                              return value;
                            })
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.courseResourceInfo;
  var courseResourceInfo = value$1.map(function (value) {
        var value$1 = value.values;
        var values = value$1.map(function (value) {
              return value;
            });
        var value$2 = value.resource;
        var resource = typeof value$2 === "object" ? value$2.VAL : (
            value$2 === "Coach" ? "Coach" : (
                value$2 === "Cohort" ? "Cohort" : (
                    value$2 === "Level" ? "Level" : (
                        value$2 === "UserTag" ? "UserTag" : "StudentTag"
                      )
                  )
              )
          );
        return {
                resource: resource,
                values: values
              };
      });
  return {
          courseResourceInfo: courseResourceInfo
        };
}

function serializeVariables(inp) {
  var a = inp.resources;
  return {
          courseId: inp.courseId,
          resources: a.map(function (b) {
                if (b === "Coach") {
                  return "Coach";
                } else if (b === "Cohort") {
                  return "Cohort";
                } else if (b === "Level") {
                  return "Level";
                } else if (b === "UserTag") {
                  return "UserTag";
                } else {
                  return "StudentTag";
                }
              })
        };
}

function makeVariables(courseId, resources, param) {
  return {
          courseId: courseId,
          resources: resources
        };
}

var CourseResourceInfoInfoQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var make = include.make;

var CourseResourceInfoInfoQuery_Graphql_error = include.Graphql_error;

var CourseResourceInfoInfoQuery_decodeNotification = include.decodeNotification;

var CourseResourceInfoInfoQuery_decodeObject = include.decodeObject;

var CourseResourceInfoInfoQuery_decodeNotifications = include.decodeNotifications;

var CourseResourceInfoInfoQuery_decodeErrors = include.decodeErrors;

var CourseResourceInfoInfoQuery_flashNotifications = include.flashNotifications;

var CourseResourceInfoInfoQuery_sendQuery = include.sendQuery;

var CourseResourceInfoInfoQuery_query = include.query;

var CourseResourceInfoInfoQuery_fetch = include.$$fetch;

var CourseResourceInfoInfoQuery = {
  CourseResourceInfoInfoQuery_inner: CourseResourceInfoInfoQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: CourseResourceInfoInfoQuery_Graphql_error,
  decodeNotification: CourseResourceInfoInfoQuery_decodeNotification,
  decodeObject: CourseResourceInfoInfoQuery_decodeObject,
  decodeNotifications: CourseResourceInfoInfoQuery_decodeNotifications,
  decodeErrors: CourseResourceInfoInfoQuery_decodeErrors,
  flashNotifications: CourseResourceInfoInfoQuery_flashNotifications,
  sendQuery: CourseResourceInfoInfoQuery_sendQuery,
  query: CourseResourceInfoInfoQuery_query,
  $$fetch: CourseResourceInfoInfoQuery_fetch,
  make: make
};

function getCourseResources(send, courseId, filters) {
  var resources = ArrayUtils.flattenV2(filters.map(function (config) {
            var resource = config.filterType;
            if (typeof resource === "number") {
              return [];
            } else if (resource.TAG === /* DataLoad */0) {
              return [resource._0];
            } else {
              return [];
            }
          }));
  if (resources.length > 0) {
    Curry._1(send, /* SetLoading */1);
    Curry._3(make, undefined, undefined, {
            courseId: courseId,
            resources: resources
          }).then(function (response) {
          Curry._1(send, {
                TAG: /* SetFilterData */1,
                _0: response.courseResourceInfo.map(function (obj) {
                      return {
                              resource: obj.resource,
                              values: obj.values
                            };
                    })
              });
          return Promise.resolve(undefined);
        });
    return ;
  }
  
}

function formatStringWithID(string) {
  return string.replace(/^\d+;/, "");
}

function value(t) {
  return t.displayValue;
}

function label(t) {
  return t.label;
}

function key(t) {
  return t.key;
}

function color(t) {
  return t.color;
}

function orginalValue(t) {
  return t.orginalValue;
}

function searchString(t) {
  return Belt_Option.getWithDefault(t.label, t.key) + " " + t.displayValue;
}

function make$1(key, value, label, color) {
  return {
          key: key,
          orginalValue: value,
          displayValue: formatStringWithID(value),
          label: label,
          color: color
        };
}

var Selectable = {
  value: value,
  label: label,
  key: key,
  color: color,
  orginalValue: orginalValue,
  searchString: searchString,
  make: make$1
};

var Multiselect = MultiselectDropdownWhite.Make({
      label: label,
      value: value,
      searchString: searchString,
      color: color
    });

function findResource(resource, filterData, filter) {
  return Belt_Option.mapWithDefault(Caml_option.undefined_to_opt(filterData.find(function (filterItem) {
                      return filterItem.resource === resource;
                    })), [], (function (filterItem) {
                return filterItem.values.map(function (value) {
                            return make$1(filter.key, value, filter.label, filter.color);
                          });
              }));
}

function unselected(state, filters) {
  return ArrayUtils.flattenV2(filters.map(function (config) {
                  var r = config.filterType;
                  if (typeof r === "number") {
                    if (state.filterInput === "") {
                      return [];
                    } else {
                      return [make$1(config.key, state.filterInput, config.label, config.color)];
                    }
                  }
                  switch (r.TAG | 0) {
                    case /* DataLoad */0 :
                        return findResource(r._0, state.filterData, config);
                    case /* Custom */1 :
                        return [make$1(config.key, r._0, config.label, config.color)];
                    case /* CustomArray */2 :
                        return r._0.map(function (value) {
                                    return make$1(config.key, value, config.label, config.color);
                                  });
                    
                  }
                }));
}

function computeInitialState(param) {
  return {
          filterInput: "",
          filterLoading: false,
          filterData: []
        };
}

function selectedFromQueryParams(params, filters) {
  return ArrayUtils.flattenV2(filters.map(function (config) {
                  var value = params.get(config.key);
                  if (value == null) {
                    return [];
                  } else {
                    return [make$1(config.key, value, config.label, config.color)];
                  }
                }));
}

function setParams(key, value, params) {
  params.set(key, value);
  return RescriptReactRouter.push("?" + params.toString());
}

function onSelect(params, send, selectable) {
  setParams(selectable.key, selectable.orginalValue, params);
  return Curry._1(send, /* UnsetSearchString */0);
}

function onDeselect(params, selectable) {
  params.delete(selectable.key);
  return RescriptReactRouter.push("?" + params.toString());
}

function selected(sorter, params) {
  var value = Belt_Option.getWithDefault(Caml_option.nullable_to_opt(params.get(sorter.key)), sorter.default);
  return React.createElement("button", {
              className: "p-3 w-36 text-sm font-medium space-x-2 text-left truncate cursor-pointer border border-gray-300 rounded-md hover:bg-primary-100 hover:text-primary-500 hover:border-primary-400 focus:outline-none focus:bg-primary-100 focus:text-primary-500 focus:border-primary-400",
              title: "Order by " + value
            }, React.createElement(Icon.make, {
                  className: "if i-sort-alpha-ascending-regular"
                }), React.createElement("span", undefined, value));
}

function contents(sorter, params) {
  return sorter.options.map(function (sort) {
              return React.createElement("button", {
                          key: sort,
                          className: "w-full cursor-pointer text-left block p-3 text-xs font-semibold border-b border-gray-50 hover:text-primary-500 hover:bg-gray-50 focus:outline-none focus:text-primary-500 focus:bg-gray-50",
                          title: "Order by " + sort,
                          onClick: (function (_e) {
                              return setParams(sorter.key, sort, params);
                            })
                        }, sort);
            });
}

function CourseResourcesFilterWhite(Props) {
  var idOpt = Props.id;
  var courseId = Props.courseId;
  var filters = Props.filters;
  var search = Props.search;
  var placeholderOpt = Props.placeholder;
  var sorter = Props.sorter;
  var hintOpt = Props.hint;
  var id = idOpt !== undefined ? idOpt : "course-resource-filter";
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Filter Resources";
  var hint = hintOpt !== undefined ? hintOpt : "...or start typing to filter by cohorts using their name";
  var match = React.useReducer(reducer, {
        filterInput: "",
        filterLoading: false,
        filterData: []
      });
  var send = match[1];
  var state = match[0];
  var params = new URLSearchParams(search);
  React.useEffect((function () {
          getCourseResources(send, courseId, filters);
          
        }), [courseId]);
  return React.createElement("div", {
              className: "w-full flex gap-3"
            }, React.createElement("div", {
                  className: "flex-1"
                }, React.createElement("p", {
                      className: "text-xs uppercase font-medium pb-2"
                    }, "Filter"), React.createElement(Multiselect.make, {
                      id: id,
                      placeholder: placeholder,
                      onChange: (function (filterInput) {
                          return Curry._1(send, {
                                      TAG: /* UpdateFilterInput */0,
                                      _0: filterInput
                                    });
                        }),
                      value: state.filterInput,
                      unselected: unselected(state, filters),
                      selected: selectedFromQueryParams(params, filters),
                      onSelect: (function (param) {
                          return onSelect(params, send, param);
                        }),
                      onDeselect: (function (param) {
                          return onDeselect(params, param);
                        }),
                      hint: hint,
                      defaultOptions: unselected(state, filters),
                      loading: state.filterLoading
                    })), sorter !== undefined ? React.createElement("div", undefined, React.createElement("p", {
                        className: "text-xs uppercase font-medium pb-2"
                      }, "Sort by"), React.createElement(Dropdown2.make, {
                        selected: selected(sorter, params),
                        contents: contents(sorter, params),
                        right: true
                      })) : null);
}

var make$2 = CourseResourcesFilterWhite;

export {
  str ,
  makeFilter ,
  makeSorter ,
  reducer ,
  CourseResourceInfoInfoQuery ,
  getCourseResources ,
  formatStringWithID ,
  Selectable ,
  Multiselect ,
  findResource ,
  unselected ,
  computeInitialState ,
  selectedFromQueryParams ,
  setParams ,
  onSelect ,
  onDeselect ,
  selected ,
  contents ,
  make$2 as make,
  
}
/* include Not a pure module */
