// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Psj from "../shared/utils/Psj.bs.js";
import * as React from "react";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as ReactDom from "react-dom";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as UserSessionResetPassword from "../users/UserSessionResetPassword.bs.js";

function decodeProps(json) {
  return {
          token: Json_decode.field("token", Json_decode.string, json),
          authenticityToken: Json_decode.field("authenticityToken", Json_decode.string, json)
        };
}

Psj.match(undefined, "users/sessions#reset_password", (function (param) {
        var props = decodeProps(DomUtils.parseJSONAttribute("user-session-reset-password", "data-json-props", undefined));
        var element = document.querySelector("#user-session-reset-password");
        if (!(element == null)) {
          ReactDom.render(React.createElement(UserSessionResetPassword.make, {
                    token: props.token,
                    authenticityToken: props.authenticityToken
                  }), element);
          return ;
        }
        
      }));

export {
  decodeProps ,
  
}
/*  Not a pure module */
