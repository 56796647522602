// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as GraphqlQuery from "../../../shared/utils/GraphqlQuery.bs.js";
import * as CoursesCurriculum__Course from "../types/CoursesCurriculum__Course.bs.js";

var partial_arg = "components.CoursesCurriculum__LevelUpButton";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

var Raw = {};

var query = "mutation LevelUpMutation($courseId: ID!)  {\nlevelUp(courseId: $courseId)  {\nsuccess  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.levelUp;
  return {
          levelUp: {
            success: value$1.success
          }
        };
}

function serialize(value) {
  var value$1 = value.levelUp;
  var value$2 = value$1.success;
  var levelUp = {
    success: value$2
  };
  return {
          levelUp: levelUp
        };
}

function serializeVariables(inp) {
  return {
          courseId: inp.courseId
        };
}

function makeVariables(courseId, param) {
  return {
          courseId: courseId
        };
}

var LevelUpQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = GraphqlQuery.Extender({
      Raw: Raw,
      query: query,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables,
      variablesToJson: (function (prim) {
          return prim;
        }),
      toJson: (function (prim) {
          return prim;
        })
    });

var $$fetch = include.$$fetch;

var LevelUpQuery_Graphql_error = include.Graphql_error;

var LevelUpQuery_decodeNotification = include.decodeNotification;

var LevelUpQuery_decodeObject = include.decodeObject;

var LevelUpQuery_decodeNotifications = include.decodeNotifications;

var LevelUpQuery_decodeErrors = include.decodeErrors;

var LevelUpQuery_flashNotifications = include.flashNotifications;

var LevelUpQuery_sendQuery = include.sendQuery;

var LevelUpQuery_query = include.query;

var LevelUpQuery_make = include.make;

var LevelUpQuery = {
  LevelUpQuery_inner: LevelUpQuery_inner,
  Raw: Raw,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  Graphql_error: LevelUpQuery_Graphql_error,
  decodeNotification: LevelUpQuery_decodeNotification,
  decodeObject: LevelUpQuery_decodeObject,
  decodeNotifications: LevelUpQuery_decodeNotifications,
  decodeErrors: LevelUpQuery_decodeErrors,
  flashNotifications: LevelUpQuery_flashNotifications,
  sendQuery: LevelUpQuery_sendQuery,
  query: LevelUpQuery_query,
  $$fetch: $$fetch,
  make: LevelUpQuery_make
};

function handleSubmitButton(saving) {
  var submitButtonText = function (title, iconClasses) {
    return React.createElement("span", undefined, React.createElement(FaIcon.make, {
                    classes: iconClasses + " mr-2"
                  }), title);
  };
  if (saving) {
    return submitButtonText(t(undefined, undefined, "button_text_saving"), "fas fa-spinner fa-spin");
  } else {
    return submitButtonText(t(undefined, undefined, "button_text_level_up"), "fas fa-flag");
  }
}

function refreshPage(param) {
  window.location.reload();
  
}

function createLevelUpQuery(course, setSaving, $$event) {
  $$event.preventDefault();
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  Curry._3($$fetch, undefined, undefined, {
          courseId: CoursesCurriculum__Course.id(course)
        }).then(function (response) {
        if (response.levelUp.success) {
          window.location.reload();
        } else {
          Curry._1(setSaving, (function (param) {
                  return false;
                }));
        }
        return Promise.resolve(undefined);
      });
  
}

function CoursesCurriculum__LevelUpButton(Props) {
  var course = Props.course;
  var match = React.useState(function () {
        return false;
      });
  var setSaving = match[1];
  var saving = match[0];
  return React.createElement("button", {
              className: "btn btn-success btn-large w-full md:w-2/3 mt-4",
              disabled: saving,
              onClick: (function (param) {
                  return createLevelUpQuery(course, setSaving, param);
                })
            }, handleSubmitButton(saving));
}

var make = CoursesCurriculum__LevelUpButton;

export {
  t ,
  str ,
  LevelUpQuery ,
  handleSubmitButton ,
  refreshPage ,
  createLevelUpQuery ,
  make ,
  
}
/* include Not a pure module */
