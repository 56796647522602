// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../shared/Api.bs.js";
import * as I18n from "../shared/utils/I18n.bs.js";
import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../shared/components/FaIcon.bs.js";
import * as DomUtils from "../shared/utils/DomUtils.bs.js";
import * as Json_decode from "../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import SetNewPasswordIconSvg from "./images/set-new-password-icon.svg";

var resetPasswordIcon = SetNewPasswordIconSvg;

function str(prim) {
  return prim;
}

var partial_arg = "components.UserSessionResetPassword";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function handleErrorCB(setSaving, param) {
  return Curry._1(setSaving, (function (param) {
                return false;
              }));
}

function handleUpdatePasswordCB(response) {
  var path = Json_decode.field("path", (function (param) {
          return Json_decode.nullable(Json_decode.string, param);
        }), response);
  if (path !== null) {
    return DomUtils.redirect(path);
  }
  
}

function validPassword(password) {
  var length = password.length;
  if (length >= 8) {
    return length < 128;
  } else {
    return false;
  }
}

function updatePassword(authenticityToken, token, newPassword, confirmPassword, setSaving) {
  var payload = {};
  payload["authenticity_token"] = authenticityToken;
  payload["token"] = token;
  payload["new_password"] = newPassword;
  payload["confirm_password"] = confirmPassword;
  Curry._1(setSaving, (function (param) {
          return true;
        }));
  return Api.create("/users/update_password", payload, handleUpdatePasswordCB, (function (param) {
                return Curry._1(setSaving, (function (param) {
                              return false;
                            }));
              }));
}

function isDisabled(saving, newPassword, confirmPassword) {
  if (!validPassword(newPassword) || newPassword !== confirmPassword) {
    return true;
  } else {
    return saving;
  }
}

function submitButtonText(saving, newPassword, confirmPassword) {
  var match = newPassword === "";
  var match$1 = !validPassword(newPassword);
  var match$2 = confirmPassword === "";
  var match$3 = newPassword !== confirmPassword;
  if (saving) {
    return t(undefined, undefined, "updating_password");
  } else if (match) {
    return t(undefined, undefined, "enter_new_password");
  } else if (match$1) {
    return t(undefined, undefined, "password_short");
  } else if (match$2) {
    return t(undefined, undefined, "confirm_your_password");
  } else if (match$3) {
    return t(undefined, undefined, "passwords_not_match");
  } else {
    return t(undefined, undefined, "update_password");
  }
}

function renderUpdatePassword(authenticityToken, token, newPassword, setNewPassword, confirmPassword, setConfirmPassword, saving, setSaving) {
  var inputClasses = "appearance-none h-10 mt-1 block w-full text-gray-800 border border-gray-300 rounded py-2 px-4 text-sm bg-gray-50 hover:bg-gray-50 focus:outline-none focus:bg-white focus:border-primary-400";
  var labelClasses = "inline-block tracking-wide text-gray-900 text-xs font-semibold";
  return React.createElement("div", {
              className: "pt-4 pb-5 md:px-9 items-center max-w-sm mx-auto"
            }, React.createElement("div", undefined, React.createElement("label", {
                      className: labelClasses,
                      htmlFor: "new-password"
                    }, t(undefined, undefined, "new_password")), React.createElement("input", {
                      className: inputClasses,
                      id: "new-password",
                      maxLength: 128,
                      placeholder: t(undefined, undefined, "new_password_placeholder"),
                      type: "password",
                      value: newPassword,
                      onChange: (function ($$event) {
                          return Curry._1(setNewPassword, $$event.target.value);
                        })
                    })), React.createElement("div", {
                  className: "mt-4"
                }, React.createElement("label", {
                      className: "inline-block tracking-wide text-gray-900 text-xs font-semibold mt-2",
                      htmlFor: "confirm password"
                    }, t(undefined, undefined, "confirm_password")), React.createElement("input", {
                      className: inputClasses,
                      id: "confirm password",
                      maxLength: 128,
                      placeholder: t(undefined, undefined, "confirm_password_placeholder"),
                      type: "password",
                      value: confirmPassword,
                      onChange: (function ($$event) {
                          return Curry._1(setConfirmPassword, $$event.target.value);
                        })
                    })), React.createElement("button", {
                  className: "btn btn-success btn-large text-center w-full mt-4",
                  disabled: isDisabled(saving, newPassword, confirmPassword),
                  onClick: (function (param) {
                      return updatePassword(authenticityToken, token, newPassword, confirmPassword, setSaving);
                    })
                }, React.createElement(FaIcon.make, {
                      classes: saving ? "fas fa-spinner fa-spin" : "fas fa-lock"
                    }), React.createElement("span", {
                      className: "ml-2"
                    }, submitButtonText(saving, newPassword, confirmPassword))));
}

function UserSessionResetPassword(Props) {
  var token = Props.token;
  var authenticityToken = Props.authenticityToken;
  var match = React.useState(function () {
        return "";
      });
  var match$1 = React.useState(function () {
        return "";
      });
  var match$2 = React.useState(function () {
        return false;
      });
  return React.createElement("div", {
              className: "bg-gray-50 sm:py-10"
            }, React.createElement("div", {
                  className: "container mx-auto max-w-lg px-4 py-6 sm:py-8 bg-white rounded-lg shadow"
                }, React.createElement("img", {
                      className: "mx-auto h-20 sm:h-32",
                      src: resetPasswordIcon
                    }), React.createElement("div", {
                      className: "text-lg sm:text-2xl font-bold text-center mt-4"
                    }, t(undefined, undefined, "set_new_password")), renderUpdatePassword(authenticityToken, token, match[0], match[1], match$1[0], match$1[1], match$2[0], match$2[1])));
}

var make = UserSessionResetPassword;

export {
  resetPasswordIcon ,
  str ,
  t ,
  handleErrorCB ,
  handleUpdatePasswordCB ,
  validPassword ,
  updatePassword ,
  isDisabled ,
  submitButtonText ,
  renderUpdatePassword ,
  make ,
  
}
/* resetPasswordIcon Not a pure module */
