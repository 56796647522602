// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as StringUtils from "../../shared/utils/StringUtils.bs.js";

function id(t) {
  return t.id;
}

function name(t) {
  return t.name;
}

function decode(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

function path(t, userEmail, userAccessToken) {
  return "/communities/" + (t.id + ("/" + StringUtils.parameterize(t.name))) + "?user_email=" + encodeURIComponent(userEmail) + "&user_access_token=" + encodeURIComponent(userAccessToken);
}

export {
  id ,
  name ,
  decode ,
  path ,
  
}
/* No side effect */
